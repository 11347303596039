//@ts-strict
import { ColorMode } from '@im-frontend/components/ColorModeContext';
import ColorModeProvider from '@im-frontend/components/ColorModeProvider';
import { imLocalStorage } from '@im-frontend/utils/storages';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useState } from 'react';
import api from '~/api';
import { appStore } from '~/stores/AppStore';

const oldColorModeStorage = {
  key: 'color-mode' as const,
  get: () => {
    return imLocalStorage?.getItem(oldColorModeStorage.key) as ColorMode;
  },
  set: (colorMode: ColorMode) => {
    imLocalStorage?.setItem(oldColorModeStorage.key, colorMode);
  },
  remove: () => {
    imLocalStorage?.removeItem(oldColorModeStorage.key);
  },
};

const colorModeStorage = {
  key: 'color-mode-2' as const,
  get: () => {
    return imLocalStorage?.getItem(colorModeStorage.key) as ColorMode;
  },
  set: (colorMode: ColorMode) => {
    imLocalStorage?.setItem(colorModeStorage.key, colorMode);
  },
};

const LpColorModeProvider: FC<{}> = ({ children }) => {
  const [colorMode, setColorMode] = useState<ColorMode>(colorModeStorage.get());
  const { isLoggedIn } = appStore;
  const getColor = useCallback(async () => {
    const res = await api.contactPreferences.showSelf({
      fields: {
        colorMode: 1,
      },
    });
    setColorMode(res.data.colorMode);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getColor();
    }
  }, [getColor, isLoggedIn]);

  useEffect(() => {
    colorModeStorage.set(colorMode);
  }, [colorMode]);

  return (
    <ColorModeProvider
      colorMode={colorMode}
      onChangeColorMode={async newValue => {
        await api.contactPreferences.updateSelf({ colorMode: newValue });
        setColorMode(newValue);
      }}
    >
      {children}
    </ColorModeProvider>
  );
};

export default observer(LpColorModeProvider);
