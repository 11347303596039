import FormFeedback from '@appfolio/react-gears/lib/components/FormFeedback';
import FormText from '@appfolio/react-gears/lib/components/FormText';
import React from 'react';

import './pdfFormLabel.scss';

interface Props {
  feedback?: string;
  hint?: string;
  children: React.ReactNode;
}

const PdfFormLabel: React.FC<Props> = ({ children, feedback, hint }: Props) => {
  return (
    <div className="pdf-form-label">
      {children}
      <div className="pdf-form-label-feedback">
        {hint && <FormText>{hint}</FormText>}
        {feedback && (
          <FormFeedback className="d-inline-block">{feedback}</FormFeedback>
        )}
      </div>
    </div>
  );
};

export default PdfFormLabel;
