import { AxiosError } from 'axios';
import { action, observable } from 'mobx';
import uniqid from 'uniqid';

export type Color = 'info' | 'danger' | 'success' | 'warning';

export interface Toast {
  key: string;
  cause: Toastworthy;
  color: Color;
  scrollTo: boolean;
}

export type Toastworthy = Error | AxiosError | React.ReactNode;

/**
 * Global state relating to status messages displayed at
 * the top of the screen. Built to be interface-compatible
 * with the toastStore in gp-frontend, though in fact the
 * storage and presentation of toasts is handled very differently
 * between the two apps.
 */
class ToastStore {
  @observable currentToast: Toast | null = null;
  @observable nextToast: Toast | null = null;

  @action.bound
  onNavigate() {
    this.currentToast = this.nextToast;
    this.nextToast = null;
  }

  /**
   * Display a message immediately, or hide current message
   * if cause is null. If cause is a 401 error, do nothing
   */
  @action.bound
  toastNow(cause: Toastworthy, color: Color = 'success', scrollTo = false) {
    //401s will trigger redirect to login page, no need to display toast
    if ((cause as AxiosError)?.response?.status === 401) {
      return;
    }
    if (cause) {
      this.currentToast = {
        key: uniqid(),
        cause,
        color,
        scrollTo,
      };
    } else {
      this.currentToast = null;
    }
  }

  @action.bound
  eatToast() {
    this.currentToast = null;
  }

  /**
   * Display a message after the next navigation.
   */
  @action.bound
  toastLater(cause: Toastworthy, color: Color = 'success') {
    this.nextToast = { key: uniqid(), cause, color, scrollTo: false };
  }
}

export default ToastStore;

/**
 * Singleton instance of this class.
 * @see setStore
 */
export let toastStore = new ToastStore();

/**
 * Set the singleton instance.
 */
export function setToastStore(newStore: ToastStore) {
  toastStore = newStore;
}
