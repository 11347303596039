import * as rich from '@appfolio-im/rich';
type Blank = undefined | null;

const isObservableArray = (thing: any): thing is any[] =>
  !Array.isArray(thing) && thing && typeof thing.peek === 'function';

export function typeSafeIsBlank<T>(val: Blank | T): val is Blank {
  if (val === null || val === undefined) {
    return true;
  }
  if (typeof val === 'string' && val.replace(/\s/g, '').length === 0) {
    return true;
  }
  if ((Array.isArray(val) || isObservableArray(val)) && val.length === 0) {
    return true;
  }
  if (rich.isRichContent(val)) {
    return rich.isBlankContent(val);
  }
  return false;
}

export default function isBlank(...args: any[]) {
  return args.every(typeSafeIsBlank);
}

export function isNumericBlank(val: Blank | string | number) {
  return typeSafeIsBlank(val) || val === '.';
}
