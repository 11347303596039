import { PatternInput } from '@appfolio/react-gears';
import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import classNames from 'classnames';
import React from 'react';

// Current react-gears does not have any typings for PatternInput. Improve typing when this gets fixed
export type PatternInputProps = any;
export type PatternFieldProps = FormLabelGroupProps & PatternInputProps;

const PatternField = ({
  label,
  required,
  hint,
  rowClassName,
  validate,
  initialValue,
  feedback,
  tableField,
  id,
  ...rest
}: PatternFieldProps) => (
  <FormLabelGroup
    stacked
    label={label}
    required={required}
    hint={hint}
    inputId={id}
    rowClassName={classNames(rowClassName, { 'no-gutters mb-0': tableField })}
    feedback={feedback}
  >
    <PatternInput id={id} {...rest} />
  </FormLabelGroup>
);

export default PatternField;
