import { Query, stringify } from '@im-frontend/praxis-api/qs';
import { AxiosRequestConfig } from 'axios';
import { apiPrefix } from '~/namespaceInfo';
import investorPortalClient from './clients/investorPortal';

export const index = (params: Query) =>
  investorPortalClient.get(`/document_shares?${stringify(params)}`);

export const show = (id: number, params: Query) =>
  investorPortalClient.get(`/document_shares/${id}?${stringify(params)}`);

export function trackView(
  href: string,
  config?: Pick<AxiosRequestConfig, 'signal'>
) {
  // Strip apiURLPrefix from href (it will be added back by axios as part of the baseURL)
  // TODO: this should really be stripping both namespacePrefix as well as apiPrefix from the start,
  // but there is a bug in the backend, and so the namespacePrefix is not present.
  // See backend/api/app/v1i/resources/document.rb's download_url.
  if (href.startsWith(apiPrefix)) {
    href = href.slice(apiPrefix.length);
  }

  // Also ensure we ask for XHR compliant response (no redirects).
  if (href.indexOf('?') < 0) {
    href += '?allow_redirect=0';
  } else if (href.indexOf('allow_redirect=') < 0) {
    href += '&allow_redirect=0';
  }

  return investorPortalClient.get(href, config);
}
