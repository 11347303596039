import { decamelizeKeys } from 'humps';
import { isPlainObject } from 'lodash';

export default function toFields(obj: any): string {
  if (typeof obj === 'string') {
    return obj;
  }

  return Object.entries(decamelizeKeys(obj))
    .map(([key, value]) => {
      if (isPlainObject(value)) {
        return `${key}{${toFields(value)}}`;
      }

      return key;
    })
    .join(',');
}
