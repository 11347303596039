import { Alert } from '@appfolio/react-gears';
import { imSessionStorage } from '@im-frontend/utils/storages';
import React from 'react';

export const storageKey = 'uiQuery';

function getUiQuery() {
  return imSessionStorage?.getItem(storageKey);
}

function reset() {
  imSessionStorage?.removeItem(storageKey);
  window.location.reload();
}

function isHidden() {
  return imSessionStorage?.getItem('testingPip.hidden') === 'true';
}

function hide() {
  imSessionStorage?.setItem('testingPip.hidden', 'true');
}

// tolerate missing injections to help tests be loosely coupled
export default class TestingPip extends React.Component {
  render() {
    if (isHidden()) {
      return null;
    }

    const uiQuery = getUiQuery();

    if (!uiQuery) {
      return null;
    }

    return (
      <Alert
        className="mx-1 my-2 p-1 small text-truncate"
        color="warning"
        dismissible
        onToggle={hide}
      >
        {uiQuery}
        <br />
        <button
          className="btn btn-link m-0 p-0"
          onClick={reset}
          style={{ fontSize: 'small' }}
        >
          reset
        </button>
      </Alert>
    );
  }
}
