import invariant from 'invariant';

export function validateProperties(
  properties: { [key: string]: any },
  disallowedPropertyNames: string[]
) {
  invariant(
    !disallowedPropertyNames.some(key => key in properties),
    `requests cannot contain any of ${JSON.stringify(
      disallowedPropertyNames
    )}, but found ${JSON.stringify(Object.keys(properties))}`
  );
}
