import { merge } from 'lodash';
import DependencyStore from './DependencyStore';
import { RegisterFields, ScopedRequest, ScopedRequests } from './interfaces';
import { validateProperties } from './utils';

const INVALID_KEYS = ['_requests', 'register'];

type MappedDependencies<T extends ScopedRequests> = {
  [K in keyof T]: DependencyStore<T[K]>;
};

export type IDependenciesStore<
  T extends ScopedRequests = { [key: string]: ScopedRequest }
> = DependenciesStore<T> & MappedDependencies<T>;

export class DependenciesStore<T extends ScopedRequests> {
  // typescript doesnt support mapped properties on classes so use this to fake it
  private get dependencyStores(): MappedDependencies<T> {
    return this as any;
  }

  // no one should ever use this externally
  _requests: T;

  constructor(requests: T) {
    validateProperties(requests, INVALID_KEYS);

    this._requests = requests;

    Object.entries(requests).forEach(([name, callback]) => {
      const key: keyof T = name;
      const value = new DependencyStore(callback) as DependencyStore<
        T[typeof key]
      >;
      this.dependencyStores[key] = value;
    });
  }

  register<F extends Partial<RegisterFields<T>>>(fields: F): F {
    Object.entries(fields).forEach(([name, subfields]) => {
      if (!(name in this._requests)) {
        const knownKeys = Object.keys(this._requests);
        throw new Error(
          `Fields requested for unknown request ${name}. Known keys are ${knownKeys}`
        );
      }

      this.dependencyStores[name].fields = merge(
        this.dependencyStores[name].fields,
        subfields
      );
    });
    return fields;
  }
}

export function createDependencyStores<T extends ScopedRequests>(
  requests: T
): IDependenciesStore<T> {
  return new DependenciesStore(requests) as any;
}
