import Button from '@appfolio/react-gears/lib/components/Button';
import React, { FC, useState } from 'react';

interface Props {
  prompt?: string;
  summary: string | React.ReactNode;
}

/**
 * Widget that displays a terse, textual summary of some information and lets
 * the user expand it to see more below the summary.
 *
 * Once more is shown, it cannot be unshown until the application is reloaded.
 */
const ShowMore: FC<Props> = ({ prompt, summary, children }) => {
  const [showMore, setShowMore] = useState(false);

  const onClick = () => {
    setShowMore(true);
  };

  const button =
    !showMore && children ? (
      <Button className="mx-1 my-0" color="link" onClick={onClick}>
        {prompt}
      </Button>
    ) : null;

  const more = showMore && <div>{children}</div>;

  return (
    <div className="d-flex flex-column">
      <div>
        {summary}
        {button}
      </div>
      {more}
    </div>
  );
};

ShowMore.defaultProps = {
  prompt: 'more...',
};

export default ShowMore;
