import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from './clients/investorPortal';

export const index = (params: Query) =>
  investorPortalClient.get(`/positions?${stringify(params)}`);

interface Update {
  position: {
    id: number;
  };
  reinvest: boolean;
}
export const bulkUpdateReinvest = (payload: Update[]) =>
  investorPortalClient.patch(
    `/positions/actions/bulk_update_reinvest`,
    payload
  );
