import Dropdown from '@appfolio/react-gears/lib/components/Dropdown';
import DropdownItemRG from '@appfolio/react-gears/lib/components/DropdownItem';
import DropdownMenuRG from '@appfolio/react-gears/lib/components/DropdownMenu';
import DropdownToggleRG from '@appfolio/react-gears/lib/components/DropdownToggle';
import Icon from '@appfolio/react-gears/lib/components/Icon';
import Tooltip from '@appfolio/react-gears/lib/components/Tooltip';
import React, { ComponentProps, useState } from 'react';
import { Link } from 'react-router-dom';
import * as authorization from '~/services/authorization';

interface AbstractItem {
  disabled?: boolean;
  ifAuthorized?: authorization.PrivilegeQuery;
  key: string;
  name: React.ReactNode;
  hidden?: boolean;
  tooltip?: React.ReactNode;
}

interface ClickItem extends AbstractItem {
  onClick: () => void;
}

interface LinkItem extends AbstractItem {
  to: string;
  iconName?: string;
  isExternalLink?: boolean;
  newTab?: boolean;
}

export type DropDownItem = ClickItem | LinkItem;

function isLinkItem(item: LinkItem | ClickItem): item is LinkItem {
  return (item as LinkItem).to !== undefined;
}

type DropDownMenuProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  items: DropDownItem[];
} & ComponentProps<typeof DropdownMenuRG>;

export function DropDownMenu({
  isOpen,
  setIsOpen,
  items,
  ...dropdownMenuProps
}: DropDownMenuProps) {
  if (!isOpen) {
    return null;
  }

  const visibleItems = items.filter(
    item => !item.hidden && authorization.queryAll(item.ifAuthorized)
  );

  if (visibleItems.length === 0) {
    return null;
  }

  const dropDownItems = visibleItems.map(item => {
    const dropdownItem = (
      <DropdownItemRG
        className={`js-${item.key}`}
        key={item.key}
        disabled={item.disabled}
        onClick={
          isLinkItem(item)
            ? undefined
            : async () => {
                await item.onClick();
                setIsOpen(false);
              }
        }
      >
        <span id={item.key}>{item.name}</span>{' '}
        {item.tooltip && (
          <Tooltip
            placement={'top'}
            target={item.key}
            key={`tooltip-${item.key}`}
            boundariesElement={'window'}
          >
            {item.tooltip}
          </Tooltip>
        )}
        {isLinkItem(item) && item.iconName && <Icon name={item.iconName} />}
      </DropdownItemRG>
    );

    if (!isLinkItem(item)) {
      return dropdownItem;
    }

    if (item.disabled) {
      return dropdownItem;
    }

    const target = item.newTab && !window.Cypress ? '_blank' : undefined;

    if (item.isExternalLink) {
      return (
        <a href={item.to} target={target} key={item.key}>
          {dropdownItem}
        </a>
      );
    }

    return (
      // tooltip currently not compatible with <Link>
      <Link to={item.to} target={target} key={item.key}>
        {dropdownItem}
      </Link>
    );
  });

  return (
    <DropdownMenuRG right {...dropdownMenuProps}>
      {dropDownItems}
    </DropdownMenuRG>
  );
}

interface Props {
  className?: string;
  name: string;
  useDiv?: boolean;
  id?: string;
  items: DropDownItem[];
  toggleClassName?: string;
}

export default function DropDown({
  items,
  name,
  useDiv = false,
  className,
  toggleClassName,
  id,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(o => !o)}
      className={className}
      id={id}
    >
      <DropdownToggleRG
        caret
        color="link" // use a div while we are lazy loading so our tests wait until lazy loading
        // is complete to interact with this element. Lazy loading causes the header
        // to churn in the DOM so the tests become really flakey
        className={`${toggleClassName} text-dark ${
          useDiv ? 'btn btn-link' : ''
        }`}
        tag={useDiv ? 'div' : undefined}
      >
        {name}
      </DropdownToggleRG>
      <DropDownMenu isOpen={isOpen} setIsOpen={setIsOpen} items={items} />
    </Dropdown>
  );
}
