import invariant from 'invariant';
import { Provider, observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { netStore } from './NetStore';
import { toastStore } from './ToastStore';

type Props = {
  children: React.ReactNode;
} & RouteComponentProps;

/**
 * Provider for stores that need to listen to react-router history events.
 * Constructs the stores and sets their singleton references.
 */
@observer
class NavigationAwareStoreProvider extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    const { history } = props;
    invariant(history, '@withRouter failed to provide history');
    netStore.history = history;
    history.listen(() => {
      netStore.onNavigate();
      toastStore.onNavigate();
    });
  }

  render() {
    return <Provider toastStore={toastStore}>{this.props.children}</Provider>;
  }
}

export default withRouter(NavigationAwareStoreProvider);
