import { PatternInput } from '@appfolio/react-gears';
import Input from '@appfolio/react-gears/lib/components/Input';
import React, { ComponentProps } from 'react';
import { Assign } from 'utility-types';

export type NumberPatternInputProps = Assign<
  ComponentProps<typeof Input>,
  {
    allowNegative?: boolean;
    integerPlaces?: number;
    decimalPlaces?: number;
  }
>;

export default function NumberPatternInput({
  allowNegative = false,
  integerPlaces = 4,
  decimalPlaces = 6,
  ...props
}: NumberPatternInputProps) {
  const naturalPlaces = Math.floor(Math.abs(decimalPlaces));
  const minus = allowNegative ? '-?' : '';
  const pattern = new RegExp(
    `^${minus}\\d{0,${integerPlaces}}$|^${minus}\\d{0,${integerPlaces}}\\.\\d{0,${naturalPlaces}}$`
  );

  return <PatternInput pattern={pattern} {...props} />;
}
