import Spinner from '@appfolio/react-gears/lib/components/Spinner';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import './spinner.scss';

const spinnerStyles = {
  width: '4rem',
  height: '4rem',
  border: '.2rem solid #dee2e7',
  borderRightColor: '#818fa0',
  animationDuration: '1.25s',
};

const smallSpinnerStyles = {
  width: '2rem',
  height: '2rem',
  border: '.2rem solid #dee2e7',
  borderRightColor: '#818fa0',
  animationDuration: '1.25s',
};

/**
 * It's big. Suitable for whole-screen modals, centered placeholder for table/block, etc.
 */
function BigSpinner() {
  return (
    <div className="p-3" data-test-id="big-spinner">
      <Spinner type="border" className="d-block m-auto" style={spinnerStyles} />
    </div>
  );
}

/**
 * It's smaller. Suitable for numerous use cases.
 */
export function SmallSpinner({ extraClassName }: { extraClassName?: string }) {
  return (
    <div className={`p-3 ${extraClassName || ''}`}>
      <Spinner
        type="border"
        className="d-block m-auto"
        style={smallSpinnerStyles}
        data-cy="small-spinner"
      />
    </div>
  );
}

/**
 * It's a big spinner that doesn't show up until 200ms after it's mounted.
 * Use this when you expect something to be mostly quick but sometimes slow.
 */
export default function DelayedSpinner() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShow(true);
    }, 200);

    return () => clearInterval(timeoutId);
  }, []);

  return show ? <BigSpinner /> : null;
}

/**
 * It's a spinner that's used in the portal (and is probably obsolete).
 *
 * TODO: replace this with Spinner?
 */
export function CircleSpinner({
  className,
  size = '6rem',
  inline,
}: {
  className?: string;
  size?: string;
  inline?: boolean;
}) {
  return (
    <div className={classNames('im-spinner', className, { inline })}>
      <svg
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle className="primary" cx="50" cy="50" r="35" />
        <circle
          className="secondary"
          cx="50"
          cy="50"
          r="35"
          strokeDasharray="80% 0% 80%"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 50 50;90 50 50;180 50 50;270 50 50;360 50 50"
            keyTimes="0;0.5;0.7;0.85;1"
            dur="1.6s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
  );
}
