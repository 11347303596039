import React from 'react';

type RenderFunction<PropsType> = (
  props: PropsType,
  Component: React.ComponentType<PropsType>
) => React.ReactNode;

export interface RenderableProps<PropsType> {
  children?: RenderFunction<PropsType> | React.ReactNode;
  component?: React.ComponentType<PropsType>;
  render?: RenderFunction<PropsType>;
}

export default function getRenderer<PropsType>({
  render,
  component,
  children,
}: RenderableProps<PropsType>): RenderFunction<PropsType> {
  if (component) {
    return props => React.createElement(component, props);
  }

  if (render) {
    return render;
  }

  if (typeof children === 'function') {
    return (props, Component) => {
      const { children: _throwAway, ...newProps } = props as PropsType & {
        children?: any;
      };
      return children(newProps as PropsType, Component);
    };
  }

  return (props, Component) => <Component {...props} />;
}
