import { Query, stringify } from '@im-frontend/praxis-api/qs';
import * as im from '~/models';
import investorPortalClient from './clients/investorPortal';

export const index = (params: Query) =>
  investorPortalClient.get<im.BankAccount[]>(
    `/bank_accounts?${stringify(params)}`
  );

export const show = (id: number, params: Query) =>
  investorPortalClient.get(`/bank_accounts/${id}?${stringify(params)}`);

export function update(id: number, payload: any) {
  return investorPortalClient.patch(`/bank_accounts/${id}`, payload);
}

export function create(body: any) {
  return investorPortalClient.post('/bank_accounts', body);
}

export function bulkCreateViaPlaid(body: any) {
  return investorPortalClient.post(
    '/bank_accounts/actions/bulk_create_via_plaid',
    body
  );
}

export function verifySameDayMicroDeposit(body: any) {
  return investorPortalClient.post(
    '/bank_accounts/actions/verify_same_day_micro_deposit',
    body
  );
}

export function unlink(bankAccountId: any) {
  return investorPortalClient.post('/bank_accounts/actions/unlink', {
    bank_account: { id: bankAccountId },
  });
}
