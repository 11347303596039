import Semaphore from 'semaphore-async-await';
import cloudFunctionsClient from '~/api/clients/cloudFunctions';

const cloudFunctionsSemaphor = new Semaphore(64);
// TODO: replace `extends any` with `,` after we update prettier
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const rateLimit = async <T extends any>(callback: () => Promise<T>) => {
  await cloudFunctionsSemaphor.acquire();
  try {
    return await callback();
  } finally {
    cloudFunctionsSemaphor.release();
  }
};

interface PdfifyRequest {
  filename?: string;
  readyHint?: string;
  url?: string;
  input?: {
    html?: string;
  };
}

interface PdifyResponse {
  id: string;
  url: string;
}

export const pdfify = ({ filename, readyHint, url, input }: PdfifyRequest) =>
  rateLimit(() =>
    cloudFunctionsClient.post<PdifyResponse>('/pdfify', {
      filename,
      ready_hint: readyHint,
      input,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      url,
    })
  );

interface PdfmodRequest {
  input: ({ id: string } | { url: string })[];
  operation: 'merge';
  output: { name: string };
}

export interface PdfmodResponse {
  output: { id: string; previewUrl: string; downloadUrl: string }[];
}

/**
 * Pdfmod merges PDF files (and someday maybe it will do other things).
 */
export const pdfmod = (request: PdfmodRequest) =>
  rateLimit(() =>
    cloudFunctionsClient.post<PdfmodResponse>('/pdfmod', request)
  );
