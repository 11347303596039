import isBlank from '@im-frontend/utils/isBlank';

export function tinValidator(
  value: string,
  {
    skipFor = [],
    blankAllowed = true,
  }: {
    skipFor?: string[];
    blankAllowed?: boolean;
  }
) {
  if (isBlank(value)) {
    return blankAllowed
      ? undefined
      : 'Please enter a Tax ID number (SSN or EIN)';
  }

  if (skipFor.indexOf(value) > -1) {
    return undefined;
  }

  const patterns = [/^\d{9}$/, /^\d{3}-\d{2}-\d{4}$/, /^\d{2}-\d{7}$/];

  const match = patterns.find(pattern => pattern.test(value));

  if (!match) {
    return 'Please enter a valid tax ID format: ###-##-####, ##-#######, or #########';
  }
  return undefined;
}
