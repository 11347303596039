import UnsavedChangesPrompt from '@im-frontend/utils/UnsavedChangesPrompt';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { isEqual } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { AnyObject, Form as FinalForm, FormProps } from 'react-final-form';
import uniqid from 'uniqid';

type Props<FormValues = AnyObject> = FormProps<FormValues> & {
  buttons?: React.ReactNode;
  showUnsavedChangesPrompt?: boolean;
  id?: string;
};

export type FormInstance = FormApi<AnyObject>;
@observer
export default class Form<FormValues = AnyObject> extends React.Component<
  Props<FormValues>
> {
  static defaultProps = {
    showUnsavedChangesPrompt: true,
  };

  id: string;

  constructor(props: Props<FormValues>) {
    super(props);

    this.id = props.id || `form-${uniqid()}`;
  }

  scrollToFirstInvalid = () => {
    const form = document.getElementById(this.id);

    if (form) {
      const invalidFields = form.getElementsByClassName('is-invalid');
      invalidFields[0] &&
        invalidFields[0].scrollIntoView({
          block: 'center',
          inline: 'center',
        });
    }
  };

  render() {
    const { initialValues, showUnsavedChangesPrompt, ...restProps } =
      this.props;
    return (
      <FinalForm<FormValues>
        mutators={{ ...arrayMutators }}
        initialValues={toJS(initialValues)}
        initialValuesEqual={isEqual}
        {...restProps}
      >
        {FinalFormProps => {
          const handleSubmit = async (
            event?: React.SyntheticEvent<HTMLFormElement, Event>
          ) => {
            const submitErrors = await FinalFormProps.handleSubmit(event);
            setTimeout(this.scrollToFirstInvalid, 100);
            return submitErrors;
          };
          const { submitSucceeded, dirtySinceLastSubmit, dirty, submitting } =
            FinalFormProps;
          return (
            <form
              id={this.id}
              onSubmit={handleSubmit}
              autoComplete="on"
              // remove `novalidate` this when we update react gears
              // PatternInput has a (fixed) bug where it always fails validation
              // https://github.com/appfolio/react-gears/commit/6673469ff3df247bdf650ff6f1876c5c0cd8730a#diff-273938f477ddd918f29546da3db8c8ac01a49c0ea0f364713592a07c179baec6R39
              noValidate
            >
              {(this.props.children as any)({
                ...FinalFormProps,
                handleSubmit,
              })}
              {showUnsavedChangesPrompt && (
                // use !submitting to enable we navigate in onSubmit function.
                // if submitSucceeded, check dirtySinceLastSubmit, other wise check if form is dirty since initialValue
                <UnsavedChangesPrompt
                  when={
                    !submitting &&
                    (submitSucceeded ? dirtySinceLastSubmit : dirty)
                  }
                />
              )}
              {this.props.buttons}
            </form>
          );
        }}
      </FinalForm>
    );
  }
}
