import { provideSessionUrl } from '@im-frontend/utils/ScreenRecording';
import { canIgnore } from '@im-frontend/utils/errors';
import {
  canIgnoreBreadCrumb,
  sanitizeEvent,
} from '@im-frontend/utils/sentryHelpers';
import { rewriteFramesIntegration } from '@sentry/react';
import { autorun } from 'mobx';
import { circleBuildUrl, namespace } from '~/namespaceInfo';
import AppStore from '~/stores/AppStore';

declare global {
  interface Window {
    sentryClientOptions: any;
    sentryCanIgnore?: any;
    sentryCanIgnoreBreadCrumb?: any;
    sentrySanitizeEvent?: any;
  }
}

window.sentryCanIgnore = canIgnore;
window.sentryCanIgnoreBreadCrumb = canIgnoreBreadCrumb;
window.sentrySanitizeEvent = sanitizeEvent;

export function syncSentryData(store: AppStore) {
  if (typeof Sentry === 'undefined') {
    return;
  }

  const configureScope = (sessionURL?: string) => {
    // Sentry integrations are not available with CDN initialization, so do this hack
    // https://forum.sentry.io/t/add-integration-after-init/5260
    Sentry.setCurrentClient(
      new Sentry.BrowserClient({
        ...window.sentryClientOptions,
        integrations: [
          ...(window.sentryClientOptions.integrations || []),
          rewriteFramesIntegration({
            iteratee: function (frame: any) {
              // This stops NewRelic from causing the stack trace to be different on every single page.
              // https://github.com/getsentry/sentry/issues/7709
              if (
                /investors\.qa\.appfolioim\.net/.test(frame.filename) ||
                /investors\.appfolioim\.com/.test(frame.filename)
              ) {
                frame.filename = 'index.html';
                frame.in_app = true;
              }
              return frame;
            },
          }),
        ],
      })
    );
    {
      const scope = Sentry.getCurrentScope();
      scope.setTag('hostname', window.location.hostname);
      scope.setTag('account', namespace);
      scope.setTag('isInvestorMobileApp', store.isMobileApp);
      circleBuildUrl && scope.setTag('circle_build_url', circleBuildUrl);
      sessionURL && scope.setExtra('sessionURL', sessionURL);
    }

    const { session } = store;
    if (session && session.email) {
      Sentry.getCurrentScope().setUser({
        email: session.email,
      });
    } else {
      Sentry.getCurrentScope().setUser(null);
    }
  };

  autorun(() => {
    provideSessionUrl(configureScope);
  });
}
