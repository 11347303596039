import { syncUserAttribute } from '@im-frontend/utils/ScreenRecording';
import { autorun } from 'mobx';
import * as im from '~/models';
import { namespace } from '~/namespaceInfo';

export default function syncScreenRecordingUserAttributes(appStore: {
  session?: im.SessionInfo;
}) {
  autorun(() => {
    const { session } = appStore;
    if (!session || !session.email) {
      return;
    }

    syncUserAttribute({
      userId: `${session.email}@${namespace}`,
      displayName: session.fullName,
      email: session.email,
      internalUser: false,
      isJv: false,
      isPreview: session.preview,
      namespace,
    });
  });
}
