import { isEqual } from 'lodash';
import memoize from 'memoize-one';
import { useState } from 'react';

const identity = (x: any) => x;

export default function useDeepMemoized(value: any) {
  const [deepMemoize] = useState(() => memoize(identity, isEqual));

  return deepMemoize(value);
}
