import parse from 'date-fns/parse';

/**
 *
 * Given a string like "2018-05-24" (w/o timezone or utc offset info),
 * return a Date that is 2018-05-24 00:00:00 in the local timezone
 *
 * Assumes that date-only strings represent a "calendar date"
 * that is independent of any time zone.
 *
 * If passed anything falsy, returns the same value.
 *
 * If passed an ISO timestamp with timezone info, or a Date,
 * returns a Date in the local time zone.
 */
export default function toLocalDate(input: any) {
  return input && parse(input);
}
