import { Query, stringify } from '@im-frontend/praxis-api/qs';
import { Address, Investor } from '~/models';
import investorPortalClient from './clients/investorPortal';

export const index = (params: Query) =>
  investorPortalClient.get(`/investors?${stringify(params)}`);

export const show = (id: number, params: Query) =>
  investorPortalClient.get(`/investors/${id}?${stringify(params)}`);

interface Update {
  entityType?: string;
  taxAddress?: Partial<Address> | null;
  tin?: string;
  bank?: any;
  ffcCustodianName?: string;
  ffcAccountNumber?: string;
  countryOfIncorporation?: Investor['countryOfIncorporation'];
}
export const update = (id: number, payload: Update) =>
  investorPortalClient.patch(`/investors/${id}`, payload);
