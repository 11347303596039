import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from './clients/investorPortal';

export function show(id: number, params: Query) {
  return investorPortalClient.get(
    `/accreditation_verifications/${id}?${stringify(params)}`
  );
}

export function investorVerified(id: number) {
  return investorPortalClient.patch(
    `/accreditation_verifications/${id}/actions/investor_verified`
  );
}
