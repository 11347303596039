import { HasManyFieldsRenderProps } from '@im-frontend/forms/HasManyFields';
import React from 'react';
import { EmailFormField, HasManyFields } from '~/utils/forms2';

const EmailField = ({ name }: HasManyFieldsRenderProps) => (
  <EmailFormField name={`${name}.email`} placeholder="Email" />
);

const HasManyEmailFields = (props: any) => {
  return (
    <HasManyFields
      template={EmailField}
      blank={{ email: '' }}
      defaultValue={[]}
      {...props}
    />
  );
};

export default HasManyEmailFields;
