import { createClient } from '.';

// this client is used only for mfa and assigned with a special token
const mfaClient = createClient({
  interceptors: {
    reloadDuringRelease: false,
    retryCertainHttpCodes: false,
    enforceMaxRequestSize: false,
  },
});

export default mfaClient;
