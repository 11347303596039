import InputGroup from '@appfolio/react-gears/lib/components/InputGroup';
import InputGroupAddon from '@appfolio/react-gears/lib/components/InputGroupAddon';
import React from 'react';
import NumberInput, { NumberInputProps } from './NumberInput';

export type MultiplierInputProps = NumberInputProps;

export default function MultiplierInput(props: MultiplierInputProps) {
  return (
    <InputGroup>
      <NumberInput {...props} />
      <InputGroupAddon addonType="append">x</InputGroupAddon>
    </InputGroup>
  );
}
