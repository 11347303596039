import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from './clients/investorPortal';

export function show(id: number, params: Query) {
  return investorPortalClient.get(`/prospects/${id}?${stringify(params)}`);
}

export function index(params: Query) {
  return investorPortalClient.get(`/prospects?${stringify(params)}`);
}

export function confirmEntity(
  id: number,
  recipientId: number,
  {
    desiredCommitment,
    investingEntityId,
    investingEntityDetails,
    newInvestor,
    holdingTitle,
    contacts,
  }: {
    desiredCommitment?: number;
    investingEntityId?: number;
    investingEntityDetails?: any;
    newInvestor?: any;
    holdingTitle?: string;
    contacts?: any;
  }
) {
  const params = {} as any;
  investingEntityId &&
    (params.investor = {
      id: investingEntityId,
      updatePayload: investingEntityDetails,
    });
  newInvestor && (params.newInvestor = newInvestor);
  holdingTitle && (params.holdingTitle = holdingTitle);
  recipientId && (params.signatureRecipientId = recipientId);
  contacts && (params.contacts = contacts);
  desiredCommitment && (params.desiredCommitment = desiredCommitment);

  return investorPortalClient.post(
    `/prospects/${id}/actions/confirm_entity`,
    params
  );
}

export const capitalSent = (id: string | number, params: Query) =>
  investorPortalClient.post(`/prospects/${id}/capital_sent`, params);
