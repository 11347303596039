import { Alert } from '@appfolio/react-gears';
import formatError from '@im-frontend/components/formatError';
import { observer } from 'mobx-react';
import React from 'react';
import { appStore } from '~/stores/AppStore';

export interface Props {
  cause?: Error | React.ReactNode;
  className?: string;
  color?: 'info' | 'danger' | 'success' | 'warning';
}

const technicalDetails = () =>
  process.env.NODE_ENV !== 'production' ||
  (appStore &&
    appStore.session &&
    appStore.session.email &&
    !!appStore.session.email.match(/@appfolio.com$/i));

@observer
export default class Flash extends React.Component<Props> {
  static defaultProps = {
    color: 'success',
  };
  // TODO delete these methods and their callers; favor <Flash {...this.state.flash}/> instead
  static success = (cause: any) => <Flash color="success" cause={cause} />;
  static info = (cause: any) => <Flash color="info" cause={cause} />;
  static warning = (cause: any) => <Flash color="warning" cause={cause} />;
  static danger = (cause: any) => <Flash color="danger" cause={cause} />;

  render() {
    const { cause, className } = this.props;
    let { children, color } = this.props;

    if (!cause && !children) {
      return null;
    }

    if (cause instanceof Error) {
      children = formatError(cause, technicalDetails());
      color = 'danger';
    } else if (cause) {
      children = cause;
    }

    return (
      <Alert className={className} color={color} dismissible>
        {children}
      </Alert>
    );
  }
}
