import { action, observable } from 'mobx';

class MfaStore {
  @observable modalIsOpen = false;
  client: any;
  config: any;
  resolve: any;
  reject: any;
  mfaPhoneNumberLast4: string | null;
  mfaMethod: string | null;

  @action.bound
  openModalAndSetPhoneNumberLast4(
    client: any,
    config: any,
    mfaPhoneNumberLast4: string | null,
    mfaMethod: string | null
  ) {
    this.modalIsOpen = true;
    this.client = client;
    this.config = config;
    this.mfaPhoneNumberLast4 = mfaPhoneNumberLast4;
    this.mfaMethod = mfaMethod;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  @action.bound
  closeModal() {
    this.modalIsOpen = false;
    this.mfaPhoneNumberLast4 = null;
    this.mfaMethod = null;
  }

  @action.bound
  cancelMfa() {
    this.closeModal();
    this.reject(new Error('mfaCanceled'));
  }

  @action.bound
  async submitToken(token: number) {
    this.config.headers.MFATOKEN = token;
    const resp = await this.client(this.config);
    this.closeModal();
    this.resolve(resp);
  }
}

export default MfaStore;
