import { docsClient } from './clients/investorPortal';

export type DocumentType =
  | 'General Documents'
  | 'Reporting & Statements'
  | 'Tax Documents'
  | 'Agreements'
  | 'Uploaded By Investor';

export type DocumentsCreatePayload = {
  type: DocumentType;
  date?: string | Date;
  name?: string;
  investor?: { id: number };
};

interface ICreateDocument {
  file: File;
  body: DocumentsCreatePayload;
}

export function create({ file, body }: ICreateDocument) {
  const formData = new FormData();
  formData.append('document', JSON.stringify(body));
  formData.append('file', file, file.name);
  return docsClient.post('/documents', formData);
}
