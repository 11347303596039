import InputGroup from '@appfolio/react-gears/lib/components/InputGroup';
import InputGroupAddon from '@appfolio/react-gears/lib/components/InputGroupAddon';
import React from 'react';
import HighPrecisionNumberInput, {
  HighPrecisionNumberInputProps,
} from './HighPrecisionNumberInput';

export type PercentInputProps = HighPrecisionNumberInputProps;

export default function HighPrecisionPercentInput(props: PercentInputProps) {
  return (
    <InputGroup>
      <HighPrecisionNumberInput {...props} />
      <InputGroupAddon addonType="append">%</InputGroupAddon>
    </InputGroup>
  );
}
