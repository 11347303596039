import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import ComboboxMulti, {
  ComboboxMultiProps,
} from '@im-frontend/components/ComboboxMulti';
import classNames from 'classnames';
import React from 'react';

export type ComboboxMultiFieldProps = FormLabelGroupProps &
  ComboboxMultiProps<any>;

/**
 * ComboboxField is a wrapper around Combobox that adds the FormLabelGroup.
 * Because forms lack static type safety, it discards the type parameter of
 * Combobox; its values are `any` as with every other form field.
 */
function ComboboxMultiField({
  label,
  required,
  hint,
  rowClassName,
  // validate,     -- where did this come from in SelectField??
  // initialValue, -- TODO grok how default/initial value works (& add to Combobox et al, if necessary)
  feedback,
  onChange,
  // tableField, -- where did this come from in SelectField??
  id,
  ...rest
}: ComboboxMultiFieldProps) {
  return (
    <FormLabelGroup
      stacked
      label={label}
      required={required}
      hint={hint}
      rowClassName={classNames(rowClassName, {
        'no-gutters mb-0': false /*tableField*/,
      })}
      inputId={id}
      feedback={feedback}
    >
      <ComboboxMulti<any> id={id} onChange={onChange} {...rest} />
    </FormLabelGroup>
  );
}

export default ComboboxMultiField;
