import Input from '@appfolio/react-gears/lib/components/Input';
import Tooltip from '@appfolio/react-gears/lib/components/Tooltip';
import React, { useState } from 'react';
import './pdfCheckbox.scss';
import uniqid from 'uniqid';

interface Props {
  value: boolean;
  disabled?: boolean;
  onChange?: (event: any) => void;
  draggable?: boolean;
  backgroundColor?: string;
  tooltip?: React.ReactNode;
}

const GRAY = 'rgba(234, 234, 234, .9)';
const YELLOW = 'rgba(252, 239, 220, .9)';

const PdfCheckbox: React.FC<Props> = ({
  value,
  disabled,
  onChange,
  draggable,
  backgroundColor,
  tooltip,
}: Props) => {
  const [targetId] = useState(() => `pdf-checkbox${uniqid()}`);
  return (
    <label
      className="pdf-checkbox-container pdf-input"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
      style={{
        backgroundColor: backgroundColor || YELLOW,
        cursor: draggable ? 'move' : 'pointer',
      }}
      id={targetId}
    >
      {tooltip && (
        <Tooltip target={targetId} placement="top">
          {tooltip}
        </Tooltip>
      )}
      <Input
        type="checkbox"
        disabled={disabled}
        checked={value}
        onChange={e => {
          onChange && onChange(e.target.checked);
        }}
      />
      <span className="pdf-checkbox-checkmark" />
    </label>
  );
};

export default PdfCheckbox;
