import { parse } from 'qs';

export const ALLOWED_SEARCH_PARAMS = [
  'query', // global search
  'filters', // api filters
];
export function throwIfPathTraversal(
  str: string | undefined = '',
  callback: () => void = () => {}
) {
  const pathStr = str.split('?')[0];
  if (pathStr.includes('..')) {
    callback();
    throw new Error('Path traversal detected');
  }
  const searchParams = str.substring(str.indexOf('?') + 1);
  const query = parse(searchParams, {
    ignoreQueryPrefix: true,
  }) as Record<string, string>;

  Object.entries(query).forEach(([key, value]) => {
    if (!ALLOWED_SEARCH_PARAMS.includes(key) && value.includes('..')) {
      callback();
      throw new Error('Path traversal detected');
    }
  });
}
