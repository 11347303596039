import Button from '@appfolio/react-gears/lib/components/Button';
import ButtonToolbar from '@appfolio/react-gears/lib/components/ButtonToolbar';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@im-frontend/components/Modal';
import invariant from 'invariant';
import { inject } from 'mobx-react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TimingStore from '~/components/TimingStore';
import AppStore from '~/stores/AppStore';

type OwnProps = {
  appStore?: AppStore;
  timeout?: number;
  timingStore?: TimingStore;
  warning?: number;
};

interface State {
  isInactive: boolean;
  remaining: number;
}

type Props = OwnProps & RouteComponentProps;

// tolerate missing injections to help tests be loosely coupled
@inject((stores: any) => ({
  appStore: stores.appStore,
  timingStore: stores.timingStore,
}))
class ActivityMonitor extends React.Component<Props, State> {
  static defaultProps = {
    warning: 15,
  };

  countdownInterval: number | null = null;
  inactivityTimeout: number;

  state = {
    isInactive: false,
    remaining: this.props.warning,
  };

  componentDidMount() {
    if (this.props.appStore.isMobileApp) {
      throw Error('Activity Monitor should not be rendered in Mobile Apps');
    }
    if (this.props.appStore && this.props.timingStore) {
      this.becomeActive(true);
    }
  }

  componentWillUnmount() {
    const { clearInterval, clearTimeout } = this.props.timingStore;
    clearInterval(this.countdownInterval);
    clearTimeout(this.inactivityTimeout);
  }

  onSignOut() {
    const { appStore, history } = this.props;
    invariant(appStore && history, 'missing injected props');
    history.push('/login');
    appStore.signOut();
  }

  becomeInactive = () => {
    const { setInterval } = this.props.timingStore;
    if (!this.state.isInactive) {
      this.setState({ isInactive: true, remaining: this.props.warning });
      this.countdownInterval = setInterval(this.countdown, 1000);
    }
  };

  becomeActive = (force = false) => {
    const { clearInterval, setTimeout } = this.props.timingStore;
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    }

    if (this.state.isInactive || force) {
      const { warning } = this.props;
      const timeout = this.props.appStore.inactivityTimeout;
      this.setState({ isInactive: false });
      this.inactivityTimeout = setTimeout(
        this.becomeInactive,
        (timeout - warning) * 1000
      );
    }
  };

  countdown = () => {
    const { remaining } = this.state;
    if (remaining > 0) {
      this.setState({ remaining: remaining - 1 });
    } else {
      this.onSignOut();
    }
  };

  render() {
    const { isInactive, remaining } = this.state;
    return (
      <Modal backdrop fade isOpen={isInactive}>
        <ModalHeader>Are You There?</ModalHeader>
        <ModalBody>
          It&apos;s been a while since you interacted with your portal. For
          security reasons we will automatically log you out.
          <div className="text-center text-danger w-100">
            Time remaining: {remaining} sec
          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonToolbar>
            <Button color="primary" onClick={() => this.becomeActive()}>
              Keep Me Logged In
            </Button>
          </ButtonToolbar>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withRouter(ActivityMonitor);
