import { CircleSpinner as Spinner } from '@im-frontend/components/Spinner';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { appStore } from '~/stores/AppStore';
import { settingsStore } from '~/stores/SettingsStore';

// Route that redirects to /login unless user is authenticated.

export default observer(
  ({
    component: Component,
    location,
    keyed = true,
    ...rest
  }: RouteProps & {
    keyed?: boolean;
  }) => {
    // register variable dependency here so it will re-render when the value changes
    // https://stackoverflow.com/c/appfolio-investment-management/a/1081/17
    const isLoadingSetting = settingsStore.loading;
    return (
      <Route
        key={keyed ? location.pathname : undefined}
        {...rest}
        render={props =>
          appStore.isLoggedIn ? (
            isLoadingSetting ? (
              <Spinner />
            ) : (
              <Component {...props} />
            )
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
);
