import * as v1 from '@im-frontend/client/api/v1/schemas';
import isBlank from '@im-frontend/utils/isBlank';
import Big from 'big.js';
// eslint-disable-next-line no-restricted-imports
import * as dateFns from 'date-fns';
import numeral from 'numeral';
import { Optional } from 'utility-types';

export { asCurrency } from './asCurrency';

const EN_US_DATE = new RegExp('[0-9]{2}/[0-9]{2}/[0-9]{4}');

/**
 * Represent a boolean as a "Yes" or "No" value.
 */
export function asBoolean(value: 'true' | 'false'): string {
  return value === 'true' ? 'Yes' : 'No';
}

/**
 * Represent a date as a localized date string (possibly including time elements).
 *
 * This is a wrapper for date-fns format function, but it accepts more data types
 * as input (string, Date, number, null, undefined) and auto-converts to Date.
 *
 * When given invalid inputs (undefined, null, false) it mimics the behavior of
 * date-fns v1.
 */
export function asDate(value: any, format?: string): string {
  switch (typeof value) {
    case 'string':
      if (EN_US_DATE.test(value)) {
        value = dateFns.parse(value);
        // v2:
        //value = dateFns.parse(value, 'MM/dd/yyyy', new Date());
      } else {
        value = dateFns.parse(value);
        // v2:
        //value = dateFns.parseISO(value);
      }
      break;
    case 'number':
      value = new Date(value);
      break;
  }
  return dateFns.format(value, format);
}

// This is a subset of Intl.NumberFormatOptions
interface FormatOptions {
  minimumFractionDigits: number;
  maximumFractionDigits: number;
  useGrouping: boolean;
  withSymbol?: boolean;
}

const GROUPING_SEPARATOR = ','; // This is America
const DECIMAL_SEPARATOR = '.'; // This is America

/**
 * @deprecated use asNumber instead
 */
export function formatBig(
  value: string | Big | number,
  options: FormatOptions
): string {
  // guard against null/undefined/falsey values
  if (!value) {
    value = 0;
  }
  const bigValue = typeof value === 'object' ? value : new Big(value);
  // Note: in Big.js <6.0, toFixed doesn't take a custom rounding option, and `gp` initializes the rounding mode to "truncate", which we don't want here
  const roundedValue = bigValue.round(options.maximumFractionDigits, 1); // Rounding mode 1 is "round half away from 0" -- so, 0.5 -> 1, -0.5 -> -1.
  const baseStr = roundedValue.toFixed(options.maximumFractionDigits);
  let [wholePart, decimalPart] = baseStr.split('.'); // If Big itself becomes Intl-aware this might change from `'.'` to `DECIMAL_SEPARATOR`
  let signPart = '';
  if (wholePart[0] === '-') {
    signPart = '-';
    wholePart = wholePart.substring(1);
  }
  const unitsPart = options.withSymbol ? '$' : '';

  // Add group separators to the whole part, if necessary
  if (options.useGrouping) {
    wholePart = wholePart
      .split('') // Reverse the whole string, since we want to start grouping from the end, not the beginning
      .reverse()
      .join('')
      .split(/(...)/) // Split into groups of 3 digits (use capture group since we want the "separators")
      .filter(x => x) // Remove empty strings (since our digit groups are the "separators", they separate empty strings)
      .join(GROUPING_SEPARATOR) // Add in digit group separators
      .split('') // Finally, reverse the whole string again to undo the reverse at the beginning
      .reverse()
      .join('');
  }

  // Remove trailing zeroes until we reach the minimum allowable fraction digits
  while (
    decimalPart &&
    decimalPart.length > options.minimumFractionDigits &&
    decimalPart.endsWith('0')
  ) {
    decimalPart = decimalPart.substring(0, decimalPart.length - 1);
  }

  // If there is no decimal part, exclude trailing decimal separator
  if (!decimalPart) {
    return signPart + unitsPart + wholePart;
  }
  return signPart + unitsPart + wholePart + DECIMAL_SEPARATOR + decimalPart;
}

/**
 * Format a string containing a number of shares to present to the user
 */
export function asShares(
  shares: string | Big,
  options?: FormatOptions
): string {
  return formatBig(
    shares,
    Object.assign(
      {},
      {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 4,
      },
      options || {}
    )
  );
}

/**
 * Represent a number as a localized percentage.
 *
 * This is a wrapper for the numeral.js format function, but provides a simpler
 * way to specify decimalPoints.
 */
export function asPercent(
  value: any,
  options = { decimalPoints: 2 },
  roundMethod = Math.floor
) {
  const n = numeral(value).format(
    `0.${'0'.repeat(options.decimalPoints)}%`,
    roundMethod
  );

  return n;
}

/**
 *  Represent a number with “thousands separators” to make it easier to read long numbers
 */
export function asNumber(value: string | number | Big) {
  return formatBig(value, {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export const EMPTY_CONTACT_NAME = 'Unnamed Contact';

export const joinNameParts = (
  nameParts: string[],
  defaultName: string,
  fallbackName?: string
) => {
  if (nameParts.length === 0) {
    return fallbackName || defaultName;
  }

  return nameParts.join(' ');
};

export const asContactName = (
  contact: Optional<
    Pick<
      v1.Contact,
      'firstName' | 'middleName' | 'lastName' | 'companyName' | 'preferredName'
    >
  >,
  options?: {
    usePreferredName?: boolean;
    includeMiddleName?: boolean;
    firstNameFirst?: boolean;
  }
) => {
  const { usePreferredName, includeMiddleName, firstNameFirst } = Object.assign(
    {},
    {
      usePreferredName: false,
      includeMiddleName: false, // TODO: always include middle name?
      firstNameFirst: true,
    },
    options || {}
  );

  const { firstName, middleName, lastName, companyName, preferredName } =
    contact;

  const nameFrontParts = [
    firstName,
    usePreferredName && !isBlank(preferredName) ? `"${preferredName}"` : null,
    includeMiddleName && !isBlank(middleName) ? middleName : null,
  ].filter(n => !isBlank(n)) as string[];

  if (firstNameFirst) {
    return joinNameParts(
      nameFrontParts.concat(isBlank(lastName) ? [] : ([lastName] as string[])),
      EMPTY_CONTACT_NAME,
      companyName
    );
  } else {
    if (!isBlank(lastName)) {
      return joinNameParts(
        [`${lastName},`].concat(nameFrontParts),
        EMPTY_CONTACT_NAME,
        companyName
      );
    } else {
      return joinNameParts(nameFrontParts, EMPTY_CONTACT_NAME, companyName);
    }
  }
};

export const asAccruedPref = (accruedPref: number) =>
  accruedPref >= 0.1 ? accruedPref : 0;
