import investorPortalClient from './clients/investorPortal';

export function linkPayee(body: any) {
  return investorPortalClient.post('/plaid/actions/link', body);
}

export function unlinkPayee(entityId: any) {
  return investorPortalClient.post('/plaid/actions/unlink', {
    entity: {
      id: entityId,
    },
  });
}

export function createPlaidLinkToken(
  sameDayMicroDepositVerifyFlow = false,
  entityId: number
) {
  return investorPortalClient.post('/plaid/actions/create_plaid_link_token', {
    sameDayMicroDepositVerifyFlow,
    entity: {
      id: entityId,
    },
  });
}

export function createPlaidLinkTokenV2(
  sameDayMicroDepositVerifyFlow = false,
  bankAccountId: number
) {
  return investorPortalClient.post(
    '/plaid/actions/create_plaid_link_token_v2',
    {
      sameDayMicroDepositVerifyFlow,
      bankAccount: {
        id: bankAccountId,
      },
    }
  );
}
