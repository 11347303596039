import { createClient } from '.';

// pick up config injected by frontend bootstrapper
const root = document.getElementById('root');
const dataset = root ? root.dataset : null;

// point to appfolio-im-dev gateway if none provided
const defGateway = 'https://a4cszqkfa2.execute-api.us-east-2.amazonaws.com';

// hopefully the gateway will be provided as a data attribute
const gatewayBase = dataset ? dataset.functionsGatewayBaseurl : defGateway;

const cloudFunctions = createClient({
  baseURL: `${gatewayBase}/20190901`,
  headers: null,
  interceptors: {
    reloadDuringRelease: false,
    retryCertainHttpCodes: false,
    enforceMaxRequestSize: false,
  },
});

export default cloudFunctions;
