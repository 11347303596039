/**
 * Infer display metadata for this browser & its host machine. Used for analytics e.g. Pendo.
 */
export default function getUserDimensions(win = window) {
  const browserWidth = win.innerWidth || -1;
  const browserHeight = win.innerHeight || -1;
  const screenWidth = (win.screen && win.screen.width) || -1;
  const screenHeight = (win.screen && win.screen.height) || -1;

  const qry = window.matchMedia('(prefers-color-scheme: dark)');
  const prefersColorScheme =
    qry.media === 'not all' ? 'none' : qry.matches ? 'dark' : 'light';

  return {
    browserWidth,
    browserHeight,
    prefersColorScheme,
    screenWidth,
    screenHeight,
  };
}
