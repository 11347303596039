import isValid from 'date-fns/is_valid';
import setDate from 'date-fns/set_date';
import fecha from 'fecha';

// Accepted Formats (delimeter could be any non-digit character):
// DD (assumes current month / year, adds DD to 1st of the month to allow values greater than 31)
// MMDD (assumes current year)
// MMDDYY (assumes current year)
// MMDDYYYY
// MM/DD (assumes current year)
// MM/DD/YY
// MM/DD/YYYY
// YYYY/MM/DD
// Sep 15 (assumes current year)
// Sep 15, YY
// Sep 15, YYYY
// September 15 (assumes current year)
// September 15, YY
// September 15, YYYY
const formats: Record<any, RegExp> = {
  DD: /^(\d{1,2})$/,
  MMDD: /^(\d{2})(\d{2})$/,
  MMDDYY: /^(\d{2})(\d{2})(\d{2})$/,
  MMDDYYYY: /^(\d{2})(\d{2})(\d{4})$/,
  'MM/DD': /^(\d{1,2})[\W-](\d{1,2})$/,
  'MM/DD/YY': /^(\d{1,2})[\W-](\d{1,2})[\W-](\d{2})$/,
  'MM/DD/YYYY': /^(\d{1,2})[\W-](\d{1,2})[\W-](\d{4})$/,
  'YYYY/MM/DD': /^(\d{4})[\W-](\d{1,2})[\W-](\d{1,2})$/,
  'MMM/DD': /^(\w{3})[\W-]+\d{1,2}$/,
  'MMM/DD/YYYY': /^(\w{3})[\W-]+\d{1,2}[\W-]+\d{4}$/,
  'MMM/DD/YY': /^(\w{3})[\W-]+\d{1,2}[\D]+\d{2}$/,
  'MMMM/DD': /^(\w{3,9})[\W-]+\d{1,2}$/,
  'MMMM/DD/YY': /^(\w{3,9})[\W-]+\d{1,2}[\D]+\d{2}$/,
  'MMMM/DD/YYYY': /^(\w{3,9})[\W-]+\d{1,2}[\D]+\d{4}$/,
};

export default function dateParse(value: string) {
  // remove leading whitespace and trailing non-digit characters
  // e.g. convert " sept 14th" to "sept 14"
  let dateString = value.replace(/(^\s*)|(\D+$)/g, '');

  // convert all delimiters to a slash for simplicity
  dateString = dateString.replace(/[\W-]+/g, '/');

  // eslint-disable-next-line
    // eslint-disable-next-line
    for (let format in formats) {
    const regex = formats[format];
    if (regex.test(dateString)) {
      const year = new Date().getFullYear();

      // add the current year to formats without it
      // eslint-disable-next-line
        switch (format) {
        case 'DD':
          format = 'MM/DD/YYYY';
          dateString = fecha.format(
            setDate(new Date(), parseInt(dateString, 10)),
            format
          );
          break;
        case 'MMDD':
          format = 'MMDDYYYY';
          dateString += year;
          break;
        case 'MM/DD':
          format = 'MM/DD/YYYY';
          dateString += `/${year}`;
          break;
        case 'MMM/DD':
          format = 'MMM/DD/YYYY';
          dateString += `/${year}`;
          break;
        case 'MMMM/DD':
          format = 'MMMM/DD/YYYY';
          dateString += `/${year}`;
          break;
      }

      const m = fecha.parse(dateString, format);

      // fecha sometimes returns false/null, which causes isValid to throw
      return m instanceof Date && isValid(m) ? m : '';
    }
  }

  return '';
}
