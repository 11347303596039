import { Select } from '@appfolio/react-gears';
import { sortBy } from 'lodash';
import React, { ComponentProps, useMemo } from 'react';

export type SelectInputProps = ComponentProps<typeof Select>;

export interface SelectOption {
  label: string;
  value: any;
}

const SelectInput = ({
  options,
  disableDefaultSort,
  clearable,
  ...rest
}: SelectInputProps) => {
  const sortedOptions = useMemo(() => {
    return disableDefaultSort
      ? options
      : options && sortBy(options, o => o.label?.toLowerCase());
  }, [disableDefaultSort, options]);

  return (
    <Select
      options={sortedOptions}
      clearable={clearable}
      backspaceRemoves={clearable}
      deleteRemoves={clearable}
      {...rest}
    />
  );
};

export default SelectInput;
