import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import classNames from 'classnames';
import React from 'react';
import Select, { SelectInputProps, SelectOption } from '../inputs/SelectInput';

const withDefaultValue = (value?: SelectOption) =>
  value === undefined ? null : value;

const extractValue = (
  option: SelectOption | SelectOption[],
  callback: (option: any) => void
) => {
  if (Array.isArray(option)) {
    const selectOptions = option.map(item => withDefaultValue(item?.value));
    callback(selectOptions);
    return;
  }

  callback(withDefaultValue(option?.value));
};

export type SelectFieldProps = FormLabelGroupProps & SelectInputProps;

const SelectField = ({
  label,
  required,
  hint,
  rowClassName,
  validate,
  initialValue,
  feedback,
  onChange,
  tableField,
  id,
  ...rest
}: SelectFieldProps) => (
  <FormLabelGroup
    stacked
    label={label}
    required={required}
    hint={hint}
    rowClassName={classNames(rowClassName, { 'no-gutters mb-0': tableField })}
    inputId={id}
    feedback={feedback}
  >
    <Select
      id={id}
      onChange={
        rest.creatable
          ? onChange
          : (option: SelectOption | SelectOption[]) =>
              extractValue(option, onChange)
      }
      // This will make react-gears select render correctly when initialValue is not true
      // https://github.com/appfolio/react-gears/blob/master/src/components/Select.js#L12
      defaultValue={initialValue}
      {...rest}
    />
  </FormLabelGroup>
);

export default SelectField;
