import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { acceptsOnlyJsonV1iApi } from '~/api';
import { appStore } from '~/stores/AppStore';
import { createDataDependencies } from '~/utils/DataStore';

export const Data = createDataDependencies({
  opportunities: acceptsOnlyJsonV1iApi.opportunities.index,
});

const PageProvider = ({ children }: { children: React.ReactNode }) => {
  const [data] = useState(Data.createStore);

  useEffect(() => {
    if (appStore.isLoggedIn && appStore.authenticatedWithPassword) {
      data.opportunities.triggerRequest();
    }
  }, [
    data.opportunities,
    appStore.isLoggedIn,
    appStore.authenticatedWithPassword,
  ]);

  return <Data.Context.Provider value={data}>{children}</Data.Context.Provider>;
};

export default observer(PageProvider);
