export default function syncNetStats(client: any, netStore: any) {
  client.interceptors.request.use((config: any) => {
    netStore.onRequest(config);
    return config;
  });

  client.interceptors.response.use(
    (response: any) => {
      netStore.onResponse(response);
      return response;
    },
    (error: any) => {
      const { response } = error;
      netStore.onResponse(response);
      return Promise.reject(error);
    }
  );
}
