import RequestStore from '@im-frontend/data-store/RequestStore';
import * as namespaceInfo from '~/namespaceInfo';
import { toastStore } from '~/stores/ToastStore';

RequestStore.toastNow = toastStore.toastNow;
RequestStore.namespaceInfo = namespaceInfo;
RequestStore.timeLogging = (metadata: any) => {
  if (window.newrelic) {
    window.newrelic.addPageAction('dataStoreTiming', metadata);
  }
};
