import { imSessionStorage } from '@im-frontend/utils/storages';
import getUserDimensions from '@im-frontend/utils/windowHelper';
import investorPortalClient from './clients/investorPortal';

export function create(feedback: string) {
  const { browserWidth, browserHeight, screenWidth, screenHeight } =
    getUserDimensions();

  return investorPortalClient.post('/feedback', {
    feedback,
    url: window.location.href,
    uiVersion: imSessionStorage?.getItem('ui') || 'prod',
    screenHeight,
    screenWidth,
    browserHeight,
    browserWidth,
  });
}
