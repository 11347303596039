//@ts-strict
import { init as initScreenRecording } from '@im-frontend/utils/ScreenRecording';
import * as cypress from '@im-frontend/utils/cypress';
import { useSanitizedNewRelicBrowserInteractionName } from '@im-frontend/utils/newRelic';
import { configure } from 'mobx';
import { observer } from 'mobx-react';
import React, { Suspense, lazy } from 'react';
import { hot } from 'react-hot-loader/root';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import apiClient from '~/api/clients/investorPortal';

const Appf = lazy(() => import('~/components/Appf'));
const Assets = lazy(() => import('~/components/Assets'));
const Contact_Us = lazy(() => import('~/components/Contact_Us'));
const Dashboard = lazy(() => import('~/components/Dashboard'));
const Transactions = lazy(() => import('~/components/Transactions'));
const Documents = lazy(() => import('~/components/Documents'));
const Investments = lazy(() => import('~/components/Investments'));
const InvestorQuestionnaire = lazy(
  () => import('~/components/InvestorQuestionnaire')
);
const Investors = lazy(() => import('~/components/Investors'));
const Opportunity = lazy(() => import('~/components/Opportunities'));
const OpportunityAssets = lazy(() => import('~/components/OpportunityAssets'));
const ProjectUpdates = lazy(() => import('~/components/ProjectUpdates'));
const RecentOfferings = lazy(() => import('~/components/RecentOfferings'));
const Session = lazy(() => import('~/components/Session'));
const Go = lazy(() => import('~/components/Session/Go'));
const Settings = lazy(() => import('~/components/Settings'));
const Taxes = lazy(() => import('~/components/Taxes'));
const UserShow = lazy(() => import('~/components/GeneralPartners/Show'));
import LpColorModeProvider from '~/components/LpColorModeProvider';
import Page from '~/components/shared/Page';
import PageProvider from '~/components/shared/Page/PageProvider';
import PrivateRoute from '~/components/shared/PrivateRoute';
import stayAuthorized from '~/reactions/stayAuthorized';
import { syncSentryData } from '~/reactions/syncSentryData';
import AppStore from '~/stores/AppStore';
import NavigationAwareStoreProvider from '~/stores/NavigationAwareStoreProvider';
import { inject } from '~/utils/inject';
import { MobileAppReviewProvider } from './shared/MobileAppReviewModal';

/* tslint:disable:ordered-imports */
import '~/styles/base.scss';
import '~/styles/index.scss';
import '~/styles/print.scss';
import '~/styles/screen.scss';
import '~/styles/shame.scss';
import '~/styles/dark.scss';
import '~/styles/dark-overrides.scss';
/* tslint:enable:ordered-imports */

configure({ enforceActions: 'observed' });

if (!cypress.isPresent) {
  initScreenRecording();
}

export interface InjectedProps {
  appStore: AppStore;
}

function LazyLoadFallback() {
  return <Page lazyLoading></Page>;
}

const NewRelicWrapper = ({ children }: { children: React.ReactNode }) => {
  useSanitizedNewRelicBrowserInteractionName();
  return <>{children}</>;
};

class App extends React.Component<InjectedProps & RouteComponentProps> {
  constructor(props: InjectedProps & RouteComponentProps) {
    super(props);
    syncSentryData(this.props.appStore);
    stayAuthorized(apiClient, this.props.appStore, this.props.history);
  }

  render() {
    return (
      <NewRelicWrapper>
        <LpColorModeProvider>
          <NavigationAwareStoreProvider>
            <MobileAppReviewProvider>
              <PageProvider>
                <Suspense fallback={<LazyLoadFallback />}>
                  <Switch>
                    <Redirect exact from="/" to="/investments" />
                    <Redirect exact from="/dashboard" to="/investments" />
                    <PrivateRoute path="/appf" component={Appf} />
                    <PrivateRoute
                      exact
                      path="/investments"
                      component={Dashboard}
                    />
                    <PrivateRoute path="/taxes" component={Taxes} />
                    <PrivateRoute
                      path="/documents/questionnaires/"
                      component={InvestorQuestionnaire}
                    />
                    <PrivateRoute
                      keyed={false}
                      path="/documents"
                      component={Documents}
                    />

                    <PrivateRoute
                      path="/investments/:fundId/assets"
                      component={Assets}
                    />
                    <PrivateRoute
                      path="/recent_offerings/:fundId/assets"
                      component={Assets}
                    />
                    <PrivateRoute
                      keyed={false}
                      path="/investments"
                      component={Investments}
                    />

                    <Redirect path="/distributions" to="/transactions" />
                    <PrivateRoute
                      path="/transactions"
                      component={Transactions}
                    />

                    <PrivateRoute path="/settings" component={Settings} />
                    <PrivateRoute
                      path="/opportunities"
                      component={Opportunity}
                    />
                    <PrivateRoute path="/contact_us" component={Contact_Us} />
                    <PrivateRoute
                      path="/opportunity_assets"
                      component={OpportunityAssets}
                    />
                    <PrivateRoute
                      path="/project-updates"
                      component={ProjectUpdates}
                    />
                    <PrivateRoute path="/investors" component={Investors} />
                    <PrivateRoute
                      path="/recent_offerings"
                      component={RecentOfferings}
                    />
                    <Route path="/go/:target" component={Go} />
                    <Route path="/contact-us" component={Session} />
                    <Route path="/unsubscribe" component={Session} />
                    <Route path="/login" component={Session} />
                    <Route path="/mfa" component={Session} />
                    <Route path="/signup" component={Session} />
                    <Route path="/reset_password" component={Session} />
                    <Route path="/request_access" component={Session} />
                    <Route path="/submit_interest" component={Session} />
                    <Route
                      path="/general_partners/:uuid"
                      component={UserShow}
                    />
                    <Route
                      path="/public_opportunities"
                      component={(props: any) => (
                        <Opportunity {...props} isPublic />
                      )}
                    />
                    <Redirect path="/sign_in" to="/login" />
                    <Route component={Page.NotFound} />
                  </Switch>
                </Suspense>
              </PageProvider>
            </MobileAppReviewProvider>
          </NavigationAwareStoreProvider>
        </LpColorModeProvider>
      </NewRelicWrapper>
    );
  }
}

export default hot(withRouter(inject('appStore')(observer(App))));
