import { inject, observer } from 'mobx-react';
import React from 'react';

import AppStore from '~/stores/AppStore';

const bannerClasses =
  'banner bg-secondary mx-0 my-0 py-2 text-center text-white';

interface Props {
  appStore?: AppStore;
}

@inject('appStore')
@observer
export default class HeaderBanner extends React.Component<Props> {
  render() {
    const { session } = this.props.appStore;
    let banner = null;
    if (session?.preview) {
      banner = (
        <h6 className={bannerClasses}>
          This is a preview of what your investor will see in their portal.
        </h6>
      );
    }

    return banner;
  }
}
