import { compact, merge } from 'lodash';
import isBlank from './isBlank';

const DEFAULT = '--';

export function withDefaultCustomizable<Type>(
  val: Type,
  inputOptions?: {
    additionalFilter?: (x?: Type) => boolean;
    callback?: (x: Type) => any;
    default?: any;
  }
) {
  const options = merge(
    {},
    {
      default: DEFAULT,
      additionalFilter: () => true,
      callback: (x: Type) => x,
    },
    inputOptions
  );
  if (!options.additionalFilter(val)) {
    return options.default;
  }
  return withDefault(val, ...compact([options.callback, options.default]));
}

function withDefault<Type>(
  val?: Type,
  callback: (x: Type) => any = (x: Type) => x,
  _default: any = DEFAULT
) {
  if (isBlank(val)) {
    return _default;
  }

  return callback(val as Type);
}

export default withDefault;
