export function phoneWithCountryCodeValidator(values: any) {
  if (values.phone && !/^\d+$/.test(values.phone)) {
    return {
      phone: 'Please only enter digits',
    };
  }
  const countryCode = values.countryCode?.value || values.countryCode;
  if (countryCode === '1' && values.phone && values.phone.length !== 10) {
    return {
      phone: 'Please enter a 10-digit phone number',
    };
  }
  return null;
}
