import { computed } from 'mobx';
import { IDependenciesStore } from './DependenciesStore';
import RequestStore from './RequestStore';
import { validateProperties } from './utils';

const INVALID_KEYS = ['reload', 'isFetching', 'isLoading', 'scoped'];

type DependencyScopes<T extends IDependenciesStore> = {
  [K in keyof T]?: T[K]['fields'];
};

type MappedRequests<
  T extends IDependenciesStore,
  S extends DependencyScopes<T>
> = {
  [K in keyof T]: RequestStore<T[K], S[K]>;
};

export type IDataStore<
  T extends IDependenciesStore,
  S extends DependencyScopes<T>
> = DataStore<T, S> & MappedRequests<T, S>;

export class DataStore<
  T extends IDependenciesStore,
  S extends DependencyScopes<T>
> {
  // typescript doesnt support mapped properties on classes so use this to fake it
  private get requestStores(): MappedRequests<T, S> {
    return this as any;
  }

  private dependencies: T;

  private get keys(): (keyof T)[] {
    return Object.keys(this.dependencies._requests);
  }

  constructor(dependencies: T) {
    validateProperties(dependencies, INVALID_KEYS);

    this.dependencies = dependencies;

    this.keys.forEach(key => {
      const value = new RequestStore(dependencies[key]) as RequestStore<
        T[keyof T],
        S[keyof S]
      >;
      this.requestStores[key] = value;
    });
  }

  reload = () => {
    return Promise.all(this.keys.map(key => this.requestStores[key].reload()));
  };

  @computed
  get isFetching() {
    return this.keys.some(key => this.requestStores[key].isFetching);
  }

  @computed
  get isLoading() {
    return this.keys.some(key => this.requestStores[key].isLoading);
  }

  scoped<NewScope extends DependencyScopes<T>>(
    scope: NewScope
  ): IDataStore<T, NewScope> {
    // TODO: use the scope to actually scope the object in JS?
    return this as any;
  }
}

export function createDataStore<
  T extends IDependenciesStore,
  S extends DependencyScopes<T> = any
>(dependencies: T): IDataStore<T, S> {
  return new DataStore(dependencies) as any;
}
