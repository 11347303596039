import * as Sentry from '@sentry/react';
import { keyBy, mapValues } from 'lodash';
import { parse, stringify } from 'qs';

const excludedXHRBreadCrumbs = [
  'https://rs.fullstory.com',
  'https://bam-cell.nr-data.net',
];

export const canIgnoreBreadCrumb = (
  breadcrumb: Sentry.Breadcrumb,
  _hint: Sentry.BreadcrumbHint
) => {
  if (breadcrumb.category === 'xhr') {
    const bingo = excludedXHRBreadCrumbs.some(function (v) {
      return breadcrumb.data?.url.indexOf(v) >= 0;
    });
    if (bingo) {
      // drops the bread crumb
      return true;
    }
  }

  return false;
};

const sanitizeUrl = (url: string) => {
  const [path, ...queryParts] = url.split('?');
  const query = parse(queryParts.join('?'));

  if (Object.keys(query).length === 0) {
    return url;
  }

  const secretKeyLookup = keyBy([
    // taken from https://docs.sentry.io/product/data-management-settings/scrubbing/server-side-scrubbing/
    'password',
    'secret',
    'passwd',
    'api_key',
    'apikey',
    'access_token',
    'auth',
    'credentials',
    'mysql_pwd',
    'stripetoken',
    'card[number]',
    'github_token',
    'privatekey',
    'private_key',
    // somehow token isnt in the list?
    'token',
  ]);

  const sanitizedQuery = mapValues(query, (value, key) =>
    key in secretKeyLookup ? '[CLIENT_SIDE_FILTERED]' : value
  );

  return `${path}?${stringify(sanitizedQuery)}`;
};

export const sanitizeEvent = <T extends Sentry.Event | undefined>(event: T) => {
  if (event?.request?.url) {
    event.request.url = sanitizeUrl(event.request.url);
  }

  if (event?.request?.headers?.Referrer) {
    event.request.headers.Referrer = sanitizeUrl(
      event.request.headers.Referrer
    );
  }

  return event;
};
