import GearsModal from '@appfolio/react-gears/lib/components/Modal';
import GearsModalBody from '@appfolio/react-gears/lib/components/ModalBody';
import GearsModalFooter from '@appfolio/react-gears/lib/components/ModalFooter';
import GearsModalHeader from '@appfolio/react-gears/lib/components/ModalHeader';
import { safeExitFullscreen } from '@im-frontend/utils/fullscreen';
import React from 'react';

import './styles.scss';

/**
 * Wrapper for the react-gears Modal that imposes some DREAM-specific defaults
 * and makes Modal's child components easier to impor (by exporting them from
 * this file as non-defaults).
 */
const Modal = (props: any) => (
  <GearsModal
    {...props}
    onOpened={() => {
      safeExitFullscreen();
      props.onOpened && props.onOpened();
    }}
  />
);
Modal.defaultProps = {
  backdrop: 'static',
  fade: true,
  zIndex: 'auto',
  autoFocus: false, // otherwise autoFocusing a form field in a modal does not work
};
export default Modal;

export const ModalBody = GearsModalBody;

export const ModalFooter = GearsModalFooter;

export const ModalHeader = GearsModalHeader;
