import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import Input from '@appfolio/react-gears/lib/components/Input';
import React, { ComponentProps } from 'react';

export type InputProps = ComponentProps<typeof Input>;
export type TextFieldProps = FormLabelGroupProps & InputProps;

const EmailFormField = ({
  label,
  required,
  hint,
  rowClassName,
  validate,
  initialValue,
  feedback,
  id,
  placeholder,
  templateExtraProps,
  ...inputProps
}: TextFieldProps) => (
  <FormLabelGroup
    stacked
    label={label}
    required={required}
    hint={hint}
    inputId={id}
    rowClassName={rowClassName}
    feedback={feedback}
  >
    <Input placeholder={placeholder} {...inputProps} />
  </FormLabelGroup>
);

export const validateEmail = (email: any) => {
  if (!email) {
    return 'Please enter a valid email address';
  }
  const atIndex = email.indexOf('@');
  const dotIndex = email.lastIndexOf('.');
  const valid =
    atIndex + 1 < dotIndex &&
    atIndex > 0 &&
    dotIndex < email.length - 1 &&
    email.indexOf('@', atIndex + 1) < 0 &&
    email.indexOf('.', dotIndex + 1) < 0;

  return valid ? undefined : 'Please enter a valid email address';
};

export default EmailFormField;
