import FormLabelGroup from '@appfolio/react-gears/lib/components/FormLabelGroup';
import Input from '@appfolio/react-gears/lib/components/Input';
import React from 'react';
import { Label } from 'reactstrap';

const SimpleCheckboxFormField = ({
  label,
  name,
  required,
  hint,
  rowClassName,
  feedback,
  ...inputProps
}: any) => (
  <FormLabelGroup
    stacked
    required={required}
    hint={hint}
    rowClassName={rowClassName}
    feedback={feedback}
  >
    <Label check className="ml-4">
      <Input type="checkbox" id="checkbox2" {...inputProps} />
      <span className="ml-1">{label}</span>
    </Label>
  </FormLabelGroup>
);

export default SimpleCheckboxFormField;
