import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import Input from '@appfolio/react-gears/lib/components/Input';
import classNames from 'classnames';
import React, { ComponentProps } from 'react';

export type InputProps = ComponentProps<typeof Input>;
export type TextFieldProps = FormLabelGroupProps & InputProps;

const TextField = ({
  feedback,
  label,
  required,
  hint,
  id,
  initialValue,
  rowClassName,
  tableField,
  validate,
  ...inputProps
}: TextFieldProps) => (
  <FormLabelGroup
    stacked
    label={label}
    required={required}
    hint={hint}
    inputId={id}
    rowClassName={classNames(rowClassName, { 'no-gutters mb-0': tableField })}
    feedback={feedback}
  >
    <Input id={id} {...inputProps} />
  </FormLabelGroup>
);

export default TextField;
