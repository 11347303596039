import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from './clients/investorPortal';

export const index = (params: Query) =>
  investorPortalClient.get(`/signature_signers?${stringify(params)}`);

export const show = (id: number, params: Query) =>
  investorPortalClient.get(`/signature_signers/${id}?${stringify(params)}`);

export const executeSignature = ({
  id,
  signature,
  title,
  signatureFields,
}: {
  id: number;
  signature: string;
  title: string;
  signatureFields?: any;
}) => {
  const params = {
    signature,
    title,
  } as any;
  signatureFields && (params.signature_fields = signatureFields);

  return investorPortalClient.post(
    `/signature_signers/${id}/actions/execute_signature`,
    params
  );
};

export const save = ({
  id,
  signatureFields,
}: {
  id: number;
  signatureFields?: any;
}) => {
  const params = {} as any;
  signatureFields && (params.signature_fields = signatureFields);

  return investorPortalClient.post(
    `/signature_signers/${id}/actions/save`,
    params
  );
};
