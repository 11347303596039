import * as MobileBridge from '@appfolio/mobile-bridge';
import { maybeReportException } from '@im-frontend/utils/errors';
import isBlank from '@im-frontend/utils/isBlank';
import jwtDecode from 'jwt-decode';
import { action, computed, observable, runInAction } from 'mobx';
import api, { Api, generatedV1iApi } from '~/api';
import cloudFunctionsClient from '~/api/clients/cloudFunctions';
import apiClient, {
  acceptOnlyJsonClient,
  docsClient,
  uploadsClient,
} from '~/api/clients/investorPortal';
import loginClient from '~/api/clients/investorPortalLogin';
import mfaClient from '~/api/clients/mfaClient';
import * as im from '~/models';
import { accessToken, namespace } from '~/namespaceInfo';
import MfaStore from './MfaStore';

export type IMobileBridge = typeof MobileBridge;

interface SessionInfo extends im.SessionInfo {
  authenticatedWithPassword?: boolean;
  expiresAt?: number;
}

interface SessionOptions {
  persist: boolean;
  authenticatedWithPassword?: boolean;
}

/**
 * Repository for state that is shared among pages in current tab (i.e. in storage or VM memory).
 * Responsible for managing and persisting API session state (i.e. "login" to API), including impersonation.
 *
 * TODO: rename me to SessionStore. The "app store" is really the GlobalsProvider.
 */
export default class AppStore {
  // Storage key for persisting API session objects.
  // NB: duplicated in tests! Use global search+replace if you rename or change value.
  static get sessionStorageKey() {
    return `${namespace}.investorPortal.session`;
  }

  // Storage key for persisting timestamp of navigation between pages.
  // NB: duplicated in tests! Use global search+replace if you rename or change value.
  static get activityStorageKey() {
    return `${namespace}.investorPortal.activityAt`;
  }

  // Used to store remember-me token for 2fa
  // (2fa is skipped if browser has this token and it is not expired)
  static remember2faDeviceTokenStorageKey(email: any) {
    if (!email) {
      return '';
    }
    return `${namespace}.${email.toLowerCase()}.investorPortal.deviceToken`;
  }

  static defaultInactivityTimeoutMinutes = 30;
  // Number of seconds user can be inactive before we end their session.
  // NB: duplicated in tests! Use global search+replace if you rename or change value.
  @observable inactivityTimeout = AppStore.defaultInactivityTimeoutMinutes * 60;

  @observable activityAt: Date;
  @observable firmInfo: Partial<im.FirmInfo>;
  @observable session: SessionInfo | null;
  @observable lastMfaMethod: string;
  @observable requireMfaRegistration = false;
  @observable emailToken: string;
  @observable email: string;
  @observable mfaInProgress = false;
  @observable isNewContact = false;
  @observable mfaPhoneNumberLast4: string;
  @observable successfullyResetPassword: boolean;
  @observable signInWithTokenInProgress: boolean = false;

  password: string;

  badIdToken?: string;
  goodIdToken?: string;
  sessionErrorCount: number;
  api: Api;
  storage: any; // TODO be more specific
  localStorage: any;
  mfa: MfaStore;
  mobileBridge: IMobileBridge;

  constructor(
    api: any,
    storage: any,
    localStorage: any,
    mobileBridge?: IMobileBridge
  ) {
    this.api = api;
    this.storage = storage;
    this.localStorage = localStorage;
    this.mobileBridge = mobileBridge;
    const activityAt = storage?.getItem(AppStore.activityStorageKey);
    this.activityAt = activityAt ? new Date(activityAt) : new Date();
    this.firmInfo = { name: 'Investor Portal' };
    this.session = null;
    this.sessionErrorCount = 0;
    this.mfa = new MfaStore();

    if (!isBlank(accessToken)) {
      this.signInWithToken(accessToken);
    }

    // Prevent flicker by auto immediately remembering-me before initial
    // page view if able.
    if (this.canRememberMe && this.isRecentlyActive) {
      this.rememberMe();
    }

    // load firm info no matter what
    this.loadFirmInfo();
  }

  // Determine whether we can log the user in (via stored token) without a trip to the login page.
  //
  // @return {Boolean}
  get canRememberMe() {
    const now = new Date().getTime();
    const saved = JSON.parse(
      this.storage?.getItem(AppStore.sessionStorageKey) || '{}'
    );
    return !!(saved && saved.expiresAt > now) && this.isRecentlyActive;
  }

  // Returns true iff store has an API access token.
  @computed
  get isLoggedIn() {
    return !!(this.session && this.session.accessToken);
  }

  @computed
  get isHalfLoggedIn() {
    return !!(
      this.session &&
      this.session.accessToken &&
      !this.session.authenticatedWithPassword &&
      !this.session.preview
    );
  }

  @computed
  get authenticatedWithPassword(): boolean {
    return !!(this.session?.authenticatedWithPassword || this.session?.preview);
  }

  // Determine whether user is interacting with the UI right now.
  // NB: NOT computed!
  get isRecentlyActive() {
    return (
      this.activityAt &&
      +Date.now() - +this.activityAt < this.inactivityTimeout * 1000
    );
  }

  get isMobileApp() {
    return !!this.mobileBridge?.Detector.usingMobileApp;
  }

  @action
  setInactivityTimeoutTime(timeoutTime: number) {
    this.inactivityTimeout = timeoutTime * 60;
  }

  // Note that a user has just interacted with the UI.
  @action
  recordActivity() {
    this.activityAt = new Date();
    this.storage?.setItem(AppStore.activityStorageKey, this.activityAt);
  }

  // Restore prior access token from browser storage.
  @action
  async rememberMe() {
    if (this.canRememberMe) {
      const session = JSON.parse(
        this.storage?.getItem(AppStore.sessionStorageKey)
      );
      this.useSession(session, {
        persist: false,
      });
      return true;
    }

    throw new Error('No remember-me state in storage');
  }

  @action.bound
  setSuccessfullyResetPassword(successfullyResetPassword: boolean) {
    this.successfullyResetPassword = successfullyResetPassword;
  }

  @action.bound
  setMfaInfo(session: im.SessionInfo, password: string) {
    mfaClient.defaults.headers.common.Authorization = `Bearer ${session.accessToken}`;
    this.mfaInProgress = true;
    if (session.imMfa) {
      this.requireMfaRegistration = session.imMfa.registrationRequired;
      this.lastMfaMethod = session.imMfa.lastMethod;
      this.isNewContact = session.imMfa.isNewContact;
      this.mfaPhoneNumberLast4 = session.imMfa.phoneNumberLast4;
    }
    this.email = session.email;
    this.password = password; // store to send again in 2fa auth
  }

  // Authenticate to IM API using an investor email address and password.
  //
  // @return {Promise} resolves with true; rejects with an error (e.g. wrong password)
  @action
  async signIn(email: string, password: string) {
    this.session = null;
    try {
      const response = await this.api.login.password({
        email,
        password,
        remember2faDeviceToken: this.deviceToken(email),
      });
      const session = response.data as im.SessionInfo;

      if (session.imMfa && session.imMfa.loginRequired) {
        this.setMfaInfo(session, password);
        return true;
      } else if (session.email2faRequired) {
        runInAction(() => {
          this.email = session.email;
          this.password = password; // store to send again in 2fa auth
        });
        return true;
      } else {
        this.useSession(session, {
          persist: true,
          authenticatedWithPassword: true,
        });
      }

      return true;
    } catch (err) {
      runInAction(() => {
        this.sessionErrorCount += 1;
      });
      maybeReportException(err);
      throw err;
    }
  }

  @action.bound
  async verifyMfaToken(
    token: string,
    mfaMethod: 'sms' | 'call' | 'totp',
    rememberMe: boolean
  ) {
    const response = await this.api.login.mfaVerify(
      this.email,
      this.password,
      token,
      mfaMethod,
      this.isNewContact,
      rememberMe
    );
    const session = response.data as im.SessionInfo;
    this.password = null;
    runInAction(() => {
      if (rememberMe && session.remember2faDeviceToken) {
        this.storeDeviceToken(session.remember2faDeviceToken, this.email);
      }

      this.useSession(session, {
        persist: true,
        authenticatedWithPassword: true,
      });
      this.mfaInProgress = false;
    });
    return true;
  }

  @action.bound
  storeDeviceToken(token: string, email: string) {
    this.localStorage?.setItem(
      AppStore.remember2faDeviceTokenStorageKey(email),
      token
    );
  }

  removeDeviceToken(email: string) {
    this.localStorage?.removeItem(
      AppStore.remember2faDeviceTokenStorageKey(email)
    );
  }

  deviceToken(email: string) {
    return this.localStorage?.getItem(
      AppStore.remember2faDeviceTokenStorageKey(email)
    );
  }

  @action
  async signInWithToken(token: string) {
    this.signInWithTokenInProgress = true;
    try {
      const response = await this.api.login.show({
        token,
      });
      const session = response.data as im.SessionInfo;
      if (session.preview || session.uuid !== this.session?.uuid) {
        // use the new session
        this.useSession(session, {
          persist: true,
          authenticatedWithPassword: !session.halfLogin,
        });
      }
      return true;
    } catch (err) {
      if (err.response?.data?.errors?.[0]?.type === 'time_limit') {
        return 'expiredLink';
      }
      runInAction(() => {
        this.sessionErrorCount += 1;
      });
      maybeReportException(err);
      throw err;
    } finally {
      runInAction(() => {
        this.signInWithTokenInProgress = false;
      });
    }
  }

  // Log in using an existing SessionInfo, then initiate a load.
  //
  // @return {true} always returns true
  @action
  useSession(session: SessionInfo, options: SessionOptions) {
    const now = new Date().getTime();
    session.expiresAt = now + session.expiresIn * 1000;
    session.authenticatedWithPassword =
      session.authenticatedWithPassword || !!options.authenticatedWithPassword;
    this.session = session;
    this.sessionErrorCount = 0;
    if (options.persist) {
      this.storage?.setItem(
        AppStore.sessionStorageKey,
        JSON.stringify(session)
      );
    }
    this.load();
    return true;
  }

  @action.bound
  signOut() {
    this.session = null;
    this.storage?.removeItem(AppStore.sessionStorageKey);
    try {
      this.isMobileApp && this.mobileBridge?.Communicator.sendEvent('LOGOUT');
    } catch (e) {
      maybeReportException(e);
    }
    generatedV1iApi.login.destroy();
  }

  @action.bound
  sessionExpired() {
    this.isMobileApp &&
      this.mobileBridge?.Communicator.sendEvent(
        this.isHalfLoggedIn ? 'LOGGED_OUT' : 'SESSION_TIMED_OUT'
      );
    this.session = null;
    this.storage?.removeItem(AppStore.sessionStorageKey);
  }

  @action
  async loadFirmInfo() {
    const response = await api.firm.info();
    runInAction(() => {
      this.firmInfo = response.data;
    });
  }

  // if logged in, also configure API client auth and load ubiquitous app state.
  @action.bound
  load() {
    if (this.isLoggedIn) {
      const { accessToken } = this.session;
      apiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      acceptOnlyJsonClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      cloudFunctionsClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      loginClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      uploadsClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      docsClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    return true;
  }

  @computed
  get decodedToken(): any {
    return jwtDecode(this.session.accessToken);
  }
}

/**
 * Singleton to support direct import.
 * @see setStore
 */
export let appStore: AppStore;

/**
 * Set the singleton instance.
 */
export function setAppStore(newStore: AppStore) {
  // TODO: use __mocks__ for api & window.sessionStorage so this can become a const singleton?
  appStore = newStore;
}
