import isBlank from '@im-frontend/utils/isBlank';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import IfAuthorized from '~/components/shared/IfAuthorized';
import Tooltip from '~/components/shared/Tooltip';
import * as im from '~/models';
import { settingsStore } from '~/stores/SettingsStore';
import { Data } from './PageProvider';

import TestingPip from './TestingPip';

interface Props {
  showDistributionTab: boolean;
  showContributionsTab: boolean;
}

const opportunityFields = {
  id: 1,
  name: 1,
  allowSubmitInterest: 1,
  fundraisingCapExceeded: 1,
  prospects: {
    commitment: 1,
    interested: 1,
    entityConfirmed: 1,
    id: 1,
  },
};

const scope = Data.dependencies.register({
  opportunities: opportunityFields,
});

const Nav = ({ showDistributionTab, showContributionsTab }: Props) => {
  // use data store from the app level so that it is not re-created on every render
  const data = useContext(Data.Context).scoped(scope);
  const [numOpportunities, setNumOpportunities] = React.useState(0);
  useEffect(() => {
    if (data.opportunities.data) {
      const collectFirmCommitment = get(
        settingsStore,
        'settings.discretionary.fundraising.collectFirmCommitment',
        false
      );
      const numOpportunities = data.opportunities.data.filter(
        (o: im.Opportunity) => {
          const committedProspects = o.prospects.filter(
            prospect =>
              prospect.interested &&
              (!isBlank(
                collectFirmCommitment
                  ? prospect.commitment.amount
                  : prospect.commitment.amount || prospect.commitment.softAmount
              ) ||
                prospect.entityConfirmed)
          );
          return (
            !o.fundraisingCapExceeded &&
            o.allowSubmitInterest &&
            committedProspects.length === 0
          );
        }
      ).length;
      setNumOpportunities(numOpportunities);
    }
  }, [data.opportunities.data]);

  return (
    <nav className="nav main__nav">
      <div className="d-flex flex-column w-100">
        <div>
          <ul>
            <li>
              <NavLink
                activeClassName="is-active"
                to="/investments"
                isActive={(match, location) => {
                  const investments = /^\/investments/;

                  const offerings = new RegExp('/recent_offerings');
                  return (
                    investments.test(location.pathname) ||
                    offerings.test(location.pathname)
                  );
                }}
              >
                Dashboard
              </NavLink>
            </li>
            <li>
              {data.opportunities.data?.length > 0 && (
                <NavLink activeClassName="is-active" to="/opportunities">
                  New Offerings
                  {numOpportunities > 0 ? (
                    <span className="badge notification-icon">
                      {numOpportunities}
                    </span>
                  ) : null}
                </NavLink>
              )}
            </li>
            <IfAuthorized
              q="pii-tax#read"
              fallback={
                <>
                  <Tooltip
                    placement="bottom"
                    target="tax-forms-nav-item--disabled"
                  >
                    You do not have the required permission to view this
                  </Tooltip>
                  <li>
                    <Link
                      id="tax-forms-nav-item--disabled"
                      to="#"
                      className="disabled-nav-item"
                    >
                      Tax Forms
                    </Link>
                  </li>
                </>
              }
            >
              <li>
                <NavLink activeClassName="is-active" to="/taxes">
                  Tax Forms
                </NavLink>
              </li>
            </IfAuthorized>
            <li>
              <NavLink activeClassName="is-active" to="/documents">
                Documents
              </NavLink>
            </li>
            {(showDistributionTab || showContributionsTab) && (
              <li>
                <NavLink activeClassName="is-active" to="/transactions">
                  Transactions
                </NavLink>
              </li>
            )}
            <li>
              <NavLink activeClassName="is-active" to="/contact_us">
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="is-active"
                to="/settings"
                isActive={(match, location) => {
                  const mySettings = new RegExp('/settings');
                  const mySettingsInvestor = new RegExp('/investors');
                  return (
                    mySettings.test(location.pathname) ||
                    mySettingsInvestor.test(location.pathname)
                  );
                }}
              >
                My Profile
              </NavLink>
            </li>
          </ul>
        </div>
        <TestingPip />
      </div>
    </nav>
  );
};
export default observer(Nav);
