import React, { FC, useEffect } from 'react';
import ColorModeContext, { ColorMode } from './ColorModeContext';

/**
 * A wrapper that provides a ColorModeContext in the render tree and handles dynamically tweaking
 * a body CSS class to accomplish color-mode changes.
 */
const ColorModeProvider: FC<{
  children?: React.ReactNode;
  colorMode?: ColorMode;
  onChangeColorMode?: (newValue: ColorMode) => void;
}> = ({ children, colorMode, onChangeColorMode }) => {
  useEffect(() => {
    document
      .getElementsByTagName('html')[0]
      .setAttribute('class', colorMode === 'dark' ? 'dark-mode' : '');
  }, [colorMode]);

  return (
    <ColorModeContext.Provider
      value={{
        colorMode,
        onChangeColorMode,
      }}
    >
      {children}
    </ColorModeContext.Provider>
  );
};

export default ColorModeProvider;
