import React from 'react';
import Prompt from './Prompt';

//TODO: Copy test cases and remove the old code

const message =
  'Changes you have made have not been saved. Are you sure you want to leave?';

export default function UnsavedChangesPrompt(props: any) {
  return <Prompt message={message} {...props} />;
}
