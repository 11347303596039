import toLocalDate from '@im-frontend/utils/toLocalDate';
import toLocalTime from '@im-frontend/utils/toLocalTime';

const dateProperty = /^(date|dob|.*On|.*Date)$/;
const timeProperty = /^(timestamp|.*At|.*For)$/;

const looksLikeAWord = (value: string) => /[a-zA-Z]{3,}/.test(value);
/**
 * Transform an API response, parsing String values into Date when
 * the name of the property looks like it should be a date or time.
 */
export default function parseDates(
  data: any,
  extraFields: Record<string, 'date' | 'time'> = {}
) {
  if (Object.isFrozen(data)) {
    return data;
  } else if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      data[i] = parseDates(data[i], extraFields);
    }
  } else if (typeof data === 'object') {
    Object.keys(data).forEach(key => {
      if (
        (dateProperty.test(key) && !looksLikeAWord(data[key])) ||
        extraFields[key] === 'date'
      ) {
        data[key] = toLocalDate(data[key]);
      } else if (timeProperty.test(key) || extraFields[key] === 'time') {
        data[key] = toLocalTime(data[key]);
      } else {
        parseDates(data[key], extraFields);
      }
    });
  }

  return data;
}
