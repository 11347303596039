import { AxiosInstance } from 'axios';
import { throwIfPathTraversal } from '../../pathTraversal';

const disallowPathTraversalInterceptor = (client: AxiosInstance) => {
  client.interceptors.request.use(function (request) {
    throwIfPathTraversal(request.url);
    return request;
  });
};

export default disallowPathTraversalInterceptor;
