import RGTooltip from '@appfolio/react-gears/lib/components/Tooltip';
import React, {
  CSSProperties,
  ComponentType,
  FunctionComponent,
  useState,
} from 'react';
import uniqid from 'uniqid';

interface TagProps {
  id: string;
  style?: CSSProperties;
  className?: string;
}

export interface Props {
  tooltipText: RGTooltip['props']['children'];
  targetId?: string;
  tag?: ComponentType<TagProps> | string;
  placement?: RGTooltip['props']['placement'];
  tooltipProps?: Omit<RGTooltip['props'], 'target' | 'placement' | 'children'>;
  tagProps?: Omit<TagProps, 'id'>;
}

const Tooltip: FunctionComponent<Props> = ({
  tooltipText,
  targetId,
  tag = 'div',
  children,
  placement = 'right',
  tagProps,
  tooltipProps,
}) => {
  // ids must start with a letter but uniqids dont necessarily
  const [defaultTargetId] = useState(() => `a${uniqid()}`);
  const id = targetId || defaultTargetId;
  const Tag = tag;

  return (
    <>
      {tooltipText ? (
        <RGTooltip target={id} placement={placement} {...tooltipProps}>
          {tooltipText}
        </RGTooltip>
      ) : null}
      <Tag id={id} {...tagProps}>
        {children}
      </Tag>
    </>
  );
};

export default Tooltip;
