import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from './clients/investorPortal';

export const show = (id: number, params: Query) =>
  investorPortalClient.get(
    `/public_submit_interest_form/${id}?${stringify(params)}`
  );

export const submitInterest = (
  id: number,
  payload: {
    desiredCommitment: number;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    isAccredited: boolean;
    recaptchaToken: string;
    recaptchaAction: string;
  }
) => {
  const { recaptchaToken, recaptchaAction, ...rest } = payload;
  return investorPortalClient.post(
    `/public_submit_interest_form/${id}/actions/submit_interest`,
    rest,
    {
      headers: {
        CaptchaToken: recaptchaToken,
        CaptchaAction: recaptchaAction,
      },
    }
  );
};
