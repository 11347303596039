import CurrencyInput from '@appfolio/react-gears/lib/components/CurrencyInput';
import FormLabelGroup from '@appfolio/react-gears/lib/components/FormLabelGroup';
import React from 'react';

const CurrencyField = ({
  label,
  required,
  hint,
  rowClassName,
  initialValue,
  feedback,
  validate,
  value,
  ...inputProps
}: any) => (
  <FormLabelGroup
    stacked
    label={label}
    required={required}
    hint={hint}
    rowClassName={rowClassName}
    feedback={feedback}
  >
    <CurrencyInput value={value} {...inputProps} />
  </FormLabelGroup>
);

export default CurrencyField;
