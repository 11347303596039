import CountryInput from '@appfolio/react-gears/lib/components/CountryInput';
import FormLabelGroup from '@appfolio/react-gears/lib/components/FormLabelGroup';
import React from 'react';

const CountryField = ({
  label,
  required,
  hint,
  rowClassName,
  initialValue,
  feedback,
  value,
  onChange,
  id,
  ...rest
}: any) => {
  return (
    <FormLabelGroup
      stacked
      label={label}
      required={required}
      hint={hint}
      rowClassName={rowClassName}
      feedback={feedback}
    >
      <CountryInput value={value} id={id} {...rest} onChange={onChange} />
    </FormLabelGroup>
  );
};

export default CountryField;
