import invariant from 'invariant';
import checksum from './checksum';

export default class RoutingValidator {
  number?: string;
  error?: string;

  constructor(routingNumber: any) {
    this.routingNumber = routingNumber;
  }

  get routingNumber() {
    return this.number;
  }

  set routingNumber(routingNumber) {
    invariant(routingNumber, 'attempt to set invalid routingNumber');

    this.number = routingNumber && routingNumber.toString();
  }

  isValid() {
    if (!this.number) {
      this.error = 'Please enter a routing number';
      return false;
    }

    if (!/^\d{9}$/.test(this.number)) {
      this.error = 'Please enter a 9 digit routing number';
      return false;
    }

    const firstEight = this.number.substring(0, 8);
    const lastOne = this.number.substring(8, 9);
    const checkdigit = checksum(firstEight);

    if (checkdigit !== lastOne || this.number === '000000000') {
      this.error = 'Please enter a valid routing number';
      return false;
    }

    this.error = '';
    return true;
  }

  errorMessage() {
    if (this.error == null) {
      this.isValid();
    }
    return this.error;
  }
}
