import { HasManyFieldsRenderProps } from '@im-frontend/forms/HasManyFields';
import isBlank from '@im-frontend/utils/isBlank';
import React from 'react';
import { HasManyFields, PhoneFormField } from '~/utils/forms2';

const PhoneField = ({ name }: HasManyFieldsRenderProps) => {
  return (
    <PhoneFormField
      name={`${name}.phone`}
      placeholder="Phone Number"
      validate={phone =>
        isBlank(phone) ? 'Please enter a phone number' : undefined
      }
    />
  );
};

const HasManyPhoneFields = (props: any) => {
  return (
    <HasManyFields
      template={PhoneField}
      blank={{ phone: '' }}
      defaultValue={[]}
      {...props}
    ></HasManyFields>
  );
};

export default HasManyPhoneFields;
