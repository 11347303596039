import PdfDateInput from '@im-frontend/components/PdfDateInput';
import PdfFormLabel from '@im-frontend/components/PdfFormLabel';
import React from 'react';

type childrenProps = React.ComponentProps<typeof PdfFormLabel> &
  React.ComponentProps<typeof PdfDateInput>;

interface Props extends childrenProps {
  name: string;
}

const PdfDateField: React.FC<Props> = ({
  name,
  hint,
  feedback,
  value,
  ...inputProps
}: Props) => {
  return (
    <PdfFormLabel feedback={feedback} hint={hint}>
      <PdfDateInput value={value} {...inputProps} invalid={!!feedback} />
    </PdfFormLabel>
  );
};

export default PdfDateField;
