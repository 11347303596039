import isBlank from '@im-frontend/utils/isBlank';

function validateField({
  blankMessage,
  minLengthMessage,
  minLength = 0,
  exactLengthMessage,
  exactLength,
  regexMessage,
  regex,
  maxLength,
  maxLengthMessage,
}: any) {
  return (value: string) => {
    if (isBlank(value)) {
      return blankMessage;
    }

    if (exactLength) {
      if (value.length !== exactLength) {
        return exactLengthMessage;
      }
    } else if (value.length < minLength) {
      return minLengthMessage;
    } else if (maxLength && value.length > maxLength) {
      return maxLengthMessage;
    }

    if (regex) {
      if (!value.match(regex)) {
        return regexMessage;
      }
    }

    return undefined;
  };
}
export default validateField;
