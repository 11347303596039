import invariant from 'invariant';

import numeral from 'numeral';
import 'numeral/locales/en-au';
import 'numeral/locales/en-gb';
import 'numeral/locales/es';
import 'numeral/locales/es-es';

import * as asCurrency from './fmt/asCurrency';

let established = false;

export function establish(locale: string, currency: string) {
  invariant(
    !established,
    'Locale can only be set once during the lifetime of the app'
  );
  setupNumeral(locale);
  asCurrency.setup(locale, currency);
  established = true;
}

function setupNumeral(locale: string) {
  locale = locale.toLowerCase();
  const [lang] = locale.split('-');
  switch (locale) {
    case 'en-au':
    case 'en-gb':
    case 'es-es':
      return numeral.locale(locale);
  }

  switch (lang) {
    case 'es':
      return numeral.locale('es');
  }

  return numeral.locale();
}
