import { Badge, Icon } from '@appfolio/react-gears';

import { compact, difference, union } from 'lodash';
import React, { useState } from 'react';
import Combobox, { ComboboxProps, Option, isOption } from './Combobox';

type InheritedProps<T> = Omit<
  ComboboxProps<T>,
  'defaultValue' | 'onChange' | 'onChangeWithLabel' | 'options' | 'value'
>;

interface OwnProps<T> {
  defaultValue: T[] | Option<T>[];
  onChange?: (value?: T[]) => void;
  options: Option<T>[];
}

export type ComboboxMultiProps<T> = InheritedProps<T> & OwnProps<T>;

// For touch screens, we want a tap anywhere on the badge to delete it.
// (Also, JS-DOM doesn't have this query method so we need to check for it.)
const isTouchOnly =
  typeof window.matchMedia === 'function' &&
  window.matchMedia('(any-pointer: coarse)').matches;

/**
 * Multi-select Combobox that lets the user choose an array of items.
 * This component is uncontrolled only (i.e. it does not support `value`).
 * For simplicity, `defaultValue` takes either an array of values or an array of options.
 */
function ComboboxMulti<T>({
  defaultValue = [],
  onChange = () => {},
  options,
  ...props
}: ComboboxMultiProps<T>) {
  const [chosen, setChosen] = useState<Option<T>[]>(
    compact(
      defaultValue.map(value =>
        isOption(value) ? value : options.find(o => o.value === value)
      )
    )
  );

  return (
    <div data-testid="comboboxMulti">
      <div className="mb-1" data-testid="comboboxMulti-badges">
        {chosen.map((choice, i) => (
          <Badge
            className="align-items-center border d-inline-flex font-weight-normal ml-1 text-left"
            color="light"
            key={i}
            onTouchEnd={() =>
              setChosen(prev => {
                const next = difference(prev, [choice]);
                onChange(next.map(o => o.value));
                return next;
              })
            }
            role={isTouchOnly ? 'button' : undefined}
          >
            {choice.label}
            <Icon
              className="ml-1"
              name="times"
              onClick={e =>
                setChosen(prev => {
                  if (e.getModifierState('Shift')) {
                    // Clear everything (mostly as a test automation hook)
                    onChange([]);
                    return [];
                  } else {
                    // Clear a single item from the chosen list
                    const next = difference(prev, [choice]);
                    onChange(next.map(o => o.value));
                    return next;
                  }
                })
              }
              role={isTouchOnly ? undefined : 'button'}
              style={{ opacity: 0.5 }}
            />
          </Badge>
        ))}
      </div>
      <Combobox<T>
        clearable={false}
        onChangeWithLabel={(value, label) =>
          setChosen(prev => {
            const next = union(prev, [{ label, value }]);
            onChange(next.map(o => o.value));
            return next;
          })
        }
        options={options}
        {...props}
      />
    </div>
  );
}

export default ComboboxMulti;
