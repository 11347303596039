import React from 'react';

import PatternField, { PatternFieldProps } from './PatternField';

export type IntegerFieldProps = Exclude<PatternFieldProps, 'pattern'>;

const INTEGERS = /^$|^0$|^[1-9][0-9]*$|^-[1-9][0-9]*$/;

const IntegerField = (rest: IntegerFieldProps) => {
  return <PatternField {...rest} pattern={INTEGERS} />;
};

export function parse(value: string) {
  const parsed = parseInt(value);

  if (isNaN(parsed)) {
    return null;
  }

  return parsed;
}

export default IntegerField;
