import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from './clients/investorPortal';

export const show = (id: string | number, params: Query) =>
  investorPortalClient.get(`/capital_calls/${id}?${stringify(params)}`);

export const index = (params: Query) =>
  investorPortalClient.get(`/capital_calls?${stringify(params)}`);

export const seen = (id: string | number) =>
  investorPortalClient.post(`/capital_calls/${id}/seen`);

export const capitalSent = (id: string | number, params: Query) =>
  investorPortalClient.post(`/capital_calls/${id}/capital_sent`, params);
