import React, { isValidElement, useContext } from 'react';
import {
  AnyObject,
  Field,
  FieldProps,
  FieldRenderProps,
} from 'react-final-form';
import { FieldsGroupContext } from './FieldsGroup';
import getRenderer, { RenderableProps } from './getRenderer';
import useDeepMemoized from './useDeepMemoized';

type ValidationPolicy = 'onSubmit' | 'onTouch' | 'always';

type FieldInputProps<FieldValue> = FieldRenderProps<
  FieldValue,
  HTMLInputElement
>['input'];

interface InjectedProps {
  feedback: string;
  errors: string[];
  id: string;
}

export type WrappedComponentProps<FieldValue> = FieldInputProps<FieldValue> &
  InjectedProps;

type Override<T, S> = {
  [K in keyof T]: K extends keyof S ? S[K] : T[K];
};

export type RegisteredFieldProps<FieldValue, ComponentProps> = Override<
  FieldProps<
    FieldValue,
    FieldRenderProps<FieldValue, HTMLElement>,
    HTMLElement
  > &
    Partial<ComponentProps>,
  RenderableProps<ComponentProps>
>;

// RFF supports two properties, initialValue and defaultValue, which have similar behavior.
// You want initialValue.
type DefaultValueConsideredHarmful = {
  defaultValue?: never;
};

/**
 * ###
 * ### Currently, all fields we provided in forms package is built using withField. This page contains some docs about withField and some examples creating fields using withField
 * ### withField is a higher order component to create reusable form field like TinFormField (Tax Id Number Form Field) that could work with react-final-form
 *
 * #### withField takes three params:
 *
 * C                  - Original Component               - will be rendered as UI to be showed in form
 *
 * defaultFieldProps  - Partial of FieldProps         - provide default settings for form field like parse, initialValue. etc.
 *                                                    (FieldProps: https://final-form.org/docs/react-final-form/types/FieldProps)
 *
 * validationPolicy                  - set the policy of when to show validation errors
 *
 * #### withField returns a form field component which could connected to react-final-form through context api internally
 * #### Wrapped Component by default will receive following props:
 * FieldRenderProps' input  - object of functions/infos used to handle UI input and push those info to react-final-form
 *   (FieldRenderProps: https://final-form.org/docs/react-final-form/types/FieldRenderProps)
 *
 * errors/feedback          - show validation errors or submmtion errors based on validationPolicy
 *
 * #### Wrapped Component can receive following props (they will override FieldRenderProp, errors/feedback, defaultFieldProps if using same props name):
 *
 * componentProps           - props Original Component may need to render certain UI, like label
 *
 * any other props
 *
 */

function withField<
  ComponentProps extends Partial<WrappedComponentProps<FieldValue>> = AnyObject,
  FieldValue = ComponentProps['value']
>(
  C: React.ComponentType<ComponentProps>,
  defaultFieldProps: Partial<
    RegisteredFieldProps<FieldValue, ComponentProps>
  > = {},
  validationPolicy: ValidationPolicy = 'onTouch'
): React.ComponentType<
  RegisteredFieldProps<FieldValue, ComponentProps> &
    DefaultValueConsideredHarmful
> {
  const RFFField: React.ComponentType<
    RegisteredFieldProps<FieldValue, ComponentProps> &
      DefaultValueConsideredHarmful
  > = (
    /**
     * RegisteredFieldProps can serve props for both Field and Component:
     * 1. RFF field render props: tell how RFF work https://final-form.org/docs/react-final-form/types/FieldProps
     *   a. Props in RegisteredFieldProps will override defaultFieldProps if provided
     * 2. Component Props: Provide necessary information for component
     *   a. Component will receive RFF FieldRenderProps, which controls style components https://final-form.org/docs/react-final-form/types/FieldRenderProps
     *   b. Props in RegisteredFieldProps will override all props from RFF FieldRenderProps if provided
     */
    registeredFieldProps: RegisteredFieldProps<FieldValue, ComponentProps>
  ) => {
    const renderer = getRenderer(registeredFieldProps);
    const initialValue: FieldValue = useDeepMemoized(
      registeredFieldProps.initialValue !== undefined
        ? registeredFieldProps.initialValue
        : defaultFieldProps.initialValue !== undefined
        ? defaultFieldProps.initialValue
        : null
    );

    // The defaultValue property is a footgun
    if (registeredFieldProps.defaultValue) {
      throw new Error('Replace usage of `defaultValue` with `initialValue`!');
    }

    /*
     * Prevent console spam by destructuring RFF props out of the intersection.
     * This lets us spread component props into field renderers (which often spread
     * all the way down to the DOM) without triggering React errors about unknown
     * DOM attributes.
     */
    const { format, formatDisplayingValueOnBlur, parse, ...componentProps } =
      registeredFieldProps;

    /**
     * If FieldsGroup is used, this allow you to structure your code in format below:
     *  <FieldsGroup groupName="investor" groupOnChange={() => {doSomething();}}>
     *    <FieldsGroup name="name">
     *      <TextFormField name="firstName" />
     *      <TextFormField name="lastName" />
     *    </FieldsGroup>
     *    <PhoneFormField name="phone" />
     *  </FieldsGroup>
     *
     *  you can also use code below to get the data structure, but there is on build-in way
     *  yet to add a group onChange
     *   <TextFormField name="investor.name.firstName" />
     *   <TextFormField name="investor.name.lastName" />
     *   <PhoneFormField name="investor.phone" />
     *
     *  the values in form will be structure in { investor: { name: { firstName: 'blah', lastName: 'blah' }, phone: 123456789 } }
     */

    const { groupName, groupOnChange } = useContext(FieldsGroupContext);

    const currentName = groupName
      ? `${groupName}.${registeredFieldProps.name}`
      : registeredFieldProps.name;

    const initialValueProps: { initialValue: FieldValue } = {
      // assigning initialValue to a non-primitive causes an infinite render loop due to this useEffect
      // https://github.com/final-form/react-final-form/blob/v6.3.0/src/useField.js#L92
      // deepMemoize the initialValue to fix this bug
      ...('initialValue' in registeredFieldProps && { initialValue }),
    };

    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ parse: (v: any) => any; } & Partial<Overri... Remove this comment to see the full error message
      <Field<FieldValue>
        // https://github.com/final-form/react-final-form/issues/130#issuecomment-425482365
        parse={v => v}
        {...defaultFieldProps}
        {...registeredFieldProps}
        {...initialValueProps}
        name={currentName}
      >
        {({ input, meta }: FieldRenderProps<FieldValue, HTMLElement>) => {
          const fieldError = meta.error || meta.submitError;
          let showErrors: any;
          if (validationPolicy === 'onTouch') {
            showErrors =
              (meta.touched && meta.error) ||
              (!meta.dirtySinceLastSubmit && meta.submitError) ||
              (meta.submitFailed && fieldError);
          } else if (validationPolicy === 'onSubmit') {
            showErrors = meta.submitFailed && fieldError;
          } else {
            showErrors = true;
          }
          // Be careful using submitFailed and dirtySinceLastSubmit
          // dirtySinceLastSubmit means dirty since last successful submit
          // submitFailed could be set to true if any submit failed
          let value = input.value;
          if (formatDisplayingValueOnBlur && !meta.active) {
            // the value has not been parsed yet, let's parse it before formatting
            const baseParseFunction = parse || defaultFieldProps.parse;
            value = formatDisplayingValueOnBlur(
              baseParseFunction ? baseParseFunction(value) : value
            );
          }

          const { onChange } = input;
          const currentOnChange = (e: any) => {
            onChange(e);
            if (typeof groupOnChange === 'function') {
              groupOnChange();
            }
          };
          input.onChange = currentOnChange;

          const fieldErrorIsDisplayable =
            typeof fieldError === 'string' || isValidElement(fieldError);

          // Small hack:
          //   most components take feedback:string
          //   multi-field components take errors:string[]
          // send both and hope that the component gets what to do...
          return renderer(
            {
              ...input,
              id: `form-${currentName}`,
              feedback:
                showErrors && fieldErrorIsDisplayable ? fieldError : null,
              errors: showErrors ? fieldError : [],
              value: value,
              disabled: meta.submitting,
              ...(componentProps as ComponentProps),
            },
            C
          );
        }}
      </Field>
    );
  };
  return RFFField;
}

export default withField;
