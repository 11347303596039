// This validator should be kept in sync with the backend validator
export const validateAccountNumber = (
  accountNumber: string,
  isInternational: boolean
): string | null => {
  const maxLength = isInternational ? 170 : 17;
  if (accountNumber.length < 4 || accountNumber.length > maxLength) {
    return `Please enter 4-${maxLength} characters.`;
  }
  if (!/^[A-Za-z\d-]*$/.test(accountNumber)) {
    return 'Please enter only numbers, letters or hyphens.';
  }
  return null;
};
