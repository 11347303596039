import { action, flow as mobxFlow } from 'mobx';

interface Target extends Object {
  prototype?: object;
}

type PlainMethod = TypedPropertyDescriptor<(...args: any[]) => any>;

type FlowMethod = TypedPropertyDescriptor<
  (...args: any[]) => IterableIterator<any>
>;

/**
 * Decorator that binds an instance method so it can be called
 * as a DOM event handler, etc. Does NOT provide MobX action or
 * flow semantics in any way.
 */
function bound(
  target: Target,
  key: string,
  descriptor: PlainMethod | FlowMethod
) {
  let fn = descriptor.value;

  if (typeof fn !== 'function') {
    throw new Error('@bound decorator only applies to functions');
  }

  return {
    configurable: true,
    get() {
      if (
        this === target.prototype ||
        Object.prototype.hasOwnProperty.call(this, key) ||
        typeof fn !== 'function'
      ) {
        return fn;
      }

      const boundFn = fn.bind(this);
      Object.defineProperty(this, key, {
        configurable: true,
        get() {
          return boundFn;
        },
        set(value) {
          fn = value;
          delete this[key];
        },
      });
      return boundFn;
    },
    set(value: any) {
      fn = value;
    },
  };
}

/**
 *
 * Decorator version of the MobX flow wrapper. Facilitates actions
 * that make more than one network request.
 *
 * Use with Typescript / ES6:
 *   @flow *method() { }
 */
function flow<T>(_target: Target, _key: string, descriptor: FlowMethod) {
  if (descriptor.value) {
    descriptor.value = mobxFlow(descriptor.value as any) as any;
  }
  return descriptor;
}

/**
 * MobX flow that is bound to an instance.
 *
 * Use with Typescript / ES6:
 *   @flow.bound *method() { }
 */
flow.bound = function (target: Target, key: string, descriptor: FlowMethod) {
  return bound(target, key, flow(target, key, descriptor));
};

export { action, bound, flow };
