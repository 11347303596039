import Input from '@appfolio/react-gears/lib/components/Input';
import Tooltip from '@appfolio/react-gears/lib/components/Tooltip';
import React, { useState } from 'react';
import './pdfSignatureInput.scss';
import uniqid from 'uniqid';

interface Props {
  value?: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
  draggable?: boolean;
  invalid?: boolean;
  valid?: boolean;
  maxCount: number;
  placeholder: string;
  tooltip?: React.ReactNode;
}

const YELLOW = 'rgba(252, 239, 220, .9)';

const PdfSignatureInput: React.FC<Props> = ({
  value,
  disabled,
  onChange,
  draggable,
  invalid,
  valid,
  maxCount,
  placeholder,
  tooltip,
}: Props) => {
  const [targetId] = useState(() => `pdf-signature-input${uniqid()}`);
  return (
    <div
      style={{
        cursor: draggable ? 'move' : 'pointer',
      }}
      className="pdf-signature-input-container"
      id={targetId}
    >
      {tooltip && (
        <Tooltip target={targetId} placement="top">
          {tooltip}
        </Tooltip>
      )}
      <Input
        disabled={disabled}
        onChange={e => {
          if (e.target.value.length <= maxCount) {
            onChange && onChange(e.target.value);
          }
        }}
        onMouseDown={e => e.stopPropagation()}
        invalid={invalid}
        valid={valid}
        placeholder={placeholder}
        style={{
          backgroundColor: YELLOW,
          height: '100%',
          width: '100%',
        }}
        value={value}
        onPaste={e => e.preventDefault()}
        className="pdf-signature-input pdf-input"
      />
    </div>
  );
};

export default PdfSignatureInput;
