import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from './clients/investorPortal';

export const index = (params: Query) =>
  investorPortalClient.get(`/contact_us_form?${stringify(params)}`);

export const submit = (body: any) => {
  const { recaptchaToken, recaptchaAction, ...rest } = body;
  return investorPortalClient.post('/contact_us_form', rest, {
    headers: {
      CaptchaToken: recaptchaToken,
      CaptchaAction: recaptchaAction,
    },
  });
};

export const requestAccess = (body: any) => {
  const { recaptchaToken, recaptchaAction, ...rest } = body;
  return investorPortalClient.post(
    '/contact_us_form/actions/request_access',
    rest,
    {
      headers: {
        CaptchaToken: recaptchaToken,
        CaptchaAction: recaptchaAction,
      },
    }
  );
};

export const unsubscribe = (body: any) => {
  const { recaptchaToken, recaptchaAction, ...rest } = body;
  return investorPortalClient.post(
    '/contact_us_form/actions/unsubscribe',
    rest,
    {
      headers: {
        CaptchaToken: recaptchaToken,
        CaptchaAction: recaptchaAction,
      },
    }
  );
};
