import { Icon } from '@appfolio/react-gears';
import React from 'react';

const HelpCenter: React.FC = () => {
  return (
    <a
      className="btn btn-link"
      href="https://www.appfolio.com/help/investor-portal"
      target="_blank"
      rel="noreferrer"
      style={{ paddingRight: '0', marginTop: '5.6px' }}
      data-id="help-center-link"
    >
      Help Center <Icon name="external-link" />
    </a>
  );
};

export default HelpCenter;
