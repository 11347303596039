export interface FormValue {
  city: string;
  postal: string;
  state: string;
  address1: string;
  address2: string;
  countryCode: string;
  label: string;
}

interface Model {
  city?: string;
  id?: number;
  postalCode?: string;
  state?: string;
  street1?: string;
  street2?: string;
  countryCode?: string;
  label?: string;
}

export function formValueToModel({
  address1,
  address2,
  city,
  state,
  postal,
  countryCode,
  label,
}: FormValue) {
  // But if any data is provided, then we intend to send _some_ address.
  return {
    street1: address1,
    street2: address2,
    city,
    state: state || '',
    postalCode: postal,
    countryCode: countryCode || undefined,
    label: label,
  };
}

export function formValueToModelNullable(value: FormValue) {
  // In some contexts, sending null to server means "delete the address."
  if (!value.address1 && !value.address2 && !value.city && !value.postal) {
    return null;
  } else {
    return formValueToModel(value);
  }
}

// There is no guarantee that the model will not be null
export function modelToFormValue(model: Model | null) {
  const { street1, street2, city, state, postalCode, countryCode, label } =
    model || {};

  return {
    address1: street1 || '',
    address2: street2 || '',
    city,
    state,
    postal: postalCode,
    countryCode,
    label,
  };
}

export function modelToFormValueNullable(model: Model | null) {
  if (!model) {
    return null;
  } else {
    return modelToFormValue(model);
  }
}
