import * as v1i from '@im-frontend/client/api/v1i/schemas';
import { ObjectPathValue, ObjectPaths } from '@im-frontend/utils/types/helpers';
import hexToRgba from 'hex-to-rgba';
import { get } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import api from '~/api';
import { flow } from '~/decorators';

import * as im from '~/models';

type SettingsPaths = ObjectPaths<v1i.Settings>;

export default class SettingsStore {
  // NB: settings are purposefully _observable.ref_. We assume they are time-
  // invariant and only refresh when the app is reloaded.
  /**
   * @deprecated Do not use `settingStore.settings` directly, use the `setting` method instead
   */
  @observable.ref
  settings: im.Settings | null = null;
  // NB: settings are purposefully _observable.ref_. We assume they are time-
  // invariant and only refresh when the app is reloaded.
  @observable.ref private _settings: im.Settings | null = null;

  @observable
  uiSettings: any[] = [];

  @observable loading = true;

  @action.bound
  loadCustomizedColumns(): any {
    this.loading = true;
    return api.contactPreferences
      .showSelf({ fields: { ui: 1 } })
      .then(response => {
        runInAction(() => (this.uiSettings = response.data));
      })
      .finally(() => runInAction(() => (this.loading = false)));
  }

  @flow.bound
  *load(): any {
    this.loading = true;
    try {
      const response = yield api.settings.index();
      this.settings = response.data;
      this._settings = response.data;

      this.applyTheme(
        this.setting('discretionary.portalStyle', {
          pageBackgroundColor: '#fcfcfc',
          navBackgroundColor: '#334F74',
          navItemActiveBackgroundColor: '#069CC1',
          pageHeaderBackgroundColor: '#ffffff',
          pageItemBackgroundColor: '#ffffff',
        })
      );
    } finally {
      this.loading = false;
    }

    // Load customized columns
    this.loadCustomizedColumns();
  }

  @computed
  get displayContributions() {
    return this.setting('discretionary.layout.displayContributions', true);
  }

  setting<P extends SettingsPaths>(
    settingStr: P,
    defaultValue: ObjectPathValue<v1i.Settings, P>
  ): ObjectPathValue<v1i.Settings, P> {
    return get(this._settings, settingStr, defaultValue);
  }

  applyTheme(theme: v1i.Settings['discretionary']['portalStyle']) {
    let sheet = document.getElementById('portal-style');
    if (!sheet) {
      sheet = document.createElement('style');
      sheet.setAttribute('id', 'portal-style');
      document.body.appendChild(sheet);
    }
    // Trello card for triage: https://trello.com/c/i0Ui3A9p/143-sast-semgrep-finding-insecure-document-method-on-lp-settingsstorets
    // nosemgrep javascript.browser.security.insecure-document-method.insecure-document-method
    sheet.innerHTML = `
      .main__content {
        background-color: #fcfcfc !important}
      .main__nav {
        background-color: ${theme.navBackgroundColor} !important}
      .main__nav a:hover{
        background-color: ${hexToRgba(
          theme.navItemActiveBackgroundColor,
          0.628
        )} !important}
      .main__nav .is-active{
        background-color: ${theme.navItemActiveBackgroundColor} !important}
      .header {
        background-color: #ffffff !important}
      .sticky-header {
        background-color: #ffffff !important}
    `;
  }
}

/**
 * Singleton to support direct import.
 */
export let settingsStore = new SettingsStore();

/**
 * Set the singleton instance.
 */
export function setSettingsStore(newStore: SettingsStore) {
  // TODO: use __mocks__ for api & window.sessionStorage so this can become a const singleton?
  settingsStore = newStore;
}
