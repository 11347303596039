import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from './clients/investorPortal';

export const show = (id: number, params: Query) =>
  investorPortalClient.get(`/public_opportunities/${id}?${stringify(params)}`);

export const submitInterest = (
  id: number,
  payload: {
    desiredCommitment: number;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
  }
) =>
  investorPortalClient.post(
    `/public_opportunities/${id}/actions/submit_interest`,
    payload
  );
