import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import classNames from 'classnames';
import React from 'react';
import HighPrecisionNumberInput, {
  HighPrecisionNumberInputProps,
} from '../inputs/HighPrecisionNumberInput';

export type HighPrecisionNumberFieldProps = FormLabelGroupProps &
  HighPrecisionNumberInputProps;

export default function HighPrecisionNumberField({
  label,
  required,
  hint,
  rowClassName,
  validate,
  initialValue,
  feedback,
  tableField,
  ...props
}: HighPrecisionNumberFieldProps) {
  return (
    <FormLabelGroup
      stacked
      label={label}
      required={required}
      hint={hint}
      inputId={props.id}
      rowClassName={classNames(rowClassName, { 'no-gutters mb-0': tableField })}
      feedback={feedback}
    >
      <HighPrecisionNumberInput {...props} />
    </FormLabelGroup>
  );
}
