import investorPortalClient from './clients/investorPortal';

export function generateError(
  errorMessage: string,
  errorType: string,
  fields: string,
  extras: any
) {
  return investorPortalClient.post(
    `/debug/actions/generate_error${extras.badUrl ? 'urldoesntexist' : ''}`,
    {
      message: errorMessage,
      errorType: errorType,
      fields: fields,
      ...extras,
    }
  );
}
