import PdfFormLabel from '@im-frontend/components/PdfFormLabel';
import PdfSignatureInput from '@im-frontend/components/PdfSignatureInput';
import React from 'react';

type childrenProps = React.ComponentProps<typeof PdfFormLabel> &
  React.ComponentProps<typeof PdfSignatureInput>;

interface Props extends childrenProps {
  name: string;
}

const PdfSignatureField: React.FC<Props> = ({
  name,
  hint,
  feedback,
  value,
  maxCount,
  ...inputProps
}: Props) => {
  return (
    <PdfFormLabel feedback={feedback} hint={hint}>
      <PdfSignatureInput
        invalid={!!feedback}
        maxCount={maxCount}
        value={value}
        {...inputProps}
      />
    </PdfFormLabel>
  );
};

export default PdfSignatureField;
