import mfaClient from './clients/mfaClient';

export const register = (
  phoneNumber: string,
  countryCode: string,
  mfaMethod: 'sms' | 'call'
) =>
  mfaClient.post('/mfa/actions/register', {
    phoneNumber,
    countryCode,
    mfaMethod,
  });

export const registerTotp = () => mfaClient.post('/mfa/actions/register_totp');

export const requestToken = (mfaMethod: 'sms' | 'call') =>
  mfaClient.post('/mfa/actions/request_token', {
    mfaMethod,
  });

export const registerVerify = (token: string, mfaMethod: 'sms' | 'call') =>
  mfaClient.post('/mfa/actions/register_verify', {
    token,
    mfaMethod,
  });
