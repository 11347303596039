import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from '~/api/clients/investorPortal';
import { ContactPreference } from '~/models';

type UpdateSelfCustomTable = {
  table: {
    columns: {
      name: string;
      visible: boolean;
    }[];
    name: string;
  };
};

export const showSelf = (params: Query) => {
  return investorPortalClient.get(`/contact_preferences?${stringify(params)}`);
};

export const updateSelf = (
  body: Pick<ContactPreference, 'colorMode'> | UpdateSelfCustomTable
) => {
  return investorPortalClient.patch(`/contact_preferences`, body);
};
