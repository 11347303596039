//@ts-strict
declare global {
  interface Window {
    FS?: Fullstory;
  }
  interface Fullstory {
    identify(uid: string, arg2: any): void;

    getCurrentSessionURL?: (arg: boolean) => string;
    restart(): void;
  }
}

export interface IInitialConfig {
  release?: string;
}

export interface IUserConfig {
  userId: string;
  displayName: string;
  email: string;
  [key: string]: string | boolean | number;
}

export const init = () => {
  if (!window.FS) {
    return true;
  }

  // it has been stopped when snippet was added to index.html
  window.FS.restart();
};

export const syncUserAttribute = (config: IUserConfig): any => {
  if (!window.FS) {
    return true;
  }

  const { userId, ...rest } = config;

  window.FS.identify(config.userId, rest);
};

export const provideSessionUrl = (callback: (a?: string) => void) => {
  if (!window.FS) {
    callback();
    return;
  }

  callback(window.FS.getCurrentSessionURL?.(true));
};
