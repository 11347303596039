/// <reference types="node" />

import * as Sentry from '@sentry/react';
import { isPlainObject } from 'lodash';
import * as cypress from './cypress';

/* eslint-disable no-console */

declare global {
  interface Window {
    // set by index.html
    isOutOfDateBrowser: boolean;
  }
}
interface NotReportedErrorOptions {
  shouldToastMsg: boolean;
}

// Instances of NotReportedError are not reporetd to Sentry
export class NotReportedError extends Error {
  shouldToastMsg: boolean;

  constructor(
    message: string,
    options: NotReportedErrorOptions = { shouldToastMsg: true }
  ) {
    super(message);
    this.name = this.constructor.name;
    this.shouldToastMsg = options.shouldToastMsg;
  }
}

// regex for matching if we think a server header is an aws object aka alb
const awsGatewayServer = /^aws.*/;

/**
 * Notify the error-catching service, console, and/or test runner
 * that an error has occurred. The user is not notified.
 * @see ToastStore for user notification of errors
 */
export function mustReportException(error: any, errorInfo?: any) {
  if (error instanceof NotReportedError) {
    return;
  }

  const headers = error.response?.headers;
  const data = error.response?.data;
  if (data) {
    errorInfo.extra = errorInfo.extra || {};
    errorInfo.extra.responseData = data;
  }

  let eventId;
  if (typeof Sentry !== 'undefined') {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        const extra = errorInfo && errorInfo.extra;
        if (isPlainObject(extra)) {
          Object.entries(extra).forEach(([key, value]) => {
            scope.setExtra(key, JSON.stringify(value));
          });
        }
        const tags = errorInfo && errorInfo.tags;
        if (isPlainObject(tags)) {
          scope.setTags(tags);
        }
        if (headers?.['x-transaction-id']) {
          scope.setTag('transaction_id', headers['x-transaction-id']);
        }
        const preferredUiVersion = headers?.['preferred-ui-version'];
        scope.setTag('preferredUiVersion', preferredUiVersion);

        if (awsGatewayServer.test(headers?.['server'] ?? '')) {
          scope.setTag('possiblyBlockedByWaf', true);
        }

        const data_json = JSON.stringify(data);
        if (data_json?.includes('CAPTCHA FAILED')) {
          scope.setTag('captchaFailed', true);
        }

        if (error?.response?.status === 400 && data?.errors) {
          error.message += `: ${error.config.method} ${error.config.url} - ${data.name}`;
          scope.setFingerprint([
            'url:' + error.config.url,
            'verb:' + error.config.method,
            'error_type:' + data.name,
            'error_messages:' + data.errors.join(', '),
          ]);
        }

        // looks like gp-frontend-sha1 or lp-frontend-sha1
        const currentRelease = scope.getSession()?.release;

        // If we are in the context of a network request, we have the preferred version. Otherwise
        // we might be somewhere like a button press and we do not have a preferred-ui-version.
        const isOutOfDateFrontendCode =
          currentRelease && preferredUiVersion
            ? currentRelease.indexOf(preferredUiVersion) < 0
            : 'unknown';
        scope.setTag('isOutOfDateFrontendCode', isOutOfDateFrontendCode);

        scope.setTag(
          'isOutOfDateBrowser',
          window.isOutOfDateBrowser === undefined
            ? 'unknown'
            : window.isOutOfDateBrowser
        );
        if (error instanceof Error) {
          eventId = Sentry.captureException(error);
        }
      });
    }
    // NB using console.log to evade Sentry
    else {
      console.log({ error, errorInfo });
    }
  } else if (process.env.NODE_ENV !== 'test') {
    console.error({ error, errorInfo });
  }

  const { id, message } = data || {};
  if (id && !eventId) {
    eventId = id;
  }

  // eslint-disable-next-line rulesdir/report-errors-in-catch
  try {
    if (eventId) {
      cypress.log({
        name: 'SENTRY',
        message: `${eventId} ${message.slice(0, 20)}`,
        consoleProps: () => data,
      });
    }
  } catch (metaError) {
    console.error({ error, errorInfo });
  }
  if (error.indicatesBuggyTest) {
    throw error;
  }
}

// Some 4xx are harmless and can be ignored.
// "Network Disconnected" is 0.
const ignorableErrorCodes = new Set([0, 401, 403, 404, 409, 500, 502, 503]);

// Some network errors are the Internet's fault, not ours.
const ignorableMessages = new Set([
  'Network Error',
  'Request aborted',
  // https://github.com/axios/axios/issues/2103
  'timeout of 0ms exceeded',
  // https://trello.com/c/zjYE3Ptb/2322-alerts-non-actionable-noise-patrol-cant-access-dead-object
  "can't access dead object",
  // https://trello.com/c/bVs9Fq3K/2340-perma-mute-at-the-sentry-sdk-level-noisy-non-actionable-alerts
  'The operation is insecure.',
  'Failed to fetch',
]);

const unprocessablePayload =
  'application/vnd.appfolio.im.unprocessable_payload_error+json';

/**
 * Determine whether it's safe to ignore an error on the basis
 * of its HTTP status code or the fact that the server has already
 * reported it.
 */
export function canIgnore(message: any, request?: any, response?: any) {
  const status = request?.status || response?.status;
  const data = response?.data;
  const headers = response?.headers;
  const responseURL = request?.responseURL;

  // Various network errors we don't care about.
  const isAwsGateway = awsGatewayServer.test(headers?.['server'] ?? '');
  if (
    (headers?.['content-type'] === unprocessablePayload &&
      data?.errorCode !== null) || // ignore error codes, but report others
    ignorableMessages.has(message) ||
    (ignorableErrorCodes.has(status) && !isAwsGateway) ||
    data?.id || // the backend has already reported this // Temporary enabling the reporting of 400 validation errors, until we're sure
    // the new required/null changes of the new Praxis works well with our code...
    // Uncomment the "|| e.type === 'validation'" to re-disable it as it was
    data?.errors?.every(
      (e: any) => e.userVisible // || e.type === 'validation'
    ) || // dont report Im::ValidationError or errors intended for users
    (responseURL?.includes('localhost') &&
      responseURL?.endsWith('/im/analytics_events')) // newrelic isnt setup in dev
  ) {
    return true;
  }

  // Issues with navigator.sendBeacon due to analytics products fighting with each others' monkey patches.
  if (message === 'Illegal invocation') {
    return true;
  }

  return false;
}

// Examine an error and notify the catcher if it seems
// unexpected (i.e. is not a network error). Display
// an error toast if appropriate.
export function maybeReportException(
  error: any,
  errorInfo: any = null
): null | void {
  if (!error) {
    return null;
  }

  const { message, request, response } = error;

  // NB: removed some lines that don't apply to gp-frontend
  // careful when copy/pasting this to investor-frontend

  // don't report useless stuff
  if (canIgnore(message, request, response)) {
    return;
  }

  mustReportException(error, errorInfo || {});
}

// Given an Axios Error where the HTTP response body is of type
// application/vnd.appfolio.aim.error+json, return the "type"
// field of the n'th error.
//
// Return undefined in any other case (missing response, different
// media type, error not found at index n).
//
// @return {String,undefined}
export function explainResponse(response: any, n = 0) {
  if (!response || !response.data) {
    return undefined;
  }
  const { errors } = response.data;
  if (!errors) {
    return undefined;
  }
  const theError = Array.isArray(errors) && errors[n];
  if (!theError) {
    return undefined;
  }
  return theError.type;
}

export function extractBadRequestMessage(response: any) {
  if (!response || !response.data) {
    return undefined;
  }
  const { errors } = response.data;
  if (!errors) {
    return undefined;
  }
  const theError = Array.isArray(errors) && errors[0];
  if (!theError) {
    return undefined;
  }
  return theError.message;
}

export function errorMatchesErrorCode(
  e: Error & { response: { data: { errorCode: string } } },
  errorCode: string
) {
  return (
    e &&
    e.response &&
    e.response.data &&
    e.response.data.errorCode === errorCode
  );
}
