import { Col, Row } from '@appfolio/react-gears';
import FormLabelGroup from '@appfolio/react-gears/lib/components/FormLabelGroup';
import isBlank from '@im-frontend/utils/isBlank';
import { CountryCodeValues } from '@im-frontend/utils/phoneCountryCode';
import React from 'react';
import { PhoneFormField, SelectFormField } from '../';

const countryCodeOptions = CountryCodeValues.map(value => ({
  value,
  label: `+${value}`,
}));

export default ({
  name,
  label,
  autoFocus,
  required,
}: {
  name: string;
  label: string;
  autoFocus?: boolean;
  required?: boolean;
}) => (
  <FormLabelGroup stacked label={label} required>
    <Row>
      <Col className="pr-0 countryCode">
        <SelectFormField
          name={`${name}.countryCode`}
          label=""
          initialValue="1"
          options={countryCodeOptions}
          clearable={false}
          validate={countryCode =>
            required && isBlank(countryCode)
              ? 'Please enter a country code'
              : null
          }
        />
      </Col>
      <Col className="phoneNumber">
        <PhoneFormField
          name={`${name}.phone`}
          label=""
          validate={phoneNumber =>
            required && isBlank(phoneNumber)
              ? 'Please enter a phone number'
              : null
          }
          autoFocus={autoFocus}
          type="tel"
        />
      </Col>
    </Row>
  </FormLabelGroup>
);
