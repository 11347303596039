import React, { useCallback, useState } from 'react';
/**
 * A hook for modal open/close state. Returns a pair consisting of:
 * - present state of the modal,
 * -callback to toggle the state.
 */
export function useModal(): [boolean, () => void, (isOpen: boolean) => void] {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen(o => !o);
  }, []);

  return [isOpen, toggleOpen, setIsOpen];
}
