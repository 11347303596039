import React from 'react';
import { PrivilegeQuery, queryAll } from '~/services/authorization';

interface CapabilityProps {
  /**
   * Component to display if user has all requisite privileges.
   */
  children: React.ReactNode;

  /**
   * Component to display if user lacks any privilege in the query.
   */
  fallback?: React.ReactNode;

  /**
   * Privilege(s) to query.
   */
  q: PrivilegeQuery;
}

/**
 * Conditionally renders its children if the privilege query is successful for
 * the current user. An optional fallback component is rendered instead if the
 * user is not authorized.
 *
 * @example hide a button
 *   <IfAuthorized q="worlds#delete"><button>delete the world</button></IfAuthorized>
 *
 * @example make a link non-navigable
 *   <IfAuthorized q="contacts#read" fallback={contact.name}>
 *    <Link to={contact.href}>{contact.name}</Link>
 *   </IfAuthorized>
 *
 * @example guard an entire page
 *   <IfAuthorized q="settings#update" fallback={<NotAuthorized/>}>
 *    this is the best page ever!
 *   </IfAuthorized>
 *
 * @see src/components/views/NotAuthorized for the official "you are not allowed to do this" presenter
 */
export default function IfAuthorized({
  children,
  fallback = null,
  q,
}: CapabilityProps) {
  return <>{queryAll(q) ? children : fallback}</>;
}

// Not yet needed in lp, as there aren't any whole pages which are conditionally visible.
// export function withAuthorization<ComponentProps = any>({
//   Fallback = NotAuthorized,
//   q,
//   C,
//   except,
// }: {
//   Fallback?: React.ComponentType;
//   q: PrivilegeQuery;
//   C: React.ComponentType<ComponentProps>;
//   exception?: boolean;
//   except?: (props: ComponentProps) => boolean;
// }) {
//   const NewC = (props: ComponentProps) => {
//     if ((except && except(props)) || queryAll(q)) {
//       return <C {...props} />;
//     }
//     return <Fallback />;
//   };

//   return NewC;
// }
