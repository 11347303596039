import { createContext } from 'react';
import { createDataStore } from './DataStore';
import { createDependencyStores } from './DependenciesStore';
import { ScopedData, ScopedModel, ScopedRequest } from './interfaces';

export type { ScopedModel, ScopedData };

export function createDataDependencies<
  T extends { [key: string]: ScopedRequest }
>(requests: T) {
  const dependencies = createDependencyStores(requests);

  const createStore = () => createDataStore(dependencies);

  type StoreTypeT = ReturnType<typeof createStore>;
  const StoreType: StoreTypeT = {} as any;

  const Context = createContext<StoreTypeT>(null);

  return {
    dependencies,
    Context,
    createStore,
    StoreType,
  };
}
