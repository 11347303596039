import { Endpoints } from '@im-frontend/client/api/v1i/endpoints';
import { AxiosResponse } from 'axios';
import * as accreditationVerifications from './accreditationVerifications';
import * as aggregations from './aggregations';
import * as assets from './assets';
import * as bankAccounts from './bankAccounts';
import * as capitalCall from './capitalCall';
import client, { acceptOnlyJsonClient } from './clients/investorPortal';
import * as cloudFunctions from './cloudFunctions';
import * as contactPreferences from './contactPreferences';
import * as contactUsForm from './contactUsForm';
import * as contacts from './contacts';
import * as contributions from './contributions';
import * as debug from './debug';
import * as distributionItems from './distributionItems';
import * as distributionWithholdings from './distributionWithholdings';
import * as distributions from './distributions';
import * as documentShares from './documentShares';
import * as documents from './documents';
import * as feedback from './feedback';
import * as firm from './firm';
import * as funds from './funds';
import * as generalPartners from './generalPartners';
import * as investorContacts from './investorContacts';
import * as investors from './investors';
import * as ip from './ip';
import * as ipContribution from './ipContribution';
import * as login from './login';
import * as mfa from './mfa';
import * as opportunities from './opportunities';
import * as opportunityAssets from './opportunityAssets';
import * as pmProfiles from './pmProfiles';
import * as positions from './positions';
import * as projectUpdates from './projectUpdates';
import * as prospectContributions from './prospectContributions';
import * as prospects from './prospects';
import * as publicOpportunities from './publicOpportunities';
import * as publicSubmitInterestForm from './publicSubmitInterestForm';
import * as questionnaireSignatures from './questionnaireSignatures';
import * as recentOfferings from './recentOfferings';
import * as settings from './settings';
import * as signatureRecipients from './signatureRecipients';
import * as signatureSigners from './signatureSigners';
import * as statementBatches from './statementBatches';
import * as uploads from './uploads';

const api = {
  accreditationVerifications,
  aggregations,
  assets,
  bankAccounts,
  capitalCall,
  contacts,
  contactUsForm,
  contributions,
  cloudFunctions,
  debug,
  distributions,
  distributionItems,
  distributionWithholdings,
  documents,
  documentShares,
  feedback,
  firm,
  funds,
  investors,
  investorContacts,
  login,
  mfa,
  opportunities,
  opportunityAssets,
  positions,
  projectUpdates,
  settings,
  signatureSigners,
  statementBatches,
  questionnaireSignatures,
  uploads,
  prospects,
  signatureRecipients,
  recentOfferings,
  prospectContributions,
  ip,
  publicOpportunities,
  publicSubmitInterestForm,
  generalPartners,
  contactPreferences,
  ipContribution,
  pmProfiles,
};

export { client };
export const generatedV1iApi = new Endpoints<AxiosResponse>(client);
export const acceptsOnlyJsonV1iApi = new Endpoints<AxiosResponse>(
  acceptOnlyJsonClient
);
export type Api = typeof api;

export default api;
