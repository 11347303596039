import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from './clients/investorPortal';

export function show(id: number, params: Query) {
  return investorPortalClient.get(
    `/prospect_contributions/${id}?${stringify(params)}`
  );
}

export function index(params: Query) {
  return investorPortalClient.get(
    `/prospect_contributions?${stringify(params)}`
  );
}
