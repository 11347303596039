import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from './clients/investorPortal';

export const index = (params: Query) =>
  investorPortalClient.get(`/questionnaires?${stringify(params)}`);

export const show = (id: number, params: Query) =>
  investorPortalClient.get(`/questionnaires/${id}?${stringify(params)}`);

export const submit = (id: number, body: any) =>
  investorPortalClient.post(`/questionnaires/${id}/actions/submit`, body);
