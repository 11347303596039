import CheckboxInput from '@appfolio/react-gears/lib/components/CheckboxInput';
import FormLabelGroup from '@appfolio/react-gears/lib/components/FormLabelGroup';
import React from 'react';

const CheckboxFormField = ({
  label,
  name,
  required,
  hint,
  rowClassName,
  feedback,
  value,
  ...inputProps
}: any) => (
  <FormLabelGroup
    stacked
    required={required}
    hint={hint}
    rowClassName={rowClassName}
    feedback={feedback}
  >
    <CheckboxInput value={!!value} {...inputProps} checkboxLabel={label} />
  </FormLabelGroup>
);

export default CheckboxFormField;
