import { HasManyFieldsRenderProps } from '@im-frontend/forms/HasManyFields';
import { AddressFormField } from '@im-frontend/forms/fields';
import React from 'react';
import { HasManyFields } from '~/utils/forms2';

const AddressField = ({ name }: HasManyFieldsRenderProps) => {
  return <AddressFormField name={`${name}`} />;
};

const HasManyAddressFields = (props: any) => {
  return (
    <HasManyFields
      template={AddressField}
      blank={{ address1: '' }}
      defaultValue={[]}
      {...props}
    ></HasManyFields>
  );
};

export default HasManyAddressFields;
