import { action, observable } from 'mobx';
import { SessionInfo } from '~/models';
import { uiPrefix } from '~/namespaceInfo';
import { appStore } from '~/stores/AppStore';
import { settingsStore } from '~/stores/SettingsStore';

interface RequestConfig {
  method: string;
}

interface Response {
  request:
    | {
        method: string;
      }
    | {
        ea: {
          method: string;
        };
      };
}

const receiveMethods = new Set([
  'GET',
  'HEAD',
  'OPTIONS',
  'get',
  'head',
  'options',
]);

const sendMethods = new Set([
  'DELETE',
  'PATCH',
  'POST',
  'PUT',
  'delete',
  'patch',
  'post',
  'put',
]);

interface VisitOpts {
  session?: SessionInfo;
}

export default class NetStore {
  @observable receiveCount = 0;
  @observable sendCount = 0;

  history: { replace: (url: string) => void } | null;

  @action.bound
  onRequest(request: RequestConfig) {
    if (!request) {
      return;
    }
    if (receiveMethods.has(request.method)) {
      this.receiveCount += 1;
    } else if (sendMethods.has(request.method)) {
      this.sendCount += 1;
    }
  }

  @action.bound
  onResponse(response: Response): void {
    if (!response || !response.request) {
      return null;
    }
    const { request } = response;
    const method = 'ea' in request ? request.ea.method : request.method;
    if (receiveMethods.has(method)) {
      this.receiveCount -= 1;
    } else if (sendMethods.has(method)) {
      this.sendCount -= 1;
    }
  }

  @action.bound
  onNavigate() {
    this.receiveCount = 0;
    this.sendCount = 0;
  }

  visit(path: string, { session }: VisitOpts = {}) {
    const isInvestor = path.match(/^(\/[^/]*)\/investor(?!s)/);

    if (!isInvestor) {
      throw new Error(
        `Expected LP route; received what seems to be a GP route`
      );
    }

    path = path.slice(uiPrefix.length); // remove namespace + investor prefix
    if (session) {
      appStore.useSession(session, { persist: true });
    }

    const r1 = settingsStore.load(); // settings may have changed!
    appStore.load();
    return r1.then(() => {
      if (this.history) {
        this.history.replace(path);
      } else {
        throw new Error(`Must set .history before calling visit`);
      }
    });
  }
}

/**
 * Singleton instance of this class.
 */
export const netStore = new NetStore();
interface FastNavigation {
  visit(url: string, options?: { session?: object }): Promise<true>;
}

// This should match the interface defined in common/@im-test/cypress-visit/interfaces.ts
// This enables component tests that stub the appStore to pass lint in lp
declare global {
  interface Window {
    uiTestingService?: FastNavigation;
  }
}

window.uiTestingService = netStore;
