import {
  Button,
  Icon,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from '@appfolio/react-gears';
import React from 'react';

interface Props {
  className?: string;
  href?: string;
  label?: string;
  value: string;
}

export default function CopyToClipboard({
  className,
  href,
  label,
  value,
}: Props) {
  const ref = React.createRef<HTMLInputElement>();
  const copy = () => {
    if (!ref.current) {
      return;
    }
    ref.current.select();
    document.execCommand('copy');
  };
  const visit = () => {
    window.open(href, '_blank');
  };

  let prepend;
  if (label) {
    prepend = (
      <InputGroupAddon addonType="prepend">
        <InputGroupText>{label}</InputGroupText>
      </InputGroupAddon>
    );
  }

  const append1 = (
    <InputGroupAddon addonType="append">
      <Button color="primary" onClick={copy}>
        <Icon name="copy" />
      </Button>
    </InputGroupAddon>
  );

  let append2;
  if (href) {
    append2 = (
      <InputGroupAddon addonType="append">
        <Button onClick={visit}>
          <Icon name="external-link" />
        </Button>
      </InputGroupAddon>
    );
  }

  return (
    <InputGroup className={className}>
      {prepend}
      <input className="flex-fill" readOnly ref={ref} value={value} />
      {append1}
      {append2}
    </InputGroup>
  );
}
