import * as fmt from '@im-frontend/utils/fmt';
import { decamelize, decamelizeKeys } from 'humps';
import { isPlainObject } from 'lodash';
import * as qs from 'qs';

/**
 * Recursive data structure used to specify the fields to include in API
 * responses.
 */
export interface Fields {
  [k: string]: number | Fields;
}

/**
 * All known query-string parameters accepted by any endpoint of the API.
 */
export interface Query {
  fields?: Fields | string;
  filter?: string;
  [k: string]: any;
}

export function stringifyFields(f: Fields): string {
  return Object.entries(decamelizeKeys(f))
    .map(([key, value]) => {
      if (isPlainObject(value)) {
        return `${key}{${stringifyFields(value as Fields)}}`;
      }
      if (value === 1) {
        return key;
      }
      throw new Error(
        `api/qs: values of fields-selector objects must be numeric 1 (received '${value}', a ${typeof value})`
      );
    })
    .join(',');
}

function queryValue(value: any): string {
  if (value instanceof Date) {
    return fmt.asDate(value, 'YYYY-MM-DD');
  }
  return value;
}

/**
 * Transform a Query into its URL-friendly string representation.
 */
export function stringify(query: Query | null): string {
  const qh: any = {};
  const typ = typeof query;
  if (typ === 'object') {
    if (query) {
      Object.entries(query).forEach(([k, v]) => {
        switch (k) {
          case 'fields':
            if (typeof v === 'object') {
              qh['fields'] = stringifyFields(v);
            }
            // passthru in case someone has already stringified their fields
            else {
              qh['fields'] = v;
            }
            break;
          case 'filter':
            // TODO validate filter or provide a better ES representation
            qh['filter'] = v;
            break;
          default:
            qh[decamelize(k)] = queryValue(v);
            break;
        }
      });
    }
    return qs.stringify(qh);
  } else if (typ === 'undefined') {
    return '';
  }
  throw new Error(`api/qs: cannot stringify ${typ}`);
}
