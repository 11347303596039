import disallowPathTraversal from '@im-frontend/utils/clients/interceptors/disallowPathTraversal';
import enforceMaxRequestSize from '@im-frontend/utils/clients/interceptors/enforceMaxRequestSize';
import maybeReportExceptionInterceptor from '@im-frontend/utils/clients/interceptors/maybeReportException';
import reloadDuringRelease from '@im-frontend/utils/clients/interceptors/reloadDuringRelease';
import retryCertainHttpCodes from '@im-frontend/utils/clients/interceptors/retryCertainHttpCodes';
import parseDates from '@im-frontend/utils/clients/transforms/parseDates';
import { maybeReportException } from '@im-frontend/utils/errors';
import addTransactionIdHeader from '@im-frontend/utils/transforms/addTransactionIdHeader';
import axios, { AxiosRequestConfig } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { omitBy } from 'lodash';
import { apiPrefix, namespacePrefix, vanityDomain } from '~/namespaceInfo';

const runningOnVanityUrl = () => {
  const host = window?.location?.host;
  if (!host) {
    return false;
  }

  return vanityDomain === window.location.host;
};

type ImCustomAxiosConfigFields =
  | 'baseURL'
  | 'headers'
  | 'transformRequest'
  | 'transformResponse';

type ImCustomAxiosParams = Pick<AxiosRequestConfig, ImCustomAxiosConfigFields>;

type InterceptorParams = {
  disallowPathTraversal: boolean;
  maybeReportException: boolean;
  reloadDuringRelease: boolean;
  retryCertainHttpCodes: boolean;
  enforceMaxRequestSize: boolean;
};

export function createClient({
  baseURL = namespacePrefix +
    `${runningOnVanityUrl() ? '/investor' : ''}` +
    apiPrefix,
  headers = { 'X-Api-Version': '1i' },
  transformRequest = [].concat(
    decamelizeKeys,
    addTransactionIdHeader,
    axios.defaults.transformRequest
  ),
  transformResponse = [].concat(
    axios.defaults.transformResponse,
    camelizeKeys,
    parseDates
  ),
  interceptors = {},
}: ImCustomAxiosParams & { interceptors?: Partial<InterceptorParams> } = {}) {
  const params: ImCustomAxiosParams = omitBy(
    {
      baseURL,
      headers,
      transformRequest,
      transformResponse,
    },
    v => v === null
  );

  const client = axios.create(params);

  const interceptorFlags: InterceptorParams = {
    disallowPathTraversal: true,
    maybeReportException: true,
    reloadDuringRelease: true,
    retryCertainHttpCodes: true,
    enforceMaxRequestSize: true,
    ...interceptors,
  };

  interceptorFlags.disallowPathTraversal && disallowPathTraversal(client);
  interceptorFlags.maybeReportException &&
    maybeReportExceptionInterceptor(client, maybeReportException);
  interceptorFlags.reloadDuringRelease && reloadDuringRelease(client);
  interceptorFlags.retryCertainHttpCodes && retryCertainHttpCodes(client);
  interceptorFlags.enforceMaxRequestSize && enforceMaxRequestSize(client);

  return client;
}
