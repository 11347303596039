import { maybeReportException } from '@im-frontend/utils/errors';
import React, { useEffect, useState } from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  onError: (error: Error, errorInfo: any) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

function RevealErrorPage() {
  const [content, setContent] = useState<React.ReactElement | null>(null);

  useEffect(() => {
    if ('showRootError' in window) {
      (window as any).showRootError();
    } else {
      setContent(
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'sans-serif',
            justifyContent: 'center',
            position: 'absolute',
            left: '0',
            top: '0',
            right: '0',
            bottom: '0',
            textAlign: 'center',
            margin: '1rem',
          }}
        >
          <img
            id="root-error-logo"
            alt="logo"
            style={{ width: '300px', marginBottom: '1rem' }}
            src="https://af-im-ui.s3.us-east-2.amazonaws.com/static-assets/logos/appfolio-investment-manager-logo-2023.svg"
          />
          <p style={{ fontSize: '1.5rem' }}>Sorry, something went wrong.</p>
        </div>
      );
    }
  }, []);

  return content;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState(ErrorBoundary.getDerivedStateFromError(error));
    try {
      this.props.onError(error, errorInfo);
    } catch (err) {
      // Prevent "double tap" crashes in case onError is buggy.
      // eslint-disable-next-line no-console
      console.error(err);
      maybeReportException(err);
    }
  }

  render() {
    if (this.state.hasError) {
      return <RevealErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
