import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Prompt as ReactRouterPrompt } from 'react-router-dom';

const showHash = '--show';

function evaluateIfFunction(val: any) {
  if (typeof val === 'function') {
    return val();
  }

  return val;
}

interface Props {
  showInCypress: boolean;
}

interface State {
  id: number;
}

@observer
export default class Prompt extends Component<Props, State> {
  static instances = observable.map();

  static id = 1;

  static getSortedIds() {
    return Array.from(Prompt.instances.keys())
      .map(key => +key)
      .sort();
  }

  static activeInstanceId() {
    return this.getSortedIds().find(id => this.instances.get(id).props.when);
  }

  static shouldDisplayPrompt() {
    // work around https://github.com/cypress-io/cypress/issues/2118
    // @ts-ignore FIXME TS2532: Object is possibly 'undefined'.
    return this.activeInstanceId() >= 0 && !window.Cypress;
  }

  static shouldDisplayPromptInCypress() {
    return !!this.getSortedIds().find(id => {
      const instance = this.instances.get(id);
      return instance.props.when && instance.props.showInCypress;
    });
  }

  static message() {
    const activeInstance = this.instances.get(this.activeInstanceId());

    if (!activeInstance) {
      return '';
    }

    return evaluateIfFunction(activeInstance.props.message);
  }

  static setOnBeforeUnload() {
    if (this.instances.size === 0) {
      window.onbeforeunload = null;
      return;
    }

    window.onbeforeunload = (e: any) => {
      if (!this.shouldDisplayPrompt()) {
        return null;
      }

      const message = this.message();
      e.returnValue = message;
      return message;
    };
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      id: Prompt.id,
    };

    Prompt.id += 1;
  }

  componentDidMount() {
    runInAction(() => {
      Prompt.instances.set(this.state.id, this);
    });
    Prompt.setOnBeforeUnload();
  }

  componentWillUnmount() {
    runInAction(() => {
      Prompt.instances.delete(this.state.id);
    });
    Prompt.setOnBeforeUnload();
  }

  render() {
    if (Prompt.getSortedIds()[0] === this.state.id) {
      return (
        <ReactRouterPrompt
          {...this.props}
          when={
            Prompt.shouldDisplayPrompt() ||
            Prompt.shouldDisplayPromptInCypress()
          }
          message={location => {
            if (location) {
              const { hash } = location;
              if (hash.includes(showHash)) {
                return true;
              }
              if (location.pathname.startsWith('/login')) {
                return true;
              }
            }
            // if the message is empty just return true to skip the prompt
            // fixes a bug where navigating to a redirect shows the real
            // prompt followed by an empty prompt
            return Prompt.message() || true;
          }}
        />
      );
    }

    return null;
  }
}
