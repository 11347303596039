import { Query, stringify } from '@im-frontend/praxis-api/qs';
import investorPortalClient from './clients/investorPortal';

export const showSelf = (params: Query) =>
  investorPortalClient.get(`/contacts/actions/self?${stringify(params)}`);

export const index = (params: Query) =>
  investorPortalClient.get(`/contacts?${stringify(params)}`);

export const update = (id: any, body: any) =>
  investorPortalClient.patch(`contacts/${id}`, body);
