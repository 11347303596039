import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import MonthInput from '@appfolio/react-gears/lib/components/MonthInput';
import React from 'react';

export type MonthFieldProps = FormLabelGroupProps & MonthInput['props'];

const MonthField = ({
  label,
  required,
  feedback,
  ...inputProps
}: MonthFieldProps) => (
  <FormLabelGroup stacked label={label} required={required} feedback={feedback}>
    <MonthInput dateFormat="MMM YYYY" {...inputProps} />
  </FormLabelGroup>
);

export default MonthField;
