import { Query, stringify } from '@im-frontend/praxis-api/qs';
import * as im from '~/models';
import investorPortalClient from './clients/investorPortal';

export function sendPayment({
  prospectId,
  amount,
  contactUpdatePayload,
  investorUpdatePayload,
  nextTransactionNumberSeed,
  bankAccount,
}: {
  prospectId?: string;
  amount: number;
  contactUpdatePayload: {
    id: number;
    firstName: string;
    lastName: string;
    middleName: string;
    dob: Date;
    countryOfResidence: im.Prospect['contact']['countryOfResidence'];
  };
  investorUpdatePayload: {
    id: number;
    entityType: im.Prospect['investor']['entityType'];
    bank: Pick<im.Investor['bank'], 'payeeName'>;
    taxAddress: Omit<
      im.Prospect['investor']['taxAddress'],
      'id' | 'lat' | 'lng' | 'pobox' | 'label' | 'street2'
    >;
    countryOfIncorporation: im.Prospect['investor']['countryOfIncorporation'];
  };
  bankAccount?: {
    id: number;
  };
  nextTransactionNumberSeed: string;
}) {
  return investorPortalClient.post('/ip_contributions/actions/send_payment', {
    prospect: {
      id: prospectId,
    },
    amount,
    contact: contactUpdatePayload,
    investor: investorUpdatePayload,
    nextTransactionNumberSeed,
    bankAccount,
  });
}

export function index(params: Query) {
  return investorPortalClient.get(`/ip_contributions?${stringify(params)}`);
}
