import { merge } from 'lodash';
import US_STATES from '../../utils/usStates';
import validateField from './validateField';
export interface AddressError {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postal?: string;
  countryCode?: string;
}

export interface AddressField {
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  countryCode?: string;
  label?: string;
}

// Validate that an address meets the API's validation criteria.
export const addressFormFieldsValidators = (
  fields: AddressField = {}
): AddressError => {
  const error: AddressError = {};

  if (!fields) {
    return {
      address1: 'Please enter at least 3 characters',
      city: 'Please enter at least 3 characters',
      state: 'Please select a State',
      postal: 'Please enter a Zip Code',
    };
  }
  error.address1 = validateField({
    blankMessage: 'Please enter a valid address',
    minLengthMessage: 'Please enter at least 3 characters',
    minLength: 3,
    maxLengthMessage: 'Please enter no more than 62 characters',
    maxLength: 62,
  })(fields.street1);

  error.address2 = validateField({
    maxLengthMessage: 'Please enter no more than 62 characters',
    maxLength: 62,
  })(fields.street2);

  if (fields.countryCode === 'US' || !fields.countryCode) {
    // we check null country code here because the API will default to US if it is not provided
    error.state = validateField({
      blankMessage: 'Please select a State',
    })(fields.state);

    error.postal = validateField({
      blankMessage: 'Please enter a Zip Code',
      regexMessage: 'Please enter a valid Zip Code',
      regex: /^\d{5}(?:-\d{4})?$/,
    })(fields.postalCode);
  }

  // we always validate city, the main reason is APM side requires city
  error.city = validateField({
    blankMessage: 'Please enter a City',
    minLengthMessage: 'Please enter at least 3 characters',
    minLength: 3,
    maxLengthMessage: 'Please enter no more than 42 characters',
    maxLength: 42,
  })(fields.city);

  return error;
};

export const nullableAddressFormFieldsValidators = (
  fields: AddressField = {}
) => {
  if (!fields) {
    return {};
  }
  const { street1, street2, city, postalCode, label, countryCode } = fields;
  if (!street1 && !street2 && !city && !postalCode && !label && !countryCode) {
    return {};
  }
  return addressFormFieldsValidators(fields);
};

export const paymentUsAddressFormFieldsValidators = (
  fields: AddressField = {}
) => {
  const error: AddressError = {};
  if (!fields) {
    return {};
  }
  const usAddressError = 'Must be a US address';
  if (fields?.countryCode !== 'US') {
    error.countryCode = usAddressError;
  }
  if (!US_STATES.includes(fields?.state)) {
    error.state = usAddressError;
  }
  return merge(error, addressFormFieldsValidators(fields));
};
