// one cent out of a billon dollars
const EPSILON = 0.00000000001;

let establishedCurrency = 'USD';
let establishedLocale = 'en-US';

let symbolOptions: undefined | Partial<Intl.NumberFormatOptions>;

/**
 * Change formatting options for currency display.
 */
export function setup(locale: string, currency: string) {
  establishedLocale = locale;
  establishedCurrency = currency;

  // Compatibility testing for currency display in older/crippled browsers.
  // @see https://appfolio.sentry.io/issues/4190756224/events/28386ab299e24ed388b90cba2c8851c2/?project=1265710&referrer=previous-event
  // eslint-disable-next-line rulesdir/report-errors-in-catch
  try {
    symbolOptions = {
      currency: establishedCurrency,
      currencyDisplay: 'narrowSymbol',
      style: 'currency',
    };
    new Intl.NumberFormat(establishedLocale, symbolOptions).format(12345.67);
  } catch (error) {
    symbolOptions = undefined;
    // eslint-disable-next-line no-console
    console.warn(
      'asCurrency.setup: fallback to manual formatting; Intl.NumberFormat is broken: ',
      error
    );
  }
}

/**
 * Represent a number as a localized currency string.
 */
export function asCurrency(
  value: any,
  options: {
    withCents?: boolean;
    withSymbol?: boolean;
    withFractionalCents?: boolean;
    withOptionalCents?: boolean;
    minimumFractionDigits?: number;
  } = {
    withCents: false,
    withSymbol: false,
    withFractionalCents: false,
    withOptionalCents: false,
    minimumFractionDigits: 2,
  }
): string {
  if (!value) {
    value = 0;
  }

  // Preserve idempotency (why on earth do we support this?)
  if (typeof value === 'string') {
    value = value.replace('$', '');
    value = value.replace(/,/g, '');
  }
  value = Number(value);

  // Truncate floating-point rounding errors
  if (Math.abs(value) < EPSILON) {
    value = 0;
  }

  const showFractions =
    (options.withCents || options.withFractionalCents) &&
    !(options.withOptionalCents && Number.isInteger(value));

  const fopt: Partial<Intl.NumberFormatOptions> =
    options.withSymbol && symbolOptions ? { ...symbolOptions } : {};

  if (showFractions) {
    fopt.minimumFractionDigits = options.minimumFractionDigits || 2;
    if (options.withFractionalCents) {
      fopt.maximumFractionDigits = 6;
    } else {
      fopt.maximumFractionDigits = 2;
    }
  } else {
    fopt.minimumFractionDigits = 0;
    fopt.maximumFractionDigits = 0;
  }

  let result = Intl.NumberFormat(establishedLocale, fopt).format(value);
  if (options.withSymbol && !symbolOptions) {
    result = result[0] === '-' ? '-$' + result.slice(1) : '$' + result;
  }
  return result;
}
