import React, { useContext } from 'react';

export const colorModes = ['dark', 'light'] as const;

export type ColorMode = (typeof colorModes)[number];

const ColorModeContext = React.createContext<{
  colorMode: ColorMode;
  onChangeColorMode: (colorMode: ColorMode) => void;
}>({
  colorMode: 'light',
  onChangeColorMode: () => {},
});

export default ColorModeContext;

export function useColorMode() {
  return useContext(ColorModeContext);
}
