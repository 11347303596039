import GearsButton from '@appfolio/react-gears/lib/components/Button';
import { maybeReportException } from '@im-frontend/utils/errors';
import React, { useEffect, useState } from 'react';
import { toastStore } from '~/stores/ToastStore';

export type BaseButtonInterface = GearsButton['props'] & {
  instance?: any;
  ref?: any;
};

/**
 * Button will be disabled during the process of running async onClick function
 */
export const Button = ({
  /** Callback when the button is clicked. Handles exceptions. */
  onClick,
  /** Disables the button */
  disabled,
  ...otherProps
}: BaseButtonInterface) => {
  const [inProcess, setInProcess] = useState(false);

  // track if component is still mounted to prevent state change after unmount
  let isMounted = true;
  //@ts-expect-error
  useEffect(() => () => (isMounted = false), []);

  return (
    <GearsButton
      onClick={async e => {
        if (onClick) {
          try {
            setInProcess(true);
            const maybePromise = onClick(e);
            if (
              (maybePromise as any) &&
              typeof (maybePromise as any).then === 'function'
            ) {
              await maybePromise;
            }
          } catch (e) {
            toastStore.toastNow(e);
            maybeReportException(e);
          } finally {
            if (isMounted) {
              setInProcess(false);
            }
          }
        }
      }}
      disabled={disabled || inProcess}
      {...otherProps}
    />
  );
};
