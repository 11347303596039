// do this before anything else https://webpack.js.org/guides/public-path/#on-the-fly
import './webpackPublicPath';

// do this before rendering anything; sooner is better
import './i18n';

import * as MobileBridge from '@appfolio/mobile-bridge';
import ErrorBoundary from '@im-frontend/components/ErrorBoundary';
import { mustReportException } from '@im-frontend/utils/errors';
import { imLocalStorage, imSessionStorage } from '@im-frontend/utils/storages';
import { compact } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import api from '~/api';
import apiClient from '~/api/clients/investorPortal';
import App from '~/components/App';
import { uiPrefix } from '~/namespaceInfo';
import loadSettings from '~/reactions/loadSettings';
import mfaPrompt from '~/reactions/mfaPrompt';
import syncNetStats from '~/reactions/syncNetStats';
import syncNewRelicMetadata from '~/reactions/syncNewRelicMetadata';
import syncPendoVisitorId from '~/reactions/syncPendoVisitorId';
import syncScreenRecordingUserAttributes from '~/reactions/syncScreenRecordingUserAttributes';
import AppStore, { setAppStore } from '~/stores/AppStore';
import { netStore } from '~/stores/NetStore';
import { settingsStore } from '~/stores/SettingsStore';
import GlobalsProvider from './GlobalsProvider';

import './initalizers/DataStore';

// load chrome 71 target polyfills https://github.com/babel/babel/blob/master/packages/babel-preset-env/test/fixtures/corejs3/entry-chrome-71/output.mjs
import 'core-js/modules/es.array.unscopables.flat';
import 'core-js/modules/es.array.unscopables.flat-map';
import 'core-js/modules/es.math.hypot';
import 'core-js/modules/es.object.from-entries';
import 'core-js/modules/web.immediate';

declare global {
  interface Window {
    pendo: any;
    newrelic: any;
  }
}

const onError = (error: any, errorInfo: any) =>
  mustReportException(error, {
    extra: errorInfo,
    tags: { fullPageCrash: true },
  });

const { environment } = (window.document.querySelector('#root') as any).dataset;
if (environment === 'development' || environment === 'qa') {
  import(`@im-frontend/utils/Cool.js`);
}

if (!imSessionStorage || !imLocalStorage) {
  ReactDOM.render(
    <></>, // backend is showing a proper error
    document.getElementById('root')
  );
} else {
  // TODO: use __mocks__ for api & sessionStorage so AppStore can become a const singleton
  const appStore = new AppStore(
    api,
    imSessionStorage,
    imLocalStorage,
    MobileBridge
  );
  setAppStore(appStore);

  loadSettings(appStore, settingsStore);
  syncNetStats(apiClient, netStore);
  mfaPrompt(apiClient, appStore.mfa); // must be AFTER syncNetStats or it breaks ATs
  syncPendoVisitorId(window.pendo, appStore);
  if (window.newrelic) {
    syncNewRelicMetadata(window.newrelic, appStore);
  }
  syncScreenRecordingUserAttributes(appStore);

  compact([
    document.getElementById('spinner'),
    document.getElementById('im-spinner'),
  ]).forEach(s => s.remove());

  // Allow 300ms for spinner's fade transition to complete after it's removed (above).
  setTimeout(() => {
    ReactDOM.render(
      <ErrorBoundary onError={onError}>
        <GlobalsProvider>
          <BrowserRouter basename={uiPrefix}>
            <App />
          </BrowserRouter>
        </GlobalsProvider>
      </ErrorBoundary>,
      document.getElementById('root')
    );
  }, 305);
}
