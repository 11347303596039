import CheckboxInput from '@appfolio/react-gears/lib/components/CheckboxBooleanInput';
import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import classNames from 'classnames';
import React from 'react';

export type CheckboxFieldProps = FormLabelGroupProps &
  Partial<CheckboxInput['props']> & {
    label: CheckboxInput['props']['checkboxLabel'];
  };

const CheckboxField = ({
  label,
  name,
  required,
  hint,
  rowClassName,
  feedback,
  validate,
  initialValue,
  value,
  tableField,
  inline,
  ...inputProps
}: CheckboxFieldProps) => (
  <FormLabelGroup
    stacked
    required={required}
    hint={hint}
    rowClassName={classNames(rowClassName, { 'no-gutters mb-0': tableField })}
    feedback={feedback}
  >
    <CheckboxInput
      value={!!value}
      name={name}
      {...inputProps}
      checkboxLabel={label}
    />
  </FormLabelGroup>
);

export default CheckboxField;
