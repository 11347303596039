import { TimeInput } from '@appfolio/react-gears';
import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import classNames from 'classnames';
import React from 'react';

export type TimeFieldProps = FormLabelGroupProps &
  TimeInput['props'] & { [key: string]: any };

const TimeField = ({
  label,
  required,
  hint,
  rowClassName,
  validate,
  initialValue,
  feedback,
  value,
  id,
  tableField,
  direction,
  ...inputProps
}: TimeFieldProps) => (
  <FormLabelGroup
    stacked
    label={label}
    required={required}
    hint={hint}
    rowClassName={classNames(rowClassName, { 'no-gutters mb-0': tableField })}
    feedback={feedback}
    inputId={id}
  >
    <TimeInput
      value={value}
      id={id}
      direction={direction || 'down'}
      autoComplete="off"
      {...inputProps}
    />
  </FormLabelGroup>
);

export default TimeField;
