import { isPresent } from '@im-frontend/utils/cypress';
import { AxiosInstance, AxiosResponse } from 'axios';

// force frontend reload during release, so it will load the maintenace page
export default function reloadDuringRelease(client: AxiosInstance) {
  client.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: any) => {
      const { response } = error;
      if (!isPresent && response?.status === 418) {
        window.location.reload();
      }
      throw error;
    }
  );
}
