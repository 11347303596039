import RGAddressInput from '@appfolio/react-gears/lib/components/AddressInput';
import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import React from 'react';

export type AddressFormFieldProps = FormLabelGroupProps &
  RGAddressInput['props'] & {
    [key: string]: any;
  };

export const nullAddress = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  postal: '',
};

export const noEmptyArray = (errors: any) =>
  Array.isArray(errors) && !errors.length ? undefined : errors;

const AddressFormField = ({
  label,
  required,
  hint,
  rowClassName,
  validate,
  initialValue,
  feedback,
  value,
  errors,
  id,
  ...inputProps
}: AddressFormFieldProps) => {
  return (
    <FormLabelGroup
      stacked
      label={label}
      required={required}
      hint={hint}
      inputId={id}
      rowClassName={rowClassName}
    >
      <RGAddressInput
        id={id}
        value={value || nullAddress}
        {...inputProps}
        error={noEmptyArray(errors)}
      />
    </FormLabelGroup>
  );
};

export default AddressFormField;
