const allDigitsRegex = /^\d+$/;

export function stripSpecialCharacters(number: string) {
  return number.replace(/\D+/g, '');
}

export function isTelLinkable(number: string | undefined) {
  if (!number || typeof number !== 'string') {
    return false;
  }

  const numberWithoutSpecialCharacters = stripSpecialCharacters(number);

  return (
    numberWithoutSpecialCharacters.length === 10 &&
    allDigitsRegex.test(numberWithoutSpecialCharacters)
  );
}

export function formatPhone(
  number: string | null | undefined,
  countryCode: string | null = null
): string | null | undefined {
  if (!number) {
    return number;
  }

  const country = countryCode && stripSpecialCharacters(countryCode);
  if (stripSpecialCharacters(number).length === 10 && number[0] !== '+') {
    const areaCode = stripSpecialCharacters(number).substring(0, 3);
    const middleThree = stripSpecialCharacters(number).substring(3, 6);
    const lastFour = stripSpecialCharacters(number).substring(6);
    number = `(${areaCode}) ${middleThree}-${lastFour}`;
  }
  return `${country ? `+${country} ` : ''}${number}`;
}
