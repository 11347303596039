import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import classNames from 'classnames';
import React from 'react';
import PercentInput, { PercentInputProps } from '../inputs/PercentInput';

export type PercentFieldProps = FormLabelGroupProps & PercentInputProps;

export default function PercentField({
  label,
  required,
  hint,
  rowClassName,
  validate,
  initialValue,
  feedback,
  tableField,
  ...props
}: PercentFieldProps) {
  return (
    <FormLabelGroup
      stacked
      label={label}
      required={required}
      hint={hint}
      inputId={props.id}
      rowClassName={classNames(rowClassName, { 'no-gutters mb-0': tableField })}
      feedback={feedback}
    >
      <PercentInput {...props} />
    </FormLabelGroup>
  );
}
