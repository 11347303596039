import { Query, stringify } from '@im-frontend/praxis-api/qs';
import * as im from '~/models';
import investorPortalClient from './clients/investorPortal';

export const index = (params: Query) =>
  investorPortalClient.get(`/opportunities?${stringify(params)}`);

export const show = (id: number, params: Query) =>
  investorPortalClient.get(`/opportunities/${id}?${stringify(params)}`);

export function submitInterest(
  id: any,
  {
    desiredCommitment,
    investingEntityId,
    investingEntityDetails,
    comments,
    newInvestor,
    holdingTitle,
    phoneNumber,
    requireAccreditationForm,
    updatingProspectId,
    contact,
  }: {
    desiredCommitment: number;
    investingEntityId?: number;
    investingEntityDetails?: any;
    comments?: string;
    newInvestor?: any;
    holdingTitle?: string;
    phoneNumber?: number;
    requireAccreditationForm: boolean;
    updatingProspectId?: number;
    contact?: Partial<im.Contact>;
  }
) {
  const params = { desiredCommitment, requireAccreditationForm } as any;
  comments && (params.lpOpportunityComments = comments);
  investingEntityId &&
    (params.investor = {
      id: investingEntityId,
      updatePayload: investingEntityDetails,
    });
  newInvestor && (params.newInvestor = newInvestor);
  holdingTitle && (params.holdingTitle = holdingTitle);
  phoneNumber && (params.phoneNumber = phoneNumber);
  updatingProspectId && (params.updatingProspectId = updatingProspectId);
  contact && (params.contact = contact);
  return investorPortalClient.post(
    `/opportunities/${id}/actions/submit_interest`,
    params
  );
}

export function updateOfferingLastViewedAt(id: number) {
  return investorPortalClient.patch(
    `/opportunities/${id}/actions/update_offering_last_viewed_at`
  );
}
