import { useColorMode } from '@im-frontend/components/ColorModeContext';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FC, ReactNode } from 'react';
import AppStore from '~/stores/AppStore';
import { inject } from '~/utils/inject';

const FirmLogo: FC<{
  fallback?: ReactNode;
  appStore: AppStore;
  logoProps?: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  showInDarkMode?: boolean;
  className?: string;
}> = ({ fallback, appStore, logoProps, showInDarkMode = false, className }) => {
  const { name, logoUrl } = appStore.firmInfo;

  const { colorMode } = useColorMode();

  const darkModeEnabled = colorMode === 'dark';

  let result: React.ReactNode = fallback;

  if (!darkModeEnabled || (darkModeEnabled && showInDarkMode)) {
    if (logoUrl) {
      result = (
        <img
          src={logoUrl}
          alt={name}
          style={{ maxWidth: '16rem' }}
          {...logoProps}
        />
      );
    }
  }

  if (result == null) {
    return null;
  }

  return <div className={className}>{result}</div>;
};

export default inject('appStore')(observer(FirmLogo));
