import DateInput from '@appfolio/react-gears/lib/components/DateInput';
import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import dateParse from '@im-frontend/utils/dateParse';
import classNames from 'classnames';
import React from 'react';

export type DateFieldProps = FormLabelGroupProps &
  DateInput['props'] & {
    [key: string]: any;
    dateVisible: (currentDate: Date) => boolean;
  };

const DateField = ({
  label,
  required,
  hint,
  rowClassName,
  validate,
  initialValue,
  feedback,
  value,
  id,
  tableField,
  direction,
  ...inputProps
}: DateFieldProps) => (
  <FormLabelGroup
    stacked
    label={label}
    required={required}
    hint={hint}
    rowClassName={classNames(rowClassName, { 'no-gutters mb-0': tableField })}
    feedback={feedback}
    inputId={id}
  >
    <DateInput
      value={value}
      dateFormat="MM/DD/YYYY"
      id={id}
      direction={direction || 'down'}
      parse={(dateOrString: string | Date) => {
        if (dateOrString instanceof Date) {
          return dateOrString;
        }
        // eslint-disable-next-line rulesdir/report-errors-in-catch
        try {
          // https://trello.com/c/JRIx0FGo
          // sometimes this function will throw error......
          // https://github.com/appfolio/im/tree/master/frontend/%40im-frontend/utils/dateParse.js#L79
          const date = dateParse(dateOrString);
          if (date < dateParse('1900-01-01')) {
            return dateOrString;
          }
          return date;
        } catch (e) {
          return '';
        }
      }}
      autoComplete="off"
      {...inputProps}
    />
  </FormLabelGroup>
);

export default DateField;
