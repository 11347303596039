import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import SearchCombobox, {
  SearchComboboxProps,
} from '@im-frontend/components/SearchCombobox';
import classNames from 'classnames';
import React from 'react';

export type SearchFieldProps = FormLabelGroupProps & SearchComboboxProps;

function SearchField({
  label,
  required,
  hint,
  rowClassName,
  // validate,     -- where did this come from in SelectField??
  // initialValue, -- TODO grok how default/initial value works (& add to Combobox et al, if necessary)
  feedback,
  onChange,
  // tableField, -- where did this come from in SelectField??
  id,
  value,
  ...rest
}: SearchFieldProps) {
  // Hack: deal with poorly-understood issue where someone is coercing undefined to ''
  // (Maybe only an issue in forms/filtering?)
  if ((value as any) === '') {
    value = undefined;
  }

  return (
    <FormLabelGroup
      stacked
      label={label}
      required={required}
      hint={hint}
      rowClassName={classNames(rowClassName, {
        'no-gutters mb-0': false /*tableField*/,
      })}
      inputId={id}
      feedback={feedback}
    >
      <SearchCombobox id={id} onChange={onChange} value={value} {...rest} />
    </FormLabelGroup>
  );
}

export default SearchField;
