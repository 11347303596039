import { AxiosInstance } from 'axios';

const maybeReportExceptionInterceptor = (
  client: AxiosInstance,
  maybeReportException: (e: Error) => void // TODO share maybeReportException
) => {
  // Add a response interceptor
  client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      maybeReportException(error);
      return Promise.reject(error);
    }
  );
};
export default maybeReportExceptionInterceptor;
