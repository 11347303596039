import FieldsGroup from '@im-frontend/forms/FieldsGroup';
import Form from '@im-frontend/forms/Form';
import HasManyFieldsField from '@im-frontend/forms/HasManyFields';
import {
  formValueToModel,
  modelToFormValue,
} from '@im-frontend/forms/utils/addressConversions';
import {
  addressFormFieldsValidators,
  dateValidator,
  phoneWithCountryCodeValidator,
} from '@im-frontend/forms/validators';
import withField from '@im-frontend/forms/withField';
import numeral from 'numeral';
import CheckboxField from './styles/CheckboxFormField';
import CurrencyField from './styles/CurrencyField';
import DateField from './styles/DateField';
import EmailField, { validateEmail } from './styles/EmailField';
import HasManyAddressFields from './styles/HasManyAddressFields';
import { HasManySelectableContactFields } from './styles/HasManyContactFields';
import HasManyEmailFields from './styles/HasManyEmailFields';
import HasManyPhoneFields from './styles/HasManyPhoneFields';
import PdfCheckboxField from './styles/PdfCheckboxField';
import PdfDateField from './styles/PdfDateField';
import PdfSignatureField from './styles/PdfSignatureField';
import PdfTextboxField from './styles/PdfTextboxField';
import PhoneField from './styles/PhoneFormField';
import PhoneWithCountryCodeField from './styles/PhoneWithCountryFormField';
import RadioButtonsField from './styles/RadioButtonsFormField';
import SelectField from './styles/SelectFormField';
import SimpleCheckboxField from './styles/SimpleCheckboxFormField';
import TextField from './styles/TextFormField';

import { tinValidator } from './validators';

/**
 * Components created by withField are reusable components that
 * register to react final form automatically
 *
 *
 * A registeredField (component created by withField) must take
 * the following props:
 *   name   - string     - identifier to form
 *
 * Example Usage
 *    <Form onSubmit={values => {fakeAPICall(values)}}>
 *     {() => {
 *       return (
 *         <TextFormField name="example1"/>
 *       )
 *     }}
 *    </Form>
 *
 * Example Usage with FieldsGroup
 *    <Form onSubmit={values => {fakeAPICall(values)}}>
 *     {() => {
 *       return (
 *         <FieldsGroup name="group1">
 *            <TextFormField name="example1"/>
 *         </FiedlsGroup>
 *       )
 *     }}
 *    </Form>
 *
 * values in form will be structure to {group1: {example1: 'example1 value'}}
 *
 *
 */
const DateFormField = withField(DateField, {
  validate: dateValidator,
});
const TextFormField = withField(TextField);
const PdfCheckboxFormField = withField(PdfCheckboxField);
const PdfTextboxFormField = withField(PdfTextboxField);
const PdfSignatureFormField = withField(PdfSignatureField);
const PdfDateFormField = withField(PdfDateField);
const PhoneFormField = withField(PhoneField);
const SelectFormField = withField(SelectField, {
  parse: option => {
    if (option && option.value) {
      return option.value;
    }
    return option;
  },
  format: option => {
    if (option && !option.value) {
      return { value: option };
    }
    return option;
  },
});
const SimpleCheckboxFormField = withField(SimpleCheckboxField, {
  type: 'checkbox',
});
const CheckboxFormField = withField(CheckboxField);
const HasManyPhoneFormFields = withField(HasManyPhoneFields);
// Deprecated use Currency field from the @im-frontend package
// const CurrencyFormField = withField(CurrencyField, {
//   parse: value => numeral(value).value(),
// });
const EmailFormField = withField(
  EmailField,
  {
    validate: validateEmail,
  },
  'onSubmit'
);
const HasManyEmailFormFields = withField(HasManyEmailFields);
const HasManyAddressFormFields = withField(HasManyAddressFields, {
  format: value =>
    value.map((address: any) => {
      return modelToFormValue(address);
    }),
  parse: value =>
    value.map((formAddress: any) => {
      return formValueToModel(formAddress);
    }),
  validate: values =>
    (values || []).map((addr: any) => addressFormFieldsValidators(addr)),
});

const HasManySelectableContactFormFields = withField(
  HasManySelectableContactFields,
  {},
  'onSubmit'
);
const TinFormField = withField(TextField, {
  validate: tinValidator,
});
const RadioButtonsFormField = withField(RadioButtonsField);

const PhoneWithCountryCodeFormField = withField(PhoneWithCountryCodeField, {
  validate: phoneWithCountryCodeValidator,
});

const NullField = () => null as never;
const NullFormField = withField(NullField);

const HasManyFields = HasManyFieldsField;

export {
  TinFormField,
  CheckboxFormField,
  TextFormField,
  PhoneFormField,
  SelectFormField,
  SimpleCheckboxFormField,
  HasManyPhoneFormFields,
  HasManyEmailFormFields,
  HasManyAddressFormFields,
  DateFormField,
  // Deprecated use Currency field from the @im-frontend package
  // CurrencyFormField,
  RadioButtonsFormField,
  PhoneWithCountryCodeFormField,
  PdfCheckboxFormField,
  PdfTextboxFormField,
  PdfSignatureFormField,
  PdfDateFormField,
  NullFormField,
  HasManySelectableContactFormFields,
  HasManyFields,
  EmailFormField,
};

export { FieldsGroup };

export default Form;
