import loginClient from './clients/investorPortalLogin';

export const verifyActivated = ({ username }: any) =>
  loginClient.post('/login/actions/verify_activated', {
    username,
  });

export const verifyInvitation = ({ username, resetToken }: any) =>
  loginClient.post('/login/actions/verify_invitation', {
    username,
    resetToken,
  });

export const choosePassword = ({ username, resetToken, password }: any) =>
  loginClient.post('/login/actions/choose_password', {
    username,
    resetToken,
    password,
  });

export const forgotPassword = (payload: { email: string }) =>
  loginClient.post('/login/actions/forgot_password', payload);

export const password = ({
  email,
  password,
  remember2faDeviceToken,
}: {
  email: string;
  password: string;
  remember2faDeviceToken: string;
}) => {
  return loginClient.post('/login/password', {
    email,
    password,
    remember_2fa_device_token: remember2faDeviceToken,
    send_mfa_code: true,
  });
};

export const resetPassword = ({ username, resetToken, password }: any) =>
  loginClient.post('/login/actions/reset_password', {
    username,
    resetToken,
    password,
  });

export function show({ token }: any) {
  return loginClient.post('/login/show', { token });
}

export const mfaVerify = (
  email: string,
  password: string,
  token: string,
  mfaMethod: 'sms' | 'call' | 'totp',
  isNewContact = false,
  rememberMe = false
) =>
  loginClient.post('/login/actions/login_with_mfa', {
    email,
    password,
    token,
    mfaMethod,
    isNewContact,
    rememberMe,
  });
