import React from 'react';

import PatternField, { PatternFieldProps } from './PatternField';

export type DecimalFieldProps = Exclude<PatternFieldProps, 'pattern'>;

export const decimalRegex = /^(\d*\.?(\d+)?|)$/;

const DecimalField = (rest: DecimalFieldProps) => {
  return <PatternField {...rest} pattern={decimalRegex} autoComplete="off" />;
};

export default DecimalField;
