import { Privilege } from '~/models/Privileges';
import { appStore } from '~/stores/AppStore';

/**
 * A list of privileges to query.
 */
export type PrivilegeQuery = Privilege | Privilege[] | readonly Privilege[];

/**
 * Return true iff the logged-in user has a given privilege
 * @param p The privilege to check
 */
export function hasPrivilege(p: Privilege): boolean {
  return !!appStore?.session?.privileges?.includes(p);
}

/**
 * Return true iff the logged-in user has all of the privileges being queried.
 * As a convenience, takes undefined query meaning "no privileges required;
 * always return true".
 */
export function queryAll(q: PrivilegeQuery): boolean {
  if (!q) {
    return true;
  }

  if (Array.isArray(q)) {
    return q.every(hasPrivilege);
  }
  return hasPrivilege(q as Privilege); // isArray returning false doesn't teach TS that `q` isn't a readonly array
}

/**
 * Return true if the logged-in user has any of the privileges being queried.
 * As a convenience, takes undefined query meaning "no privileges required;
 * always return true".
 */
export function queryAny(q: PrivilegeQuery): boolean {
  if (!q) {
    return true;
  }
  if (Array.isArray(q)) {
    return q.some(hasPrivilege);
  }
  return hasPrivilege(q as Privilege); // isArray returning false doesn't teach TS that `q` isn't a readonly array
}
