import isBlank from '@im-frontend/utils/isBlank';
import _HasManyFields from '../HasManyFields';
import _OrderableHasManyFields from '../OrderableHasManyFields';
import {
  formValueToModel,
  formValueToModelNullable,
  modelToFormValue,
  modelToFormValueNullable,
} from '../utils/addressConversions';
import {
  addressFormFieldsValidators,
  nullableAddressFormFieldsValidators,
  paymentUsAddressFormFieldsValidators,
} from '../validators';
import withField from '../withField';
import AddressField from './AddressField';
import BankAccountSelectField from './BankAccountSelectField';
import _CheckboxField from './CheckboxField';
import _CheckboxGroupField from './CheckboxGroupField';
import _ComboboxField from './ComboboxField';
import _ComboboxMultiField from './ComboboxMultiField';
import _CountryField from './CountryField';
import _CurrencyField from './CurrencyField';
import _DateField from './DateField';
import _DecimalField from './DecimalField';
import FfcField, { FfcCheckboxField } from './FfcField';
import _HighPrecisionCurrencyField from './HighPrecisionCurrencyField';
import _HighPrecisionNumberField from './HighPrecisionNumberField';
import _HighPrecisionPercentField from './HighPrecisionPercentField';
import _IntegerField, { parse as parseIntegerField } from './IntegerField';
import _MonthField from './MonthField';
import _MultiplierField from './MultiplierField';
import _NullField from './NullField';
import _NumberField from './NumberField';
import _PatternField from './PatternField';
import _PercentField from './PercentField';
import _RadioButtonsFormField from './RadioButtonsFormField';
import RoutingNumberOrSwiftCodeField from './RoutingNumberOrSwiftCodeField';
import _SearchField from './SearchField';
import _SearchMultiField from './SearchMultiField';
import _SelectField from './SelectField';
import _TextField from './TextField';
import _TimeField from './TimeField';

const currencyFieldProps = {
  parse: (input: string) => {
    if (input === '-') {
      return '-0';
    }
    if (input === '-.') {
      return '-.0';
    }
    if (isBlank(input)) {
      return input;
    }
    return input.toString().replace(/,/g, '');
  },
  format: (value: number | '-0' | '-.0') => {
    if (isBlank(value)) {
      return '';
    }
    if (value === '-.0') {
      return '-.';
    }
    if (value === '-0') {
      return '-';
    }
    return value;
  },
};

const CheckboxField = withField(_CheckboxField);
const DateField = withField(_DateField);
const TimeField = withField(_TimeField);
const MonthField = withField(_MonthField);
const HighPrecisionCurrencyField = withField(_HighPrecisionCurrencyField);
const HighPrecisionPercentField = withField(_HighPrecisionPercentField);
const HighPrecisionNumberField = withField(_HighPrecisionNumberField);
const AlwaysValidateHighPrecisionNumberField = withField(
  _HighPrecisionNumberField,
  {},
  'always'
);
const DecimalField = withField(_DecimalField, {
  parse: (value: string) => (value === '.' ? '0.' : value),
});
const IntegerField = withField(_IntegerField, { parse: parseIntegerField });
const MultiplierField = withField(_MultiplierField);
const NumberField = withField(_NumberField);
const CountryField = withField(_CountryField, {
  type: 'select',
});
const PatternField = withField(_PatternField);
const PercentField = withField(_PercentField);
const RadioButtonsFormField = withField(_RadioButtonsFormField, {
  type: 'radio',
});
const SearchField = withField(_SearchField);
const SearchMultiField = withField(_SearchMultiField);
const SelectField = withField(_SelectField);
const TextField = withField(_TextField);
const TextFieldValidateOnSubmit = withField(_TextField, {}, 'onSubmit');
const CurrencyField = withField(_CurrencyField, currencyFieldProps);
const AlwaysValidateCurrencyField = withField(
  _CurrencyField,
  currencyFieldProps,
  'always'
);
const NullableAddressFormField = withField(
  AddressField,
  {
    format: modelToFormValueNullable,
    parse: formValueToModelNullable,
    validate: nullableAddressFormFieldsValidators,
  },
  'onSubmit'
);
const AddressFormField = withField(
  AddressField,
  {
    format: modelToFormValue,
    parse: formValueToModel,
    validate: addressFormFieldsValidators,
  },
  'onSubmit'
);
const PaymentUsAddressFormField = withField(
  AddressField,
  {
    format: modelToFormValue,
    parse: formValueToModel,
    validate: paymentUsAddressFormFieldsValidators,
  },
  'onSubmit'
);
const ComboboxField = withField(_ComboboxField);
const ComboboxMultiField = withField(_ComboboxMultiField);
const CheckboxGroupField = withField(_CheckboxGroupField);
const NullField = withField(_NullField);

/// @deprecated use NullField instead
const NullFormField = NullField;

const OrderableHasManyFields = _OrderableHasManyFields;
const HasManyFields = _HasManyFields;

export {
  CheckboxField,
  ComboboxField,
  ComboboxMultiField,
  DateField,
  MonthField,
  HighPrecisionCurrencyField,
  HighPrecisionPercentField,
  HighPrecisionNumberField,
  DecimalField,
  IntegerField,
  MultiplierField,
  NumberField,
  PatternField,
  PercentField,
  SelectField,
  TextField,
  CurrencyField,
  NullableAddressFormField,
  AddressFormField,
  PaymentUsAddressFormField,
  FfcField,
  RoutingNumberOrSwiftCodeField,
  RadioButtonsFormField,
  CheckboxGroupField,
  NullField,
  NullFormField,
  OrderableHasManyFields,
  CountryField,
  HasManyFields,
  BankAccountSelectField,
  SearchField,
  SearchMultiField,
  FfcCheckboxField,
  TimeField,
  TextFieldValidateOnSubmit,
  AlwaysValidateCurrencyField,
  AlwaysValidateHighPrecisionNumberField,
};
