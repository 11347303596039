import { Provider } from 'mobx-react';
import React, { Component } from 'react';

import api from '~/api';
import { appStore } from '~/stores/AppStore';
import { netStore } from '~/stores/NetStore';
import { settingsStore } from '~/stores/SettingsStore';

/**
 * Provider of singleton stores, for components that care to inject them.
 *
 * TODO: replace me with some initialization code, once nobody injects singletons anymore
 */
export default class GlobalsProvider extends Component {
  globals: any;

  constructor(props: Record<never, never>) {
    super(props);

    this.globals = {
      api,
      appStore,
      netStore,
      settingsStore,
      timingStore: window,
      windowLocation: window.location,
    };
  }

  render() {
    const { children } = this.props;
    return <Provider {...this.globals}>{children}</Provider>;
  }
}
