import Icon from '@appfolio/react-gears/lib/components/Icon';
import { useColorMode } from '@im-frontend/components/ColorModeContext';
import classnames from 'classnames';
import invariant from 'invariant';
import { observer, useLocalStore } from 'mobx-react-lite';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Dropdown from '~/components/shared/Dropdown';
import FirmLogo from '~/components/shared/FirmLogo';
import HelpCenter from '~/components/shared/HelpCenter';
import { appStore } from '~/stores/AppStore';
import { netStore } from '~/stores/NetStore';
import NetworkActivityIndicator from './NetworkActivityIndicator';

export interface Props {
  className?: string;
  toggleNav: () => void;
  lazyLoading: boolean;
  fullStylePrelogin?: boolean;
}

// tolerate missing injections to help tests be loosely coupled
const Header = (props: Props) => {
  const history = useHistory();
  const { className, toggleNav, lazyLoading } = props;
  const { firmInfo, session } = appStore;
  const { receiveCount, sendCount } = netStore;

  const { onSignOut, onSettings } = useLocalStore(() => ({
    onSettings() {
      invariant(history, 'missing injected props');
      history.push('/settings');
    },
    onSignOut() {
      invariant(history, 'missing injected props');
      history.push('/login');
      appStore.signOut();
    },
  }));

  const { colorMode } = useColorMode();

  const darkModeEnabled = colorMode === 'dark';

  let logo: React.ReactNode = firmInfo.name;
  if (!darkModeEnabled) {
    if (firmInfo.logoUrl) {
      logo = (
        <Link to="/investments">
          <FirmLogo />
        </Link>
      );
    }
  }
  const dropDownItems = [
    {
      name: 'My Profile',
      key: 'myProfile',
      onClick: onSettings,
    },
    {
      name: 'Log Out',
      key: 'logOut',
      onClick: onSignOut,
    },
  ];

  const dropDown = session ? (
    <Dropdown
      items={dropDownItems}
      name={session.fullName}
      className="mx-2"
      useDiv={lazyLoading}
    />
  ) : props.fullStylePrelogin ? (
    <Link to="/login" className="mr-4">
      Log in
    </Link>
  ) : null;

  return (
    <div
      className={classnames('header', className, {
        'is-full-style-prelogin': props.fullStylePrelogin,
      })}
    >
      <button className="header__navtoggle" onClick={toggleNav}>
        <Icon name="bars" />
        <div className="icon-text">Menu</div>
      </button>
      <div className="header__firm">{logo}</div>
      <div className="header__filler">
        <NetworkActivityIndicator
          receiving={receiveCount > 0}
          sending={sendCount > 0}
        />
      </div>
      <div>
        <HelpCenter />
      </div>
      {dropDown}
    </div>
  );
};

export default observer(Header);
