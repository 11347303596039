import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import Input from '@appfolio/react-gears/lib/components/Input';
import React, { ComponentProps } from 'react';

export type InputProps = ComponentProps<typeof Input>;
export type TextFieldProps = FormLabelGroupProps & InputProps;

const TextFormField = ({
  label,
  required,
  hint,
  rowClassName,
  initialValue,
  feedback,
  value,
  ...inputProps
}: TextFieldProps) => {
  return (
    <FormLabelGroup
      stacked
      label={label}
      required={required}
      hint={hint}
      rowClassName={rowClassName}
      feedback={feedback}
    >
      <Input value={value} {...inputProps} />
    </FormLabelGroup>
  );
};

export default TextFormField;
