import maybeReportExceptionInterceptor from '@im-frontend/utils/clients/interceptors/maybeReportException';
import { maybeReportException } from '@im-frontend/utils/errors';
import axios from 'axios';
import { cloneDeep } from 'lodash';

const s3 = axios.create({});

// Work around https://github.com/axios/axios/issues/1522
s3.defaults = cloneDeep(s3.defaults);
maybeReportExceptionInterceptor(s3, maybeReportException);

export default s3;
