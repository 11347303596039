import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import RadioInput from '@appfolio/react-gears/lib/components/RadioInput';
import React, { ComponentProps } from 'react';

export type RadioInput = ComponentProps<typeof RadioInput>;
export type RadioButtonsFormFieldProps = FormLabelGroupProps & RadioInput;

const RadioButtonsFormField = ({
  label,
  required,
  hint,
  rowClassName,
  feedback,
  value,
  onChange,
  children,
}: RadioButtonsFormFieldProps) => {
  return (
    <FormLabelGroup
      stacked
      label={label}
      required={required}
      hint={hint}
      rowClassName={rowClassName}
      feedback={feedback}
    >
      <RadioInput onChange={onChange} value={value}>
        {children}
      </RadioInput>
    </FormLabelGroup>
  );
};

export default RadioButtonsFormField;
