import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function generalizePath(path: string) {
  return path.replace(/\/[0-9]+(\/|$)/g, '/*$1').replace(/\/$/, '');
}

declare global {
  interface Window {
    newrelic: any;
  }
}

export function useSanitizedNewRelicBrowserInteractionName() {
  const history = useHistory();
  const location = useLocation();

  const logNavigation = useCallback(
    (event: any) => {
      if (window.newrelic) {
        window.newrelic
          .interaction()
          .setName(
            generalizePath((event && event.pathname) || location.pathname)
          );
      }
    },
    [location.pathname]
  );

  logNavigation(null);
  useEffect(() => history.listen(logNavigation), [history, logNavigation]);
}
