import DateInput from '@appfolio/react-gears/lib/components/DateInput';
import FormLabelGroup from '@appfolio/react-gears/lib/components/FormLabelGroup';
import dateParse from '@im-frontend/utils/dateParse';
import React from 'react';

const DateField = ({
  label,
  required,
  hint,
  rowClassName,
  initialValue,
  feedback,
  value,
  ...inputProps
}: any) => (
  <FormLabelGroup
    stacked
    label={label}
    required={required}
    hint={hint}
    rowClassName={rowClassName}
    feedback={feedback}
  >
    <DateInput
      value={value}
      dateFormat="MM/DD/YYYY"
      parse={(dateOrString: string | Date) => {
        if (dateOrString instanceof Date) {
          return dateOrString;
        }
        // eslint-disable-next-line rulesdir/report-errors-in-catch
        try {
          // https://trello.com/c/JRIx0FGo
          // sometimes this function will throw error......
          // https://github.com/appfolio/im/tree/master/frontend/%40im-frontend/utils/dateParse.js#L79
          return dateParse(dateOrString);
        } catch (e) {
          return '';
        }
      }}
      autoComplete="off"
      {...inputProps}
    />
  </FormLabelGroup>
);

export default DateField;
