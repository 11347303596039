import { useEffect, useRef, useState } from 'react';

export default function useInternalValue<
  ExternalValue,
  InternalValue,
  C extends (...args: any[]) => any = (...args: any[]) => any
>(
  value: ExternalValue,
  onChange: C,
  {
    externalToInternalValue = (x: ExternalValue) => x as any as InternalValue,
    internalToExternalValue = (x: InternalValue) => x as any as ExternalValue,
    onChangeToInternalValue = (...args: any[]) => args[0] as InternalValue,
  }: {
    externalToInternalValue?: (value: ExternalValue) => InternalValue;
    internalToExternalValue?: (value: InternalValue) => ExternalValue;
    onChangeToInternalValue?: (...args: Parameters<C>) => InternalValue;
  }
): [InternalValue, (...args: Parameters<C>) => void] {
  const internalValueFromValue = externalToInternalValue(value);
  const [internalValue, setInternalValue] = useState(internalValueFromValue);
  const lastValueRef = useRef(value);

  useEffect(() => {
    if (lastValueRef.current !== value) {
      lastValueRef.current = value;
      setInternalValue(internalValueFromValue);
    }
  }, [value, internalValueFromValue]);

  function onChangeOverride(...args: Parameters<C>) {
    const newInternalValue = onChangeToInternalValue(...args);
    setInternalValue(newInternalValue);

    const newValue = internalToExternalValue(newInternalValue);
    lastValueRef.current = newValue;

    return onChange(newValue);
  }

  return [internalValue, onChangeOverride];
}
