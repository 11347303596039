import { Badge, Icon } from '@appfolio/react-gears';

import { difference, union } from 'lodash';
import React, { useState } from 'react';
import SearchCombobox, {
  SearchComboboxProps,
  SearchOption,
} from './SearchCombobox';

type InheritedProps = Omit<
  SearchComboboxProps,
  'defaultValue' | 'onChange' | 'onChangeWithLabel'
>;

interface OwnProps {
  defaultValue: SearchOption[];
  onChange?: (value?: number[]) => void;
}

export type SearchComboboxMultiProps = InheritedProps & OwnProps;

// For touch screens, we want a tap anywhere on the badge to delete it.
// (Also, JS-DOM doesn't have this query method so we need to check for it.)
const isTouchOnly =
  typeof window.matchMedia === 'function' &&
  window.matchMedia('(any-pointer: coarse)').matches;

/**
 * Multi-select SearchCombobox.
 * This component is uncontrolled only (i.e. it does not support `value`).
 * Because options aren't known in advance, `defaultValue` requires a full specification of labels and values.
 */
function SearchComboboxMulti({
  defaultValue = [],
  onChange = () => {},
  ...props
}: SearchComboboxMultiProps) {
  const [chosen, setChosen] = useState<SearchOption[]>(defaultValue);

  return (
    <div data-testid="comboboxMulti">
      <div className="mb-1" data-testid="comboboxMulti-badges">
        {chosen.map((choice, i) => (
          <Badge
            className="align-items-center border d-inline-flex font-weight-normal ml-1 text-left"
            color="light"
            key={i}
            onTouchEnd={() =>
              setChosen(prev => {
                const next = difference(prev, [choice]);
                onChange(next.map(o => o.value));
                return next;
              })
            }
            role={isTouchOnly ? 'button' : undefined}
          >
            {choice.label}
            <Icon
              className="ml-1"
              name="times"
              onClick={() =>
                setChosen(prev => {
                  const next = difference(prev, [choice]);
                  onChange(next.map(o => o.value));
                  return next;
                })
              }
              role={isTouchOnly ? undefined : 'button'}
              style={{ opacity: 0.5 }}
            />
          </Badge>
        ))}
      </div>
      <SearchCombobox
        onChangeWithLabel={(value, label) =>
          setChosen(prev => {
            const next = union(prev, [{ label, value }]);
            onChange(next.map(o => o.value));
            return next;
          })
        }
        {...props}
      />
    </div>
  );
}

export default SearchComboboxMulti;
