/**
 * A wrapper around a Storage.
 * For more on Storage @see https://developer.mozilla.org/en-US/docs/Web/API/Storage
 * With this we can make sure the wrapped storage is either available and allowed, OR
 * it's not available (undefined). This is to prevent the error thrown by some browsers
 * when the code access a storage that is not available.
 */
class BurritoStorage {
  storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  get length(): number {
    return this.storage.length;
  }
}

let imSessionStorage: Storage | undefined;
let imLocalStorage: Storage | undefined;

// eslint-disable-next-line rulesdir/report-errors-in-catch
try {
  // eslint-disable-next-line no-restricted-globals
  const [s, l] = [sessionStorage, localStorage];
  s.setItem('test', 'test');
  s.removeItem('test');
  l.setItem('test', 'test');
  l.removeItem('test');
  imSessionStorage = new BurritoStorage(s);
  imLocalStorage = new BurritoStorage(l);
} catch (e) {
  // eslint-disable-next-line no-console
  console.log(e);
}

export { imSessionStorage, imLocalStorage };
