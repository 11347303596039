import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import InputGroup from '@appfolio/react-gears/lib/components/InputGroup';
import InputGroupAddon from '@appfolio/react-gears/lib/components/InputGroupAddon';
import classNames from 'classnames';
import React from 'react';
import HighPrecisionNumberInput, {
  HighPrecisionNumberInputProps,
} from '../inputs/HighPrecisionNumberInput';

// Current react-gears does not have any typings for PatternInput. Improve typing when this gets fixed
export type PatternInputProps = any;
export type PatternFieldProps = FormLabelGroupProps &
  HighPrecisionNumberInputProps;

const PatternField = ({
  label,
  required,
  hint,
  rowClassName,
  validate,
  initialValue,
  feedback,
  tableField,
  id,
  ...rest
}: PatternFieldProps) => (
  <FormLabelGroup
    stacked
    label={label}
    required={required}
    hint={hint}
    inputId={id}
    rowClassName={classNames(rowClassName, { 'no-gutters mb-0': tableField })}
    feedback={feedback}
  >
    <InputGroup>
      <InputGroupAddon addonType="prepend">$</InputGroupAddon>
      <HighPrecisionNumberInput id={id} integerPlaces={20} {...rest} />
    </InputGroup>
  </FormLabelGroup>
);

export default PatternField;
