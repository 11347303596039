import investorPortalClient from './clients/investorPortal';

// Not every vhost has FirmInfo. Provide a reasonable default so people can still use the portal
// on these vhosts.
const dummyResponse = {
  data: {
    name: 'Investor Portal',
  },
};

export const info = () =>
  investorPortalClient.get('/firm').catch(() => dummyResponse);
