import { autorun } from 'mobx';
import { namespace } from '~/namespaceInfo';

export default function syncNewRelicMetadata(newrelic: any, appStore: any) {
  autorun(() => {
    const { session } = appStore;

    newrelic.setCustomAttribute('account', namespace);

    if (session) {
      newrelic.setCustomAttribute('email', session.email);
    }
  });
}
