import MfaStore from '~/stores/MfaStore';

export default function mfaPrompt(client: any, mfaStore: MfaStore) {
  client.interceptors.request.use((config: any) => {
    config.retryCount = config.retryCount || 0;
    return config;
  });

  client.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      const { config, response } = error;
      if (
        response &&
        response.status === 403 &&
        response.data &&
        response.data.message === 'MFA'
      ) {
        return mfaStore.openModalAndSetPhoneNumberLast4(
          client,
          config,
          response.data.mfaPhoneNumberLast4,
          response.data.mfaMethod
        );
      }
      return Promise.reject(error);
    }
  );
}
