import { FormLabelGroup } from '@appfolio/react-gears';
import Button from '@appfolio/react-gears/lib/components/Button';
import FieldsGroup from '@im-frontend/forms/FieldsGroup';
import {
  HasManyFieldsProps,
  HasManyFieldsRenderProps,
} from '@im-frontend/forms/HasManyFields';
import isBlank from '@im-frontend/utils/isBlank';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import {
  EmailFormField,
  HasManyFields,
  PhoneFormField,
  SelectFormField,
  TextFormField,
} from '~/utils/forms2';
import { validateEmail } from './EmailField';

export interface ContactField {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  id: number;
  isNewContact: boolean;
}

export const nullContact = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  isNewContact: false,
};

interface ContactFormFieldProps extends HasManyFieldsRenderProps {
  showDocumentAccess: boolean;
  showIsSigner: boolean;
}

export const ContactFormField = ({ name }: ContactFormFieldProps) => {
  return (
    <FormLabelGroup rowClassName="mb-0">
      <FieldsGroup name={name}>
        <TextFormField
          id="first_name"
          label="First Name"
          name="firstName"
          required
          validate={value =>
            isBlank(value) ? 'Please enter first name' : undefined
          }
        />
        <TextFormField
          id="last_name"
          label="Last Name"
          name="lastName"
          required
          validate={value =>
            isBlank(value) ? 'Please enter last name' : undefined
          }
        />
        <EmailFormField
          name="email"
          id="email"
          label="Email"
          required
          validate={validateEmail}
        />
        <PhoneFormField name="phone" id="phone" label="Phone Number" />
      </FieldsGroup>
    </FormLabelGroup>
  );
};

export const SelectableContactFormField: React.FC<
  HasManyFieldsRenderProps & HasManyFieldsProps
> = ({
  name,
  index,
  ...props
}: HasManyFieldsRenderProps & HasManyFieldsProps) => {
  const { options, showDocumentAccess, showIsSigner } =
    props.templateExtraProps;

  const form = useForm();
  const { values } = useFormState();
  const contacts = values.contacts.slice();
  const value = contacts[index];
  const toggleExisting = () => {
    form.change(`${name}.isNewContact`, !value.isNewContact);
    !value.isNewContact && form.change(`${name}.id`, null);
  };

  if (index < contacts.length) {
    // add back current selection
    contacts.splice(index, 1);
  }
  const usedContacts = contacts.reduce((contactIds: string[], s: any) => {
    s && s.id && contactIds.push(s.id);
    return contactIds;
  }, []);

  const contactOptions = options.reduce(
    (availableOptions: any[], contact: { value: string; label: string }) => {
      if (!usedContacts.includes(contact.value)) {
        availableOptions.push(contact);
      }
      return availableOptions;
    },
    []
  );
  const contactFormField = (
    <ContactFormField
      {...props}
      name={name}
      index={index}
      showDocumentAccess={showDocumentAccess}
      showIsSigner={showIsSigner}
    />
  );

  if (contactOptions.length !== 0) {
    return (
      <>
        {value.isNewContact ? (
          contactFormField
        ) : (
          <SelectFormField
            options={contactOptions}
            validate={(investorContactId: string) => {
              return isBlank(investorContactId)
                ? 'Please select a contact'
                : undefined;
            }}
            name={`${name}.id`}
            placeholder="Select a Contact"
          />
        )}
        <div className="link-below">
          <Button color="link" size="sm" onClick={toggleExisting}>
            {value.isNewContact ? '* Select a Contact' : '+ Add a New Contact'}
          </Button>
        </div>
      </>
    );
  }

  form.change(`${name}.isNewContact`, true);
  form.change(`${name}.id`, null);
  return contactFormField;
};

export const HasManySelectableContactFields = (props: any) => {
  return (
    <HasManyFields
      template={SelectableContactFormField}
      blank={nullContact}
      templateExtraProps={{
        options: props.options,
        showDocumentAccess: props.showDocumentAccess,
        showSigner: props.showSigner,
      }}
      initialValue={[nullContact]}
      {...props}
    />
  );
};
