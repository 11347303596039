import enforceResponseTypeMatch from '@im-frontend/utils/clients/interceptors/enforceResponseTypeMatch';
import addTransactionIdHeader from '@im-frontend/utils/transforms/addTransactionIdHeader';
import axios from 'axios';
import { createClient } from '.';

export default createClient();

// camelizeKeys is not appropriate for the UploadForm media type returned by
// some upload endpoints. Provide an uncamelized version instead.
const uploadsClient = createClient({
  transformResponse: null,
});

// decamelizeKeys has a bug that breaks uploading FormData, so use a special client for docs
const docsClient = createClient({
  transformRequest: [].concat(
    addTransactionIdHeader,
    axios.defaults.transformRequest
  ),
  transformResponse: null,
});

const jsonOnlyClient = (args: typeof createClient.arguments) => {
  const client = createClient({
    ...args,
  });
  enforceResponseTypeMatch(client);
  return client;
};

const acceptOnlyJsonClient = jsonOnlyClient({
  headers: { Accept: 'application/json', 'X-Api-Version': '1i' },
});

export { uploadsClient, docsClient, acceptOnlyJsonClient };
