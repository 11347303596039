import { maybeReportException } from '@im-frontend/utils/errors';
import { computed, flow, observable } from 'mobx';
import DependencyStore from './DependencyStore';
import { FetchResult, ScopedModel, ScopedRequest } from './interfaces';

export function generalizePath(path: any) {
  return path.replace(/\/[0-9]+(\/|$)/g, '/*$1').replace(/\/$/, '');
}

export default class RequestStore<
  T extends DependencyStore<ScopedRequest>,
  S extends T['fields']
> {
  private dependencyStore: T;
  static toastNow: (e: Error) => void = () => {};
  static namespaceInfo: {
    uiPrefix: string;
    namespace: string;
  };
  static timeLogging: (metadata: any) => void = undefined;

  @observable private lastParams: any = [];
  @observable.ref private response: FetchResult<T> | null = null;
  @observable private currentPendingRequestId = 0;
  @observable private currentFinishedRequestId = 0;
  private timing: {
    request: {
      start: number | null;
      end: number | null;
    };
    data: {
      start: number | null;
      end: number | null;
    };
  } = {
    request: {
      start: null,
      end: null,
    },
    data: {
      start: null,
      end: null,
    },
  };

  @observable error: any = null;

  constructor(dependencyStore: T) {
    this.dependencyStore = dependencyStore;
  }

  @computed
  get data(): ScopedModel<FetchResult<T>['data'], S> | null {
    if (this.response) {
      this.timing.data.start = Date.now();
      const { data } = this.response;
      this.logTimingInfo();
      return data;
    } else {
      return null;
    }
  }

  @computed
  get headers(): FetchResult<T>['headers'] | null {
    return this.response ? this.response.headers : null;
  }

  @computed
  get isFetching() {
    return this.currentPendingRequestId !== this.currentFinishedRequestId;
  }

  @computed
  get isLoading() {
    return !this.response;
  }

  triggerRequest = flow<FetchResult<T>, Parameters<T['fetchData']>>(
    function* (this: RequestStore<T, S>, ...args: Parameters<T['fetchData']>) {
      this.lastParams = args;
      this.currentPendingRequestId++;
      const thisRequestId = this.currentPendingRequestId;

      try {
        this.resetTimingInfo();
        const response: FetchResult<T> = yield this.dependencyStore.fetchData(
          ...args
        );

        if (thisRequestId !== this.currentPendingRequestId) {
          return;
        }

        this.timing.request.end = Date.now();
        this.response = response;
        this.error = null;
        this.currentFinishedRequestId = thisRequestId;
        return this.response;
      } catch (e) {
        maybeReportException(e);

        if (thisRequestId !== this.currentPendingRequestId) {
          return;
        }

        this.error = e;
        this.currentFinishedRequestId = thisRequestId;
        RequestStore.toastNow(e as Error);
      }
    }.bind(this)
  );

  reload = () => {
    return this.triggerRequest(...this.lastParams);
  };

  resetTimingInfo = () => {
    this.timing.request.start = Date.now();
    this.timing.request.end = null;
    this.timing.data.start = null;
    this.timing.data.end = null;
  };

  logTimingInfo = () => {
    if (!RequestStore.timeLogging || !this.timing.request.end) {
      return;
    }

    if (this.timing.data.end) {
      return;
    }

    this.timing.data.end = Date.now();

    const path = window.location.pathname.replace(
      RequestStore.namespaceInfo.uiPrefix,
      ''
    );
    const urlWithQuery = this.response?.config?.url;
    const url = urlWithQuery && urlWithQuery.split('?')[0];

    RequestStore.timeLogging({
      ...this.response?.config?.imTimingData,
      type: 'data',
      requestStart: this.timing.request.start,
      requestEnd: this.timing.request.end,
      dataStart: this.timing.data.start,
      dataEnd: this.timing.data.end,
      account: RequestStore.namespaceInfo.namespace,
      method: this.response?.config?.method,
      requestUrl: url,
      generalizedRequestUrl: url ? generalizePath(url) : undefined,
      path,
      generalizedPath: generalizePath(path),
    });
  };
}
