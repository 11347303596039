import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Combobox, { ComboboxProps, Option } from './Combobox';

/**
 * Core of search-driven combobox; makes API call(s) to find resources matching
 * the user's query.
 */
export type SearchCallback = (query: string) => Promise<SearchOption[]>;

/**
 * A search result consisting of a numeric database ID and a string label.
 */
export type SearchOption = Option<number>;

type InheritedProps = Omit<
  ComboboxProps<number>,
  'onChangeText' | 'options' | 'noResultsLabel' | 'placeholder'
>;

interface SearchProps {
  onSearch: SearchCallback;
  preload?: boolean;
}

export type SearchComboboxProps = InheritedProps & SearchProps;

/// Default Combobox default filtering by label prefix. Helps us support semantic
/// search (i.e. include items where a _related_ object matches the query).
const unfiltered = (options: any) => options;

/**
 * Combobox whose options are refined dynamically as the user types.
 * The value of search options is always a numeric database ID, and
 * the label is the searchable name of a database record.
 *
 * Search functionality is provided via a context.
 */
function SearchCombobox({
  onChange = () => {},
  onChangeWithLabel = () => {},
  onSearch,
  preload,
  ...props
}: SearchComboboxProps) {
  const last = useRef<string>();
  const [options, setOptions] = useState<SearchOption[]>([]);

  const maybeSetOptions = useCallback((newOptions: SearchOption[]) => {
    setOptions(oldOptions =>
      isEqual(oldOptions, newOptions) ? oldOptions : newOptions
    );
  }, []);

  useEffect(() => {
    if (preload) {
      onSearch('').then(maybeSetOptions);
    } else {
      maybeSetOptions([]);
    }
  }, [maybeSetOptions, onSearch, preload]);

  return (
    <Combobox<number>
      {...props}
      filterOptions={unfiltered}
      onChange={value => {
        onSearch('').then(maybeSetOptions);
        onChange(value);
        if (value && onChangeWithLabel) {
          const label = options.find(o => o.value === value)?.label;
          if (label) {
            onChangeWithLabel(value, label);
          }
        }
      }}
      onChangeText={query => {
        onSearch(query).then(maybeSetOptions);
        last.current = query;
      }}
      options={options}
      placeholder="Type to search..."
    />
  );
}

export default SearchCombobox;
