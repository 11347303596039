// See #im-frontend if you're running into this firing for your feature
import { mustReportException } from '@im-frontend/utils/errors';
import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

/**
 * AWS WAF max URL length. Includes path, query string, hash and all other URL components;
 * excludes protocol and hostname.
 *
 * The actual max length is believed to be 2048, which is also the limit commonly enforced
 * HTTP servers; however, we know for a fact that WAF sometimes passes requests longer than
 * 2048 characters, so this is more of a guess.
 *
 * We include an 8-character buffer to account for ID expansion in production (i.e. customers
 * have resources with 5- and 6-character numeric IDs, whereas in QA we only ever see
 * 1-3 character IDs.)
 */
const MAX_CHARACTERS = 2048 - 8;

// This list of regexes are MANUALLY allow-listed in QA/Prod.
// => Add to this list only if you know the allow list has been updated in QA/Prod!
// if you add to this list, you also need to update the interceptor and WAF
// https://stackoverflow.com/c/appfolio-investment-management/questions/1016
const KNOWN_EXCEPTIONS = [
  /funds\/[0-9]+\?fields=/,
  /distributions\/[0-9]+\?fields=/,
  /statements\/[0-9]+\?fields=/,
  /statements\/preview\?name=/,
  /investors\?fields=/,
  /investors\/[0-9]+\?fields=/,
  /positions\?/,
];

/**
 * File a Sentry report for requests that are likely to be rejected by AWS WAF.
 * Throw an exception to prevent the request from being sent.
 */
export default function enforceMaxRequestSize(client: AxiosInstance) {
  client.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    const { baseURL, url } = request;
    const length = (url?.length || 0) + (baseURL?.length || 0);
    if (length > MAX_CHARACTERS) {
      if (url && !KNOWN_EXCEPTIONS.some(rx => rx.test(url))) {
        mustReportException(
          new Error(
            `API request URL exceeds max permissible length; please look for suggestion in https://stackoverflow.com/c/appfolio-investment-management/questions/1016`
          ),
          { baseURL, url, length, maxLength: MAX_CHARACTERS }
        );
        throw `This request params length exceeds character max`;
      }
    }
    return request;
  });
}
