import { AxiosInstance, AxiosResponse } from 'axios';

// retryCertainHttpCodes does exp backoff retries with some jitter when it receives a 429 (rate limiting) or 503 (service unvailable) or 502 (bad gateway)
export default function retryCertainHttpCodes(client: AxiosInstance) {
  client.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: any) => {
      const { config, response } = error;
      if (!response || ![429, 502, 503].includes(response.status)) {
        throw error;
      }
      config.numRetries = config.numRetries ? config.numRetries + 1 : 1;
      if (config.numRetries > 3) {
        throw error;
      }
      // exp backoff sleep with jitter for up to:
      //  3 retries: 1^2 + 2^2 + 3^2 = 14 seconds
      await new Promise(resolve =>
        setTimeout(
          resolve,
          1000 * (Math.random() + Math.pow(config.numRetries, 2))
        )
      );
      return client(config);
    }
  );
}
