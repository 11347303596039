import { inject as mobxInject } from 'mobx-react';
import { ComponentProps, ComponentType } from 'react';

export function inject<Key extends string>(...args: Key[]) {
  return function innerInject<InjectComponentType extends ComponentType<any>>(
    Component: InjectComponentType
  ): ComponentType<Omit<ComponentProps<InjectComponentType>, Key>> {
    return mobxInject(...args)(Component) as any;
  };
}
