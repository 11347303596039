const root = document.getElementById('root');
const dataset = root ? root.dataset : {};

export const namespacePrefix: string = dataset.vhostPrefix || '/wfp';
export const namespace: string = namespacePrefix.slice(1);
export const apiPrefix: string = dataset.apiPrefix || '/im/api';
export const uiPrefix: string = dataset.uiPrefix || '/wfp/investor';
export const release: string = dataset.release;

/// Circle build url set by the backend and used as tags in Sentry.
export const circleBuildUrl: string = dataset.buildUrl || '';

/// Pre-established access token being shared with this frontend after authenticating elsewhere (e.g. mobile app).
export const accessToken: string = dataset.accessToken;
root?.removeAttribute('data-access-token');

export const parallelFlowUrl = dataset.parallelFlowUrl;
export const vanityDomain = dataset.vanityDomain;

/// Determines currency symbol.
export const currency: string = dataset.currency || 'USD';

/// Determines format of dates, times, currencies, etc.
export const locale: string = dataset.locale || 'en-US';
