import axios from 'axios';
import uniqid from 'uniqid';
import { uploadsClient } from './clients/investorPortal';
import s3Client from './clients/s3';

export const newForm = () =>
  uploadsClient.post(
    // Safari caches identical POST requests too
    `/uploads/actions/new_form?bustUselessSafariCache=${uniqid()}`
  );

export function create(form: any, content: any) {
  const formData: any = new FormData();
  Object.entries(form.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('Content-Type', content.type || 'binary/octet-stream');

  formData.append(
    'Content-Disposition',
    `attachment; filename=${content.name}`
  );

  formData.append('file', content);

  const c = s3Client;
  const ax = axios;
  return s3Client.post(form.url, formData);
}
