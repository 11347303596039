import { Icon } from '@appfolio/react-gears';
import Tooltip from '@im-frontend/utils/Tooltip';
import isBlank from '@im-frontend/utils/isBlank';
import React from 'react';
import { useFormState } from 'react-final-form';
import { RoutingNumberValidator } from '../validators';
import { CheckboxField, TextField } from '.';

export interface RoutingNumberOrSwiftCodeValues {
  routingNumber?: string;
  swiftCode?: string;
  usesSwiftCode?: boolean;
}

export interface Props {
  disabled?: boolean;
  required?: boolean;
}

const RoutingNumberOrSwiftCodeField = ({ disabled, required }: Props) => {
  const {
    values: currentValues,
    initialValues,
    dirtyFields,
  } = useFormState<RoutingNumberOrSwiftCodeValues>();
  const showSwiftCodeField = dirtyFields.usesSwiftCode
    ? currentValues.usesSwiftCode
    : initialValues?.usesSwiftCode || !isBlank(initialValues?.swiftCode);
  return (
    <>
      {showSwiftCodeField ? (
        <TextField
          data-cy="swiftCodeField"
          name="swiftCode"
          label="SWIFT Code"
          validate={value => {
            if (isBlank(value) && required) {
              return 'Please enter a SWIFT code';
            }
          }}
          required={required}
          disabled={disabled}
        />
      ) : (
        <TextField
          data-cy="routingNumberField"
          name="routingNumber"
          label="Routing Number"
          required={required}
          validate={value => {
            if (!isBlank(value)) {
              return new RoutingNumberValidator(value).errorMessage();
            }
            if (isBlank(value) && required) {
              return 'Please enter a routing number';
            }
          }}
          disabled={disabled}
        />
      )}
      <CheckboxField
        name="usesSwiftCode"
        label={
          <>
            <Tooltip
              tooltipText="Typically required for non-US bank accounts."
              placement="top"
              tag="span"
            >
              Uses SWIFT Code
              <small className="pl-1 text-muted">
                <Icon id="swiftCodeInfo" name="info-circle" />
              </small>
            </Tooltip>
          </>
        }
        initialValue={showSwiftCodeField}
        disabled={disabled}
      />
    </>
  );
};

export default RoutingNumberOrSwiftCodeField;
