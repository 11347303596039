import toFields from '@im-frontend/utils/toFields';
import invariant from 'invariant';
import { CallbackFields, ScopedRequest } from './interfaces';

export default class DependencyStore<T extends ScopedRequest> {
  private fetchCallback: T;

  fields: CallbackFields<T> = {};

  constructor(fetchCallback: T) {
    this.fetchCallback = fetchCallback;
  }

  fetchData(...args: Parameters<T>): ReturnType<T> {
    switch (this.fetchCallback.length) {
      case 0:
      case 1:
        return this.fetchCallback(
          { fields: toFields(this.fields), ...args[0] },
          ...args.slice(1)
        );
      case 2:
        return this.fetchCallback(
          args[0],
          { fields: toFields(this.fields), ...args[1] },
          ...args.slice(2)
        );
      default:
        invariant(
          false,
          `fetchCallback has must have exactly zero, one, or two arguments: ${this.fetchCallback.length} given in ${this.fetchCallback.name}}`
        );
    }
  }
}
