import PdfFormLabel from '@im-frontend/components/PdfFormLabel';
import PdfTextbox from '@im-frontend/components/PdfTextbox';
import React from 'react';

type childrenProps = React.ComponentProps<typeof PdfFormLabel> &
  React.ComponentProps<typeof PdfTextbox>;

interface Props extends childrenProps {
  name: string;
}

const PdfTextboxField: React.FC<Props> = ({
  name,
  hint,
  feedback,
  value,
  ...inputProps
}: Props) => (
  <PdfFormLabel feedback={feedback} hint={hint}>
    <PdfTextbox invalid={!!feedback} value={value} {...inputProps} />
  </PdfFormLabel>
);

export default PdfTextboxField;
