import { Card, Container } from '@appfolio/react-gears';
import { CircleSpinner as Spinner } from '@im-frontend/components/Spinner';
import classNames from 'classnames';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import MfaModal from '~/components/MfaModal';
import { appStore } from '~/stores/AppStore';
import { settingsStore } from '~/stores/SettingsStore';
import ActivityMonitor from './ActivityMonitor';
import Footer from './Footer';
import Header from './Header';
import HeaderBanner from './HeaderBanner';
import Nav from './Nav';

interface Props {
  className?: string;
  prelogin?: boolean;
  fullStylePrelogin?: boolean;
  lazyLoading?: boolean;
  isEmbedded?: boolean;
}

interface State {
  isNavigating: boolean;
}

@observer
class Page extends React.Component<Props, State> {
  static defaultProps = {
    prelogin: false,
    lazyLoading: false,
    fullStylePrelogin: false,
  };

  static NotFound = () => (
    <Page className="error" prelogin>
      <Card className="p-4">
        <div style={{ textAlign: 'center' }}>
          <p>
            Sorry, the page you are looking for was not found, check for any
            typos and try again.
          </p>
          <p>
            <pre>Reference ID: 404 Not Found</pre>
            <pre>Unknown Path: {window.location.pathname}</pre>
          </p>
        </div>
      </Card>
    </Page>
  );

  state = {
    isNavigating: false,
  };

  componentDidMount() {
    const { prelogin, lazyLoading, fullStylePrelogin } = this.props;
    if (!prelogin && !lazyLoading && !fullStylePrelogin) {
      appStore.recordActivity();
    }
    appStore.mfa.closeModal();
  }

  toggleNav = () => {
    this.setState({ isNavigating: !this.state.isNavigating });
  };

  @computed
  get showDistributionTab() {
    if (!settingsStore.settings) {
      return true;
    }
    const { displayDistribution } = settingsStore.settings.discretionary.layout;
    return displayDistribution;
  }

  @computed
  get showContributionsTab() {
    return settingsStore.displayContributions;
  }

  render() {
    const {
      children,
      className,
      prelogin,
      lazyLoading,
      isEmbedded,
      fullStylePrelogin,
    } = this.props;

    const { loading } = settingsStore;
    const { session } = appStore;

    const isPreview = session && session.preview;

    const mainClasses = classNames(
      prelogin ? 'main--prelogin' : 'main',
      {
        'not-navigating': !this.state.isNavigating,
      },
      className,
      { 'is-preview': isPreview }
    );
    const contentClasses = classNames(
      { main__content: !prelogin },
      { 'no-nav': fullStylePrelogin },
      { 'main__content--prelogin': prelogin },
      className,
      { 'prelogin-background-image': prelogin }
    );
    const headerClasses = classNames({ 'is-preview': isPreview }, className);
    let header;
    let nav;
    let content;
    let footer;

    if (isEmbedded) {
      return <div>{children}</div>;
    }

    if (prelogin) {
      const { portalBackgroundUrl } = appStore.firmInfo;
      content = (
        <Container
          className={contentClasses}
          fluid
          style={{
            backgroundImage: `url(${portalBackgroundUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundAttachment: 'fixed',
          }}
        >
          {children}
        </Container>
      );

      footer = <Footer />;
    } else if (loading && !fullStylePrelogin) {
      return <Spinner />;
    } else {
      header = (
        <>
          <HeaderBanner />
          <Header
            className={headerClasses}
            toggleNav={this.toggleNav}
            lazyLoading={lazyLoading}
            fullStylePrelogin={fullStylePrelogin}
          />
        </>
      );

      nav = (
        <Nav
          showDistributionTab={this.showDistributionTab}
          showContributionsTab={this.showContributionsTab}
        />
      );

      content = (
        <div className={contentClasses}>
          {!prelogin && !lazyLoading && !fullStylePrelogin && (
            <>
              <MfaModal />
              {!appStore.isMobileApp && <ActivityMonitor />}
            </>
          )}
          {children}
        </div>
      );
    }

    return (
      <div className="app">
        {header}
        <div className={mainClasses}>
          {!fullStylePrelogin && nav}
          {content}
        </div>
        {footer}
      </div>
    );
  }
}

export default Page;
