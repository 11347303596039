import FormLabelGroup, {
  FormLabelGroupProps,
} from '@appfolio/react-gears/lib/components/FormLabelGroup';
import Combobox, { ComboboxProps } from '@im-frontend/components/Combobox';
import classNames from 'classnames';
import React from 'react';

export type ComboboxFieldProps = FormLabelGroupProps & ComboboxProps<any>;

/**
 * ComboboxField is a wrapper around Combobox that adds the FormLabelGroup.
 * Because forms lack static type safety, it discards the type parameter of
 * Combobox; its values are `any` as with every other form field.
 */
function ComboboxField({
  label,
  required,
  hint,
  rowClassName,
  // validate,     -- where did this come from in SelectField??
  // initialValue, -- TODO grok how default/initial value works (& add to Combobox et al, if necessary)
  feedback,
  onChange,
  // tableField, -- where did this come from in SelectField??
  id,
  value,
  ...rest
}: ComboboxFieldProps) {
  // Hack: deal with poorly-understood issue where someone is coercing undefined to ''
  // (Maybe only an issue in forms/filtering?)
  if (value === '') {
    value = undefined;
  }
  return (
    <FormLabelGroup
      stacked
      label={label}
      required={required}
      hint={hint}
      rowClassName={classNames(rowClassName, {
        'no-gutters mb-0': false /*tableField*/,
      })}
      inputId={id}
      feedback={feedback}
    >
      <Combobox<any> id={id} onChange={onChange} value={value} {...rest} />
    </FormLabelGroup>
  );
}

export default ComboboxField;
