import { Badge } from '@appfolio/react-gears';
import * as cypress from '@im-frontend/utils/cypress';
import React from 'react';

import './index.scss';

/**
 * Visual indicator of network activity. Shows little red/green arrows
 * but is mostly intended to be queried, using CSS selectors, by automated
 * test code to avoid race conditions w/completing network requests vs.
 * updating UI state.
 *
 * Only visible when Cypress is present in the browser, or when the app is in
 * development mode.
 */
export default function NetworkActivityIndicator({
  receiving = false,
  sending = false,
}) {
  if (!cypress.isPresent) {
    return null;
  }

  return (
    <Badge id="netstat">
      <span className={receiving ? 'netstat__recv' : 'netstat__norecv'}>
        &darr;
      </span>
      <span className={sending ? 'netstat__send' : 'netstat__nosend'}>
        &uarr;
      </span>
    </Badge>
  );
}
