import { formatPhone } from '@im-frontend/utils/phone';
import { compact } from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import ExternalLink from '~/components/shared/ExternalLink';
import AppStore from '~/stores/AppStore';

interface Props {
  appStore?: AppStore;
}

@inject('appStore')
@observer
export default class Header extends React.Component<Props> {
  render() {
    const { firmInfo } = this.props.appStore;

    return (
      <div className="footer px-3 py-1">
        <h6 className="my-1">
          {firmInfo.name}
          <br />
          <div className="small">
            <div>{formatPhone(firmInfo.phone)}</div>
            <div>{firmInfo.email}</div>
          </div>
        </h6>
      </div>
    );
  }
}
