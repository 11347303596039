import AppStore from '~/stores/AppStore';

// TODO: be less metaprogrammy (this is unnecessarily complex)
function generateSignOutAndRedirectToLogin(
  appStore: AppStore,
  redirectToPath: any
) {
  return (queryParamString: any) => {
    if (appStore.isMobileApp) {
      appStore.sessionExpired();
      return;
    }
    appStore.signOut();
    redirectToPath(`/login?${queryParamString}`);
  };
}

function isHalfLogin(appStore: AppStore) {
  return (
    appStore.session && appStore.session.authenticatedWithPassword === false
  );
}

// TODO: be less metaprogrammy (this is unnecessarily complex)
function generateErrorInterceptor(
  appStore: AppStore,
  redirectToPath: (location: string) => any
) {
  const signOutAndRedirectToLogin = generateSignOutAndRedirectToLogin(
    appStore,
    redirectToPath
  );

  return (err: any) => {
    const status = err.response && err.response.status;

    switch (status) {
      case 401: // Unauthorized
        if (isHalfLogin(appStore)) {
          signOutAndRedirectToLogin('requiresLogin');
          break;
        }

        signOutAndRedirectToLogin('needPassword');
        break;

      case 403: // Forbidden
        signOutAndRedirectToLogin('disabled');
        break;
    }
    return Promise.reject(err);
  };
}

export default function stayAuthorized(
  client: any,
  appStore: any,
  history: any
) {
  client.interceptors.response.use(
    (response: any) => response,
    generateErrorInterceptor(appStore, (location: any) => {
      const whereTo = new URL(location, window.location.toString());
      const infiniteLoop = whereTo.pathname === history.location.pathname;
      if (!infiniteLoop) {
        history.push(location, {
          from: history.location.pathname + history.location.search,
        });
      }
    })
  );
}
