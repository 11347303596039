import * as v1i from '@im-frontend/client/api/v1i/schemas';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@im-frontend/components/Modal';
import { useModal } from '@im-frontend/components/Modal/useModal';
import { TextField } from '@im-frontend/forms/fields';
import { Subset } from '@im-frontend/praxis-api/fields';
import { maybeReportException } from '@im-frontend/utils/errors';
import { imSessionStorage } from '@im-frontend/utils/storages';
import getUserDimensions from '@im-frontend/utils/windowHelper';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Form } from 'react-final-form';
import { generatedV1iApi } from '~/api';
import { appStore } from '~/stores/AppStore';
import { settingsStore } from '~/stores/SettingsStore';
import { Button } from '../Buttons';
import Data from './data';

const scope = Data.dependencies.register({
  currentContact: {
    id: 1,
    firstName: 1,
    mobileAppReviewCanRequest: 1,
  },
});

export type Contact = Subset<v1i.Contact, typeof scope.currentContact>;

const MobileAppReviewProvider = observer(
  ({ children }: { children: React.ReactNode }) => {
    const [isOpen, toggleIsOpen, setIsOpen] = useModal();
    const [unscopedData] = React.useState(Data.createStore);
    const data = unscopedData.scoped(scope);
    const allowedToShowModal =
      appStore?.isMobileApp ||
      settingsStore?.settings?.testing?.mobileAppReviewShowOutsideOfMobileApp;
    useEffect(() => {
      if (appStore?.isLoggedIn && allowedToShowModal) {
        data.currentContact.triggerRequest();
      }
    }, [allowedToShowModal, data]);
    return (
      <MobileAppReviewContext.Provider
        value={{ isOpen, setIsOpen, toggleIsOpen }}
      >
        {children}
        {!data.isLoading &&
          data.currentContact.data.mobileAppReviewCanRequest && (
            <MobileAppReviewModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              toggleIsOpen={toggleIsOpen}
              currentContact={data.currentContact.data}
              refresh={data.currentContact.triggerRequest}
            />
          )}
      </MobileAppReviewContext.Provider>
    );
  }
);

const MobileAppReviewContext = React.createContext({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => {},
  toggleIsOpen: () => {},
});

// This hook will attempt to open the mobile app review modal when the component unloads
const useMobileAppReviewOnComponentCleanup = () => {
  const { setIsOpen } = useContext(MobileAppReviewContext);
  useEffect(() => {
    return () => {
      if (appStore?.isLoggedIn) {
        setIsOpen(true);
      }
    };
  }, [setIsOpen]);
};

export interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refresh: () => Promise<any>;
  toggleIsOpen: () => void;
  currentContact: Contact;
}

const MobileAppReviewModal = ({ isOpen, setIsOpen, refresh }: Props) => {
  const [feedbackModalIsOpen, toggleFeedbackModalIsOpen] = useModal();
  const { browserWidth, browserHeight, screenWidth, screenHeight } =
    getUserDimensions();
  if (!appStore?.isLoggedIn) {
    return null;
  }
  if (
    !(
      appStore?.isMobileApp ||
      settingsStore?.settings?.testing?.mobileAppReviewShowOutsideOfMobileApp
    )
  ) {
    return null;
  }

  return (
    <>
      <Modal isOpen={isOpen} data-testid="portal-feedback-1">
        <ModalHeader
          toggle={async () => {
            try {
              await generatedV1iApi.contacts.recordMobileAppReview({
                lastRequestedMobileReviewResponse: 'canceled',
              });
              await refresh();
            } catch (e) {
              maybeReportException(e);
            } finally {
              setIsOpen(false);
            }
          }}
        >
          Portal Feedback
        </ModalHeader>
        <ModalBody>
          <p>Are you enjoying the app?</p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={async () => {
              try {
                appStore.isMobileApp &&
                  appStore.mobileBridge?.Communicator?.sendEvent(
                    'REQUEST_APP_RATING'
                  );
                await generatedV1iApi.contacts.recordMobileAppReview({
                  lastRequestedMobileReviewResponse: 'yes',
                });
                await refresh();
              } catch (e) {
                maybeReportException(e);
              } finally {
                setIsOpen(false);
              }
            }}
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={async () => {
              try {
                toggleFeedbackModalIsOpen();
                await generatedV1iApi.contacts.recordMobileAppReview({
                  lastRequestedMobileReviewResponse: 'no',
                });
              } catch (e) {
                maybeReportException(e);
              } finally {
                setIsOpen(false);
              }
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={feedbackModalIsOpen} data-testid="portal-feedback-2">
        <ModalHeader
          toggle={async () => {
            await refresh();
            toggleFeedbackModalIsOpen();
          }}
        >
          Portal Feedback
        </ModalHeader>
        <Form<{ feedback?: string }>
          onSubmit={async function ({ feedback }) {
            try {
              if (feedback) {
                generatedV1iApi.feedback.create({
                  feature: 'MobileApp',
                  recipient: 'im_mobile_app',
                  feedback,
                  url: window.location.href,
                  uiVersion: imSessionStorage?.getItem('ui') || 'prod',
                  screenHeight,
                  screenWidth,
                  browserHeight,
                  browserWidth,
                });
              }
            } catch (e) {
              maybeReportException(e);
            } finally {
              toggleFeedbackModalIsOpen();
              await refresh();
            }
          }}
        >
          {({ submitting, form }) => (
            <>
              <ModalBody>
                <p>What feedback do you have about the Investor Portal?</p>
                <TextField name="feedback" type="textarea" />
              </ModalBody>
              <ModalFooter>
                <Button
                  disabled={submitting}
                  type="submit"
                  color="primary"
                  // type submit is not working in the form
                  onClick={form.submit}
                >
                  Submit
                </Button>
                <Button
                  onClick={async () => {
                    toggleFeedbackModalIsOpen();
                    await refresh();
                  }}
                  disabled={submitting}
                >
                  Dismiss
                </Button>
              </ModalFooter>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default observer(MobileAppReviewModal);

export {
  MobileAppReviewProvider,
  MobileAppReviewContext,
  MobileAppReviewModal,
  useMobileAppReviewOnComponentCleanup,
};
