import getUserDimensions from '@im-frontend/utils/windowHelper';
import { autorun } from 'mobx';
import { namespace } from '~/namespaceInfo';

export default function syncPendoUserId(pendo: any, appStore: any) {
  autorun(() => {
    const { isMobileApp, session } = appStore;

    const {
      browserWidth,
      browserHeight,
      screenWidth,
      screenHeight,
      prefersColorScheme,
    } = getUserDimensions();

    if (!session || !session.email || session.preview) {
      return;
    }

    pendo.initialize({
      visitor: {
        id: session.email,
        email: session.email,
        full_name: session.fullName,
        isGP: false,
        isMobileApp,
        screenHeight: screenHeight,
        screenWidth: screenWidth,
        browserHeight: browserHeight,
        browserWidth: browserWidth,
        prefersColorScheme,
      },

      account: {
        id: namespace,
        // salesforce database name is prepended with 'im-'.  need this to map data
        sfdcDBName: 'im-' + namespace,
      },
    });
  });
}
