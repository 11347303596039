import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Input,
  InputGroup,
} from '@appfolio/react-gears';

import classnames from 'classnames';
import { isEqual as equal } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { findDOMNode } from 'react-dom';
import type { DropdownProps, InputProps } from 'reactstrap';

// for custom styling that mimics Select
import './Combobox.scss';
// for visually-hidden (can remove if we ever upgrade to bootstrap 5)
import './bootstrap5.scss';

export type Direction = 'up' | 'down';

export type Option<T> = {
  label: string;
  value: T;
  disabled?: boolean;
};

export type OptionGroup<T> = {
  label: string;
  options: Option<T>[];
};

export interface ComboboxProps<T> {
  className?: InputProps['className'];
  clearable?: boolean;
  disabled?: InputProps['disabled'];
  options: Option<T>[] | OptionGroup<T>[];
  direction?: Direction;
  dropdownProps?: DropdownProps;
  filterOptions?: (options: Option<T>[], value: string) => Option<T>[];
  id?: InputProps['id'];
  isValidNewOption?: (label: string) => boolean;
  menuMaxHeight?: string;
  name?: InputProps['name'];
  noResultsLabel?: string;
  placeholder?: InputProps['placeholder'];
  onChange?: (value: T | undefined) => void;
  onChangeWithLabel?: (value: T | undefined, label: string | undefined) => void;
  onChangeText?: (value: string) => void;
  onCreate?: (str: string) => T;
  renderOption?: (option: Option<T>) => React.ReactNode;
  type?: InputProps['type'];
  value?: T;
}

const defaultProps = {
  clearable: true,
  menuMaxHeight: '12rem',
  noResultsLabel: 'No results found',
  onChange: () => {},
  onChangeWithLabel: () => {},
  onChangeText: () => {},
  filterOptions: (o: Option<any>[], v: any) =>
    o.filter(option =>
      v ? option.label.toLowerCase().indexOf(v.toLowerCase()) > -1 : true
    ),
  isValidNewOption: () => true,
  placeholder: 'Select...',
  renderOption: (option: Option<any>) => option.label,
};

function isKeyboardEvent<T>(u: unknown): u is React.KeyboardEvent<T> {
  return !!(u && (u as any).key);
}

function makeKey<T>(value: T) {
  switch (typeof value) {
    case 'string':
      return encodeURIComponent(value).replace('/%/g', '-');
    case 'number':
      return String(value);
    default:
      return JSON.stringify(value).replace(/[^a-z0-9.:_-]+/gi, '-');
  }
}

/**
 * Type guard for Option.
 */
export function isOption(u: unknown): u is Option<any> {
  return typeof u === 'object' && u && 'value' in u && 'label' in u;
}

/**
 * Combobox component forked from react-gears 7. Changelog vs. upstream:
 *   - styling to mimic Select (react-select-plus)
 *   - clearable prop, for parity with Select
 *   - a11y and keyboard navigation enhancements
 *   - onChangeWithLabel event (simultaneous with onChange) to facilitate use with forms and multi-select
 *   - onChangeText event to hook into typing to facilitate dynamic options
 *
 * @see https://github.com/appfolio/react-gears/blob/master/src/components/Combobox/Combobox.tsx
 */
function Combobox<T>({
  className,
  clearable = defaultProps.clearable,
  direction,
  disabled,
  dropdownProps,
  id,
  options: optionsProp,
  placeholder,
  value,
  menuMaxHeight = defaultProps.menuMaxHeight,
  noResultsLabel = defaultProps.noResultsLabel,
  onChange = defaultProps.onChange,
  onChangeWithLabel = defaultProps.onChangeWithLabel,
  onChangeText = defaultProps.onChangeText,
  onCreate,
  isValidNewOption = defaultProps.isValidNewOption,
  filterOptions = defaultProps.filterOptions,
  renderOption = defaultProps.renderOption,
  ...props
}: ComboboxProps<T>) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [visibleOptions, setVisibleOptions] = useState<Option<T>[]>([]);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>(0);

  const inputElement = useRef<HTMLInputElement>(null);
  const dropdownMenu = useRef(null);
  const focusedOption = useRef(null);

  const grouped = !!(optionsProp[0] as OptionGroup<T>)?.options;
  const options: Option<T>[] = useMemo(() => {
    if (equal(optionsProp, []) || !optionsProp) {
      return [];
    }

    if (grouped) {
      return (optionsProp as OptionGroup<T>[]).reduce(
        (o: Option<T>[], current: OptionGroup<T>) => [...o, ...current.options],
        []
      );
    }
    return optionsProp as Option<T>[];
  }, [optionsProp, grouped]);
  const selected = useMemo<Option<T>>(
    () => options.find(option => equal(option.value, value)),
    [value, options]
  );
  const selectedLabel = selected?.label;
  const noMatches = visibleOptions.length === 0;
  const cursorAtStart = () =>
    inputElement?.current?.selectionStart === 0 &&
    inputElement?.current?.selectionEnd === 0;

  // TODO: why did react-gears include this behavior? it's annoying!
  //   - user opens the dropdown with keyboard nav (UpArrow/DownArrow)
  //   - we reset the focused option to 0!
  // By commenting it out, we have superior UX...
  // useEffect(() => {
  //   if (visibleOptions.length > 0) {
  //     setFocusedOptionIndex(0);
  //   }
  // }, [visibleOptions]);

  useEffect(() => {
    if (selectedLabel) {
      setInputValue(selectedLabel);
    } else if (value === undefined) {
      setInputValue('');
    }
  }, [open, selectedLabel, value]);

  useEffect(() => {
    if (open && selectedLabel && inputElement?.current) {
      window.setTimeout(() => {
        inputElement.current.setSelectionRange(0, 0);
      }, 1);
    }
  }, [open, selectedLabel]);

  useEffect(() => {
    const selectableOptions = [...options];

    setVisibleOptions(
      filterOptions(
        selectableOptions,
        selected && inputValue === (selected as Option<T>).label
          ? ''
          : inputValue
      )
    );
  }, [inputValue, setVisibleOptions, filterOptions, options, value, selected]);

  const scrollFocusedOptionIntoView = () => {
    if (dropdownMenu.current === null || focusedOption.current === null) {
      return;
    }
    /* eslint-disable react/no-find-dom-node */
    const focusedOptionNode = findDOMNode(focusedOption.current) as HTMLElement;
    const menuNode = findDOMNode(dropdownMenu.current) as HTMLElement;
    /* eslint-enable react/no-find-dom-node */

    if (focusedOptionNode === null || menuNode === null) {
      return;
    }

    const scrollTop = menuNode.scrollTop;
    const scrollBottom = scrollTop + menuNode.offsetHeight;
    const optionTop = focusedOptionNode.offsetTop;
    const optionBottom = optionTop + focusedOptionNode.offsetHeight;

    if (scrollTop > optionTop) {
      menuNode.scrollTop -= scrollTop - optionTop;
    } else if (scrollBottom < optionBottom) {
      menuNode.scrollTop += optionBottom - scrollBottom;
    }
  };

  useEffect(scrollFocusedOptionIntoView, [focusedOptionIndex]);

  const isOptionVisible = (option: Option<T>) =>
    visibleOptions.indexOf(option) > -1;

  const isOptionSelected = (option: Option<T>) => equal(value, option.value);

  const selectOption = (value: T, label: string) => {
    onChange(value);
    onChangeWithLabel(value, label);
    setOpen(false);
  };

  const clear = () => {
    setInputValue('');
    onChange(undefined);
    onChangeWithLabel(undefined, undefined);
    setOpen(false);
  };

  const showSelectedPreview = () => {
    setInputValue(`${(selected as Option<T>).label} `);
    window.setTimeout(() => {
      inputElement?.current?.setSelectionRange(0, 0);
    }, 1);
  };

  const clearSelectedPreview = (e?: React.SyntheticEvent<HTMLInputElement>) => {
    if (selected && (selected as Option<T>).label === inputValue) {
      if (cursorAtStart()) {
        if (isKeyboardEvent(e)) {
          setInputValue(e.key);
        } else {
          setInputValue('');
        }
      }
    }
  };

  const createOption = () => {
    if (!onCreate) {
      return;
    }

    const optionValue = onCreate(inputValue);
    if (optionValue) {
      selectOption(optionValue, inputValue);
    }
  };

  const handleOptionsKeyboardNav = (e: React.KeyboardEvent<HTMLElement>) => {
    const input = inputElement?.current;
    const allSelected =
      input?.selectionStart === 0 && input.selectionEnd === input.value.length;
    const isDisplayingSelected =
      selected && inputValue === (selected as Option<T>).label;

    switch (e.key) {
      case 'ArrowDown':
        if (!open) {
          setOpen(true);
        } else if (focusedOptionIndex < visibleOptions.length - 1) {
          setFocusedOptionIndex(focusedOptionIndex + 1);
        }
        break;
      case 'ArrowUp':
        if (!open) {
          setOpen(true);
        } else if (focusedOptionIndex > 0) {
          setFocusedOptionIndex(focusedOptionIndex - 1);
        }
        break;
      case 'Backspace':
        if (selected) {
          if (
            clearable &&
            (cursorAtStart() || (allSelected && isDisplayingSelected))
          ) {
            clear();
          } else if (
            (input?.selectionStart === 1 && input?.selectionEnd === 1) ||
            (allSelected && !isDisplayingSelected)
          ) {
            showSelectedPreview();
          }
        }
        break;
      case 'Enter':
        if (noMatches) {
          createOption();
          return;
        }
        selectOption(
          visibleOptions[focusedOptionIndex].value,
          visibleOptions[focusedOptionIndex].label
        );
        e.preventDefault();
        break;
      case 'Escape':
        if (open) {
          setOpen(false);
        } else if (clearable) {
          clear();
        }
        break;
      default:
        return;
    }
  };

  const renderOptions = (opts: Option<T>[]) =>
    opts.map(option => {
      const visibleIndex = visibleOptions.indexOf(option);
      const key = makeKey(option.value);
      return (
        <DropdownItem
          disabled={option.disabled}
          className={`${isOptionVisible(option) ? '' : 'visually-hidden'}`}
          key={key}
          id={`option-${key}`}
          active={focusedOptionIndex === visibleIndex}
          onMouseEnter={ev => {
            ev.preventDefault();
            ev.stopPropagation();
            setFocusedOptionIndex(visibleIndex);
          }}
          onKeyDown={ev => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              ev.stopPropagation();
              selectOption(option.value, option.label);
            }
          }}
          onMouseDown={ev => {
            ev.preventDefault();
            ev.stopPropagation();
            selectOption(option.value, option.label);
          }}
          ref={visibleIndex === focusedOptionIndex ? focusedOption : null}
          role="option"
          aria-selected={isOptionSelected(option)}
        >
          {renderOption(option)}
        </DropdownItem>
      );
    });

  const renderGroupedOptions = (groups: OptionGroup<T>[]) =>
    groups.map((group, i) => (
      <>
        <DropdownItem header>{group.label}</DropdownItem>
        {renderOptions(group.options)}
        {i !== groups.length - 1 && <DropdownItem divider />}
      </>
    ));

  const renderNoOptions = () => {
    if (onCreate) {
      return (
        <DropdownItem
          active={noMatches}
          data-testid="create-new-option"
          disabled={!isValidNewOption(inputValue)}
          onMouseDown={ev => {
            ev.preventDefault();
            ev.stopPropagation();
            createOption();
          }}
        >
          {`Create "${inputValue}"`}
        </DropdownItem>
      );
    }

    return <DropdownItem disabled>{noResultsLabel}</DropdownItem>;
  };

  // Suppress "X" button on WebKit browsers; we do our own!
  const typeProp = props.type || 'search';
  const inputType = typeProp === 'search' ? 'text' : typeProp;
  clearable = clearable && value && !disabled && typeProp === 'search';

  return (
    <Dropdown
      className={classnames('combobox', className)}
      data-testid="combobox-dropdown"
      direction={direction}
      isOpen={!disabled && open}
      toggle={() => {}}
      onBlur={({ currentTarget: blurred, relatedTarget: focused }) => {
        if (focused instanceof Node && blurred.contains(focused)) {
          return;
        }
        setOpen(false);
      }}
    >
      <DropdownToggle disabled={disabled} tag="div">
        <InputGroup className={className}>
          <Input
            aria-label="Combobox"
            autoComplete="off"
            data-testid="combobox-input"
            disabled={disabled}
            id={id}
            innerRef={inputElement}
            onClick={() => {
              if (!open) {
                setOpen(true);
              }
            }}
            onFocus={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(true);
            }}
            onChange={e => {
              e.preventDefault();
              e.stopPropagation();
              const { value } = e.target;
              setInputValue(value);
              onChangeText(value);

              if (selected && cursorAtStart() && value === '') {
                // TODO: document what's going on here
                //   - should this be a call to clear()
                //   - or is it important to keep focus on the input?
                //   - does this event make sense for non-clearable comboboxen?
                onChange(undefined);
                onChangeWithLabel(undefined, undefined);
              }
            }}
            onMouseDown={e => {
              if (selected) {
                inputElement?.current?.setSelectionRange(0, 0);

                if ((selected as Option<T>).label === inputValue) {
                  e.preventDefault();
                }
              }

              // @ts-expect-error 2339 it lies!
              e.target.focus();
            }}
            onKeyDown={handleOptionsKeyboardNav}
            onKeyPress={clearSelectedPreview}
            onPaste={clearSelectedPreview}
            placeholder={selected ? undefined : placeholder}
            type={inputType}
            value={inputValue}
            {...props}
          />
          <Button
            aria-hidden
            disabled={!clearable}
            className="px-0"
            data-testid="combobox-clear"
            onClick={ev => {
              clear();
              ev.preventDefault();
              ev.stopPropagation();
            }}
            style={{
              opacity: clearable ? 1 : 0,
            }}
            tabIndex={-1}
            title="Clear value"
          >
            ×
          </Button>
          <Button
            aria-hidden
            className="pl-0 pr-2"
            data-testid="combobox-caret"
            disabled={disabled}
            onClick={ev => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            onMouseDown={ev => {
              ev.preventDefault();
              ev.stopPropagation();
              setOpen(!open);
              if (!open) {
                setTimeout(() => inputElement?.current?.focus(), 0);
              }
            }}
            tabIndex={-1}
            title="Toggle options menu"
          >
            <Icon name={open ? 'caret-up' : 'caret-down'} fixedWidth />
          </Button>
        </InputGroup>
      </DropdownToggle>
      <DropdownMenu
        data-testid="combobox-menu"
        style={{ maxHeight: menuMaxHeight }}
        {...dropdownProps}
        ref={dropdownMenu}
        role="listbox"
        aria-activedescendant={
          visibleOptions[focusedOptionIndex] &&
          `option-${makeKey(visibleOptions[focusedOptionIndex].value)}`
        }
      >
        {grouped
          ? renderGroupedOptions(optionsProp as OptionGroup<T>[])
          : renderOptions(options)}
        {noMatches && renderNoOptions()}
      </DropdownMenu>
    </Dropdown>
  );
}

Combobox.displayName = 'Combobox';
Combobox.defaultProps = defaultProps;

export default Combobox;
