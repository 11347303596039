interface LogOptions {
  name?: string;
  message: string;
  consoleProps?: () => Record<any, any>;
}

interface API {
  log(options: LogOptions): void;
}

declare global {
  interface Window {
    Cypress: API;
  }
}

/**
 * Is the Cypress API defined in this JavaScript VM?
 */
export const isPresent =
  typeof window !== 'undefined' && typeof window.Cypress !== 'undefined';
/**
 * If Cypress is present, add a message to the test runner log.
 */
export function log(options: LogOptions) {
  if (!isPresent) {
    return;
  }
  window.Cypress.log(options);
}
